import React, { useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions, Platform, Alert, ToastAndroid } from 'react-native';
import { Text, View, ScrollView, Image, ImageBackground, useTVRemoteHandler, StyleSheet, Pressable } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext } from '../../config';
import on from "../../assets/on.png";
import off from "../../assets/off.png";
import playlisticon from "../../assets/playlist.png";
import { isPlatformTizen, isPlatformAndroidtv } from '@rnv/renative';
import axios from 'axios';
import { useQuery } from 'react-query';
import { GET_AUTH_TOKEN } from '../../storage/AuthStorage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode from 'jwt-decode';
// @ts-ignore
import CryptoJS from 'crypto-js';
import DeviceInfo from 'react-native-device-info';
import packageJson from "../../../package.json";
import logo from "../../assets/logo.png";
import { useTranslation } from 'react-i18next';

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

const ScreenVPNLicense = ({ navigation }: { navigation?: any }) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);
    const [version, setVersion] = React.useState("");
    const [id, setId] = React.useState("");
    const [orbixplayId, setOrbixplayId] = React.useState("");
    const { t, i18n } = useTranslation();

    const [deviceid, setDeviceId] = React.useState("");

    // Fetcher function qr code
    const getResourceQrCode = async () => {
        try {
            const response = await axios.get('https://deviceapi.orbixplay.com/app/live-lbgjx/endpoint/get_qr_code_vpn_license', {
                headers: {
                    "Authorization": `Bearer ${await GET_AUTH_TOKEN()}`
                }
            });
            return response.data;
        } catch (error) {
            throw new Error('Failed to fetch resource QR code');
        }
    };

    // Using the hook
    const { data: getqrocode, error: errgetqrcode, isLoading: isLoadingGetQrcode, refetch } = useQuery('activate-license', getResourceQrCode);

    const [tvHandlerRemote, setTvHandlerRemote] = React.useState<any>();

    useTVRemoteHandler((remote: any) => {
        setTvHandlerRemote(remote);
    });

    const getLicense = async () => {
        try {
            const value = await AsyncStorage.getItem('@access_token');
            const decodedToken: any = jwtDecode(`${value}`);
            const currentDate = Date.now()
            if (decodedToken?.user_data?.vpn_license_end_date !== undefined && decodedToken?.user_data?.vpn_license_end_date?.$date.$numberLong > currentDate) {
                navigation.navigate(ROUTES.PLAYLIST, { params: 2 });
            } else {
                alert("No Privacy Mode license is available! Please scan to activate.")
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }
    };

    React.useEffect(() => {
        const getDeviceId = async () => {
            const did: any = await AsyncStorage.getItem("@device_id");
            setDeviceId(did);
        };
        getDeviceId();
    }, [deviceid]);

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#0A1C34" }]}
        >
            <View style={{ height: Dimensions.get("window").width / 20, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <View style={{ marginTop: Ratio(50) }}>
                    <Image source={logo} style={{ width: isPlatformAndroidtv ? 160 : 300, height: isPlatformAndroidtv ? 50 : 100 }} />
                </View>
            </View>
            <View>
                <View style={{ padding: 40 }}>
                    <Text style={{ color: "white", fontSize: Dimensions.get("window").width / 80, marginBottom: 0, textAlign: "center", textTransform: "uppercase", fontWeight: "bold" }}>
                        {t('vpn.no_privacymode')}
                    </Text>
                    <Text style={{ color: "white", fontSize: Dimensions.get("window").width / 100, marginBottom: 10, textAlign: "center" }}>
                        {t('vpn.scan_to_activate')}
                    </Text>


                    <View style={{ flexDirection: "row" }}>
                        <View
                            style={{
                                borderRadius: 20,
                                alignItems: "flex-start",
                                position: 'absolute',
                                left: Ratio(150),
                                top: Ratio(150)
                            }}
                        >
                            <Text style={{ color: "white", fontSize: Dimensions.get("window").width / 40, marginBottom: 30, textAlign: "center", textTransform: "uppercase" }}>
                                {t('vpn.how_to_activate')}
                            </Text>

                            <View style={{ marginBottom: 20, flexDirection: "row" }}>
                                <View style={{ alignItems: "flex-start" }}>
                                    <Text style={{ color: "white", fontSize: Dimensions.get("window").width / 60, textAlign: "center", marginBottom: 5 }}>
                                        {t('vpn.scan_qr_visit')}
                                    </Text>
                                    <Text style={{ color: "orange", fontSize: Dimensions.get("window").width / 60 }}>
                                        https://orbixplay.com/activate-pm
                                    </Text>
                                </View>
                            </View>

                            <View style={{ marginBottom: 20, flexDirection: "row" }}>
                                <View style={{ alignItems: "flex-start" }}>
                                    <Text style={{ color: "white", fontSize: Dimensions.get("window").width / 60, textAlign: "center", marginBottom: 5 }}>
                                        {t('vpn.type_mac_address')}
                                    </Text>
                                    <Text style={{ fontSize: Dimensions.get("window").width / 60 }}>
                                        <Text style={{ color: "white" }}>{t('vpn.mac_address')}: </Text>
                                        <Text style={{ color: "orange" }}>{deviceid}</Text>
                                    </Text>
                                </View>
                            </View>

                            <View style={{ marginBottom: 20, flexDirection: "row" }}>
                                <View style={{ alignItems: "flex-start", justifyContent: "center" }}>
                                    <Text style={{ color: "white", fontSize: Dimensions.get("window").width / 60, textAlign: "center" }}>
                                        {t('vpn.activate')}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View
                            style={{
                                backgroundColor: "#fff",
                                width: Dimensions.get("window").width / 4.5,
                                borderRadius: 20,
                                height: Dimensions.get("window").width / 4.5,
                                alignItems: "center",
                                justifyContent: "center",
                                position: 'absolute',
                                right: Ratio(200),
                                top: Ratio(100)
                            }}
                        >
                            {getqrocode &&
                                <Image source={{ uri: getqrocode.qr_payment_url }} style={{
                                    width: Dimensions.get("window").width / 5,
                                    height: Dimensions.get("window").width / 5,
                                }} />
                            }
                        </View>
                    </View>
                </View>
            </View>

            <View style={{ alignSelf: "center", flexDirection: "row", position: "absolute", bottom: Ratio(150) }}>
                <Pressable
                    style={{
                        padding: Ratio(20),
                        backgroundColor: "#2b3e54",
                        marginTop: 20,
                        alignItems: "center",
                        borderRadius: 15,
                        justifyContent: "center",
                        marginRight: 30,
                        marginLeft: 30
                    }}
                    onPress={() => {
                        navigation.goBack();
                    }}
                    focusOptions={{
                        animator: {
                            type: "background",
                            focus: {
                                backgroundColor: "#128DD3"
                            }
                        }
                    }}
                >
                    <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 90 }}>
                        {t('vpn.goback')}
                    </Text>
                </Pressable>
                <Pressable
                    style={{
                        padding: Ratio(20),
                        backgroundColor: "#2b3e54",
                        marginTop: 20,
                        borderRadius: 15,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    onPress={() => getLicense()}
                    focusOptions={{
                        animator: {
                            type: "background",
                            focus: {
                                backgroundColor: "#128DD3"
                            }
                        }
                    }}
                >
                    <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 90 }}>
                        {t('vpn.check')}
                    </Text>
                </Pressable>
            </View>
        </Screen>
    )
}

const styles = StyleSheet.create({
    recyclerItem: {
        width: windowWidth / 4,
        height: windowWidth / 4,
        borderRadius: isPlatformAndroidtv ? 20 : 50,
        alignItems: "center",
        backgroundColor: "#222",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.6,
        justifyContent: 'center'
    },
    recyclerItemSettings: {
        width: windowWidth / 8,
        height: windowWidth / 12,
        backgroundColor: "#222",
        borderRadius: isPlatformAndroidtv ? 15 : 30,
        alignItems: "center",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.6,
        justifyContent: 'center'
    },
    txtButton: {
        color: "#fff",
        fontSize: windowWidth / 50,
        fontWeight: "bold",
        marginTop: isPlatformAndroidtv ? 20 : 60,
        textTransform: "uppercase"
    },
    txtButtonSettings: {
        color: "#fff",
        fontSize: windowWidth / 80,
        fontWeight: "bold",
        marginTop: isPlatformAndroidtv ? 15 : 25,
        textTransform: "uppercase"
    }
});

export default ScreenVPNLicense;