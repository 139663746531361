import React, { useEffect } from 'react';
import { disableTVMenuKey, enableTVMenuKey, setFocusManagerEnabled, View } from '@flexn/create';
import { isPlatformTizen, isPlatformWebos } from '@rnv/renative';
import { enableScreens } from 'react-native-screens';
import { StyleSheet, Dimensions } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
    NavigationContainer,
    useNavigationBuilder,
    createNavigatorFactory,
    StackRouter,
} from '@react-navigation/native';
import { ScreenContainer, Screen } from 'react-native-screens';
import ScreenHome from '../screens/home/Home';
import ScreenCarousels from '../screens/carousels';
import ScreenDetails from '../screens/details';
import ScreenModal from '../screens/modal';
import Menu from '../components/menu';
import { ROUTES } from '../config';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import ScreenPlaylist from '../screens/playlists/Playlist';
import ScreenLiveTv from '../screens/livetv/LiveTv';
import ScreenMovies from '../screens/movies/Movies';
import ScreenMovieDetails from '../screens/movies/MovieDetails';
import ScreenPlayMovie from '../screens/movies/PlayMovie';
import ScreenSeries from '../screens/series/Series';
import ScreenSeriesDetails from '../screens/series/SerieDetails';
import ScreenPlaySerie from '../screens/series/PlaySerie';
import ScreenSettings from '../screens/settings/Settings';
import ScreenVPN from '../screens/vpn/Vpn';
import ScreenSearch from '../screens/search/Search';
import ScreenMorePlaylist from '../screens/playlists/MorePlaylists';
import ScreenCheckLicense from '../screens/check-license/CheckLicense';
import { GET_LICENSE_DATE } from '../storage/AuthStorage';
import AcceptTerms from '../screens/accept-terms/AcceptTerms';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ScreenChannelsMenu from '../screens/livetv/ChannelsMenu';

const { height } = Dimensions.get('window');

setFocusManagerEnabled(true);
enableScreens();

const createTVSideNavigator = createNavigatorFactory(Navigator);

function Navigator({ initialRouteName, children, screenOptions, drawerContent, ...rest }: any) {
    const { state, navigation, descriptors, NavigationContent } = useNavigationBuilder(StackRouter, {
        children,
        screenOptions,
        initialRouteName,
    });

    useEffect(() => {
        enableTVMenuKey();
        if (state.index === 0) {
            disableTVMenuKey();
        }
    }, [navigation, state.index]);

    return (
        <NavigationContent>
            <View style={styles.container}>
                {drawerContent({
                    state,
                    navigation,
                    descriptors,
                    ...rest,
                })}
            </View>

            <ScreenContainer style={styles.main}>
                {state.routes.map((route, i) => {
                    const isFocused = state.index === i;
                    const style =
                        isPlatformTizen || isPlatformWebos
                            ? { opacity: isFocused ? 1 : 0 }
                            : [StyleSheet.absoluteFill, { opacity: isFocused ? 1 : 0 }];

                    return (
                        <Screen key={route.key} style={style} active={isFocused ? 1 : 0}>
                            {descriptors[route.key].render()}
                        </Screen>
                    );
                })}
            </ScreenContainer>
        </NavigationContent>
    );
}

const RootStack = createNativeStackNavigator();
const SideNavigatorStack = createTVSideNavigator();

const SideNavigator = () => (
    <SideNavigatorStack.Navigator
        drawerContent={({ navigation }: { navigation: any }) => <Menu navigation={navigation} />}
    >
        <SideNavigatorStack.Screen name={ROUTES.HOME} component={ScreenHome} />
        <SideNavigatorStack.Screen name={ROUTES.CAROUSELS} component={ScreenCarousels} />
        <SideNavigatorStack.Screen name={ROUTES.DETAILS} component={ScreenDetails} />
    </SideNavigatorStack.Navigator>
);

const App = () => {

    const [acceptTerms, setAcceptTerms] = React.useState(false);

    const GET_TERMS_VALUE = async () => {
        const value = await AsyncStorage.getItem("accept_terms");

        if (value !== null) {
            if (value == "true") {
                setAcceptTerms(true);
            } else {
                setAcceptTerms(false);
            }
        } else {
            setAcceptTerms(false);
        }
    }

    React.useEffect(() => {
        GET_TERMS_VALUE();
    }, [acceptTerms])

    return (
        <SafeAreaProvider>
            <NavigationContainer>
                <RootStack.Navigator screenOptions={{ headerShown: false }}>
                    {!acceptTerms &&
                        <RootStack.Screen name={ROUTES.ACCEPT_TERMS} component={AcceptTerms} />
                    }
                    <RootStack.Screen name={ROUTES.PLAYLIST} component={ScreenPlaylist} />
                    <RootStack.Screen name={ROUTES.MORE_PLAYLIST} component={ScreenMorePlaylist} />
                    <RootStack.Screen name={ROUTES.CHECK_LICENSE} component={ScreenCheckLicense} />
                    <RootStack.Screen name={ROUTES.HOME} component={ScreenHome} />
                    <RootStack.Screen name={ROUTES.LIVETV} component={ScreenLiveTv} />
                    <RootStack.Screen name={ROUTES.CHANNEL_LIST_TV} component={ScreenChannelsMenu} />
                    <RootStack.Screen name={ROUTES.MOVIES} component={ScreenMovies} />
                    <RootStack.Screen name={ROUTES.MOVIE_DETAILS} component={ScreenMovieDetails} />
                    <RootStack.Screen name={ROUTES.MOVIE_PLAY} component={ScreenPlayMovie} />
                    <RootStack.Screen name={ROUTES.SERIES} component={ScreenSeries} />
                    <RootStack.Screen name={ROUTES.SERIE_DETAILS} component={ScreenSeriesDetails} />
                    <RootStack.Screen name={ROUTES.SERIE_PLAY} component={ScreenPlaySerie} />
                    <RootStack.Screen name={ROUTES.SETTINGS} component={ScreenSettings} />
                    <RootStack.Screen name={ROUTES.VPN} component={ScreenVPN} />
                    <RootStack.Screen name={ROUTES.SEARCH} component={ScreenSearch} />
                </RootStack.Navigator>
            </NavigationContainer>
        </SafeAreaProvider>
    )
};

const styles = StyleSheet.create({
    container: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        opacity: 1,
        position: 'absolute',
        ...((isPlatformTizen || isPlatformWebos) && { height }),
    },
    content: { flex: 1 },
    main: { flex: 1 },
});

export default App;
