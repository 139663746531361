import AsyncStorage from '@react-native-async-storage/async-storage';

export const PLAYLIST_STORAGE_URL = async () => {
	try {
		const value = await AsyncStorage.getItem('@active_playlist');
		if (value !== null) {
			return JSON.parse(value).url;
		}
	} catch (e) {
		// error reading value
	}
}

export const PLAYLIST_STORAGE_USERNAME = async () => {
	try {
		const value = await AsyncStorage.getItem('@active_playlist');
		if (value !== null) {
			return JSON.parse(value).username;
		}
	} catch (e) {
		// error reading value
	}
}

export const PLAYLIST_STORAGE_PASSWORD = async () => {
	try {
		const value = await AsyncStorage.getItem('@active_playlist');
		if (value !== null) {
			return JSON.parse(value).password;
		}
	} catch (e) {
		// error reading value
	}
}