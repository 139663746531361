import React, { useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions } from 'react-native';
import { Text, View, ScrollView, Image, useTVRemoteHandler, FlashList, ImageBackground, StyleSheet, Pressable, CreateListRenderItemInfo } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext, getWidth, isWebBased } from '../../config';
import logo from "../../assets/logo.png";
import { useQuery } from 'react-query';
import { isFactorMobile, isPlatformMacos, isPlatformWeb, isFactorTv, isPlatformAndroidtv, isPlatformTizen, isEngineRnWeb } from '@rnv/renative';
import { PLAYLIST_STORAGE_PASSWORD, PLAYLIST_STORAGE_URL, PLAYLIST_STORAGE_USERNAME } from '../../storage/PlayListStorage';
import axios from 'axios';
import noimage from "../../assets/noimage.png";
import AntDesign from 'react-native-vector-icons/AntDesign';

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

const ScreenMovies = ({ navigation }: { navigation?: any }) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    const [category, setCategory] = React.useState("");
    const [categoryName, setCategoryName] = React.useState("");
    const [focusedIten, setFocusedItem] = React.useState<any>({});

    // Fetcher function for VOD categories
    const getResourceCategories = async () => {
        try {
            const playlistStorageUrl = await PLAYLIST_STORAGE_URL();
            const playlistStorageUsername = await PLAYLIST_STORAGE_USERNAME();
            const playlistStoragePassword = await PLAYLIST_STORAGE_PASSWORD();

            const response = await fetch(`${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}&action=get_vod_categories`);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch VOD categories');
        }
    };

    // Using the hook for VOD categories
    const { data: categories, error: errCategories, isLoading: isLoadingCategories } = useQuery('vod-categories', getResourceCategories, {
        refetchInterval: 6 * 60 * 60 * 1000,
        cacheTime: 6 * 60 * 60 * 1000,
    });

    // Fetcher function for VOD streams in a specific category
    const getResourceVODStreams = async (category: any) => {
        try {
            const playlistStorageUrl = await PLAYLIST_STORAGE_URL();
            const playlistStorageUsername = await PLAYLIST_STORAGE_USERNAME();
            const playlistStoragePassword = await PLAYLIST_STORAGE_PASSWORD();

            const response = await fetch(`${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}&action=get_vod_streams&category_id=${category}`);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error(`Failed to fetch VOD streams in the category ${category}`);
        }
    };

    // Using the hook for VOD streams in a specific category
    const { data, error: errVODStreams, isLoading: isLoadingVODStreams } = useQuery(['vod-by-categories', category], () => getResourceVODStreams(category), {
        refetchInterval: 6 * 60 * 60 * 1000,
        cacheTime: 6 * 60 * 60 * 1000,
    });

    const [date, setDate] = React.useState(new Date());
    const [time, setTime] = React.useState("");

    React.useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);
        return function cleanup() {
            clearInterval(timer)
        }
    });

    const onFocusCategory = (item: any) => {
        setCategory(item);
    }

    const onFocusCategoryName = (item: any) => {
        setCategoryName(item);
    }

    const onFocusItem = (item: any) => {
        setFocusedItem(item);
    }

    const renderCategoriesItem = ({ item, focusRepeatContext, index }: any) => {
        return (
            <Pressable
                style={[
                    styles.genreitem
                ]}
                focusRepeatContext={focusRepeatContext}
                onFocus={() => {
                    onFocusCategory(item.category_id)
                    onFocusCategoryName(item.category_name)
                }}
                focusOptions={{
                    animator: {
                        type: 'background',
                        focus: {
                            backgroundColor: '#128DD3'
                        },
                    }
                }}
            >
                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", flex: 1 }}>
                    {/* @ts-ignore */}
                    <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 75, marginLeft: Ratio(40) }}>
                        {item.category_name.length > 16 ? `${item.category_name.substring(0, 16)}...` : item.category_name}
                    </Text>
                </View>
            </Pressable>
        );
    };

    const MovieRating = ({ rating, color }: any) => {

        const totalStars = 5;
        const filledStars = Math.round(rating / 2); // Convert the rating to a 5-star scale
        const starsArray = [];

        for (let i = 1; i <= totalStars; i++) {
            starsArray.push(
                <AntDesign
                    key={i}
                    name="star"
                    size={40}
                    color={i <= filledStars ? color : 'grey'}
                    style={{ marginRight: 3 }}
                />
            );
        }

        return <View style={{ flexDirection: 'row' }}>{starsArray}</View>;

    };

    const rowRenderer = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {

        const isLastItem = index === data?.length - 1;

        return (
            <Pressable
                onMouseDown={() => navigation.navigate(ROUTES.MOVIE_DETAILS, { item: item })}
                onPress={() => navigation.navigate(ROUTES.MOVIE_DETAILS, { item: item })}
                style={styles.recyclerItem}
                focusRepeatContext={focusRepeatContext}
                focusOptions={{
                    animator: {
                        type: "background",
                        focus: {
                            backgroundColor: "#128DD3"
                        }
                    }
                }}
            >
                <View style={{ flexDirection: "row" }}>
                    <View>
                        <Image resizeMode={'cover'} source={{ uri: !item.stream_icon ? "https://assets.orbixplay.com/no_movie_image_placeholder.png" : item.stream_icon }} style={{
                            width: Dimensions.get("window").width / 15,
                            height: Dimensions.get("window").height / 5,
                            borderTopRightRadius: 20,
                            borderTopLeftRadius: 10,
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 20,
                            alignSelf: 'center',
                        }} />
                    </View>
                    <View style={{ padding: Ratio(25) }}>
                        <Text style={{ fontSize: windowWidth / 50, fontWeight: "bold", color: "white", marginLeft: Ratio(3) }}>{item.name ? item.name.substring(0, 40) + (item.name.length > 40 ? " ..." : "") : ""}</Text>
                        {/* <View>
                            <View style={{ height: windowWidth / 50, width: windowWidth / 14, borderRadius: 10, backgroundColor: "#222", justifyContent: "center", alignItems: "center", marginTop: Ratio(15) }}>
                                <Text style={{ fontSize: Ratio(18), color: "white" }}>{categoryName ? categoryName.substring(0, 12) + (categoryName.length > 12 ? " ..." : "") : ""}</Text>
                            </View>
                        </View> */}
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                            <View style={{ flexDirection: "row", marginTop: 3 }}>
                                <MovieRating color="orange" rating={item.rating} />
                            </View>
                        </View>
                    </View>
                </View>
            </Pressable>
        );
    };

    React.useEffect(() => {

        if (category !== "" && data) {

            const index0 = data && data[0];

            setFocusedItem({
                name: index0.name,
                stream_icon: index0.stream_icon,
            })

        }

    }, [data, category])

    const [tvHandlerRemote, setTvHandlerRemote] = React.useState<any>();

    useTVRemoteHandler((remote: any) => {
        setTvHandlerRemote(remote);
    });

    React.useEffect(() => {

        if (tvHandlerRemote) {
            if (isEngineRnWeb) {
                if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "back") {
                    navigation.goBack();
                }
            }
        }

    }, [tvHandlerRemote]);

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, paddingLeft: Ratio(40), backgroundColor: "#0A1C34" }]}
            focusOptions={{ screenOrder: 3 }}
        >
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.3 }}>
                    {categories &&
                        <FlashList
                            data={categories}
                            renderItem={renderCategoriesItem}
                            type="row"
                            contentContainerStyle={{
                                paddingTop: Ratio(30)
                            }}
                            style={{
                                height: height
                            }}
                            showsVerticalScrollIndicator={false}
                            estimatedItemSize={Dimensions.get("window").width / 1}
                            horizontal={false}
                            showsHorizontalScrollIndicator={false}

                        />
                    }
                </View>
                <View style={{ flex: 0.8 }}>
                    {data &&
                        <FlashList
                            key={category}
                            data={data}
                            renderItem={rowRenderer}
                            contentContainerStyle={{
                                paddingTop: Ratio(50)
                            }}
                            style={{
                                height: height
                            }}
                            horizontal={false}
                            type="row"
                            estimatedItemSize={Dimensions.get("window").width / 1}
                        />
                    }
                </View>
            </View>
        </Screen>
    )
}

const styles = StyleSheet.create({
    recyclerItem: {
        width: Dimensions.get("window").width / 1.45,
        height: Dimensions.get("window").height / 5,
        backgroundColor: "#2b3e54",
        borderRadius: 10,
        marginBottom: 10,
        marginRight: !isWebBased ? 10 : 30
    },
    recyclerItemSettings: {
        width: windowWidth / 8,
        height: windowWidth / 12,
        backgroundColor: "#2b3e54",
        borderRadius: 30,
        alignItems: "center",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.6,
        justifyContent: 'center'
    },
    txtButton: {
        color: "#fff",
        fontSize: windowWidth / 120,
        width: 250,
        textAlign: "center"
    },
    txtButtonSettings: {
        color: "#fff",
        fontSize: windowWidth / 90,
        fontWeight: "bold",
        marginTop: 25,
        textTransform: "uppercase"
    },
    channelLogo: {
        width: windowWidth / 25,
        height: windowWidth / 25,
    },
    genreitem: {
        width: isPlatformAndroidtv ? getWidth() / 8 : getWidth() / 4,
        height: isPlatformAndroidtv ? getWidth() / 40 : getWidth() / 20,
        marginBottom: Ratio(10),
        borderRadius: Ratio(10),
        backgroundColor: "#2b3e54",
        alignItems: "flex-start",
        justifyContent: 'center',
        alignSelf: "center"
    },
    image: {
        width: Dimensions.get("window").width / 9.5,
        height: Dimensions.get("window").width / 6.3
    },
});

export default ScreenMovies;