// import React, { useContext, useRef } from 'react';
// import { ScrollView as RNScrollView, useWindowDimensions, Dimensions } from 'react-native';
// import { Text, View, ScrollView, Image, FlashList, ImageBackground, StyleSheet, useTVRemoteHandler, Pressable, CreateListRenderItemInfo } from '@flexn/create';
// import Screen from '../screen';
// import { ROUTES, Ratio, ThemeContext, isWebBased } from '../../config';
// import logo from "../../assets/logo.png";
// import { useQuery } from 'react-query';
// import { isFactorMobile, isPlatformMacos, isPlatformWeb, isFactorTv, isPlatformAndroidtv, isPlatformTizen, isEngineRnWeb } from '@rnv/renative';
// import VODLayout from "../../assets/layers/vod_preview_layer.png";
// import { PLAYLIST_STORAGE_PASSWORD, PLAYLIST_STORAGE_URL, PLAYLIST_STORAGE_USERNAME } from '../../storage/PlayListStorage';
// import axios from 'axios';
// import { useTranslation } from 'react-i18next';

// // screen size
// const windowWidth: any = Dimensions.get('window').width;
// const windowHeight: any = Dimensions.get('window').height;

// const ScreenSeriesDetails = ({ route, navigation, router }: { navigation?: any; router?: any; route?: any }) => {

//     const { height } = useWindowDimensions();
//     const { theme, toggle } = useContext(ThemeContext);

//     const params = isPlatformWeb ? router.query : route?.params;
//     const [focusedIten, setFocusedItem] = React.useState<any>("1");
//     const [episodes, setEpisodes] = React.useState();
//     const { t, i18n } = useTranslation();

//     // Fetcher function for series info
//     const getResource = async () => {
//         try {
//             const response = await axios.get(`${await PLAYLIST_STORAGE_URL()}/player_api.php?username=${await PLAYLIST_STORAGE_USERNAME()}&password=${await PLAYLIST_STORAGE_PASSWORD()}&action=get_series_info&series_id=${params.item.series_id}`);
//             return response.data;
//         } catch (error) {
//             throw new Error('Failed to fetch series info');
//         }
//     };

//     // Using the hook for series info
//     const { data, error, isLoading } = useQuery('seasons', getResource, {
//         refetchInterval: 6 * 60 * 60 * 1000,
//         cacheTime: 6 * 60 * 60 * 1000,
//     });

//     const onFocusSeason = (item: any) => {
//         setFocusedItem(item.season_number);
//     }

//     const renderSeasonsItem = ({ item, focusRepeatContext, index }: any) => {
//         return (
//             <Pressable
//                 style={[
//                     styles.genreitem
//                 ]}
//                 focusRepeatContext={focusRepeatContext}
//                 onFocus={() => onFocusSeason(item)}
//                 focusOptions={{
//                     animator: {
//                         type: 'background',
//                         focus: {
//                             backgroundColor: '#128DD3'
//                         },
//                     }
//                 }}
//             >
//                 <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", flex: 1 }}>
//                     <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 110 }}>{item.name}</Text>
//                 </View>
//             </Pressable>
//         );
//     };

//     const rowRenderer = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
//         return (
//             <Pressable
//                 onMouseDown={() => navigation.navigate(ROUTES.SERIE_PLAY, { item: item })}
//                 onPress={() => navigation.navigate(ROUTES.SERIE_PLAY, { item: item })}
//                 style={styles.recyclerItem}
//                 focusRepeatContext={focusRepeatContext}
//                 focusOptions={{
//                     animator: {
//                         type: "background",
//                         focus: {
//                             backgroundColor: "#128DD3"
//                         }
//                     }
//                 }}
//             >
//                 <View style={{ alignItems: 'center', justifyContent: 'center' }}>
//                     <Image
//                         resizeMode="cover"
//                         style={{
//                             width: Dimensions.get("window").width / 10.7,
//                             height: Dimensions.get("window").height / 7.7,
//                             borderRadius: 10,
//                             opacity: 0.7 // Bën imazhin pak më të errët
//                         }}
//                         source={{ uri: item.info.movie_image }}
//                     />
//                     <Text style={{
//                         color: "#fff", // Ngjyra e bardhë për tekstin
//                         fontSize: Ratio(18),
//                         position: 'absolute' // Vendos tekstin mbi imazh
//                     }}>
//                         {item.title}
//                     </Text>
//                 </View>
//             </Pressable>
//         );
//     };

//     const [tvHandlerRemote, setTvHandlerRemote] = React.useState<any>();

//     useTVRemoteHandler((remote: any) => {
//         setTvHandlerRemote(remote);
//     });

//     React.useEffect(() => {

//         if (tvHandlerRemote) {
//             if (isEngineRnWeb) {
//                 if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "back") {
//                     navigation.goBack();
//                 }
//             }
//         }

//     }, [tvHandlerRemote]);

//     return (
//         <Screen
//             style={[theme.styles.screen, { minHeight: height, backgroundColor: "#111" }]}
//             focusOptions={{ screenOrder: 4 }}
//         >
//             <View style={{ flex: 1 }}>
//                 <ImageBackground source={VODLayout} resizeMode="cover" style={{ flex: 1 }}>
//                     <View style={{ flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
//                         <View style={{ paddingLeft: isPlatformAndroidtv ? 30 : 90, paddingTop: isPlatformAndroidtv ? 20 : 50 }}>
//                             <View style={{ marginLeft: 0, marginBottom: 10, flexDirection: "row" }}>
//                                 {data &&
//                                     <Image
//                                         style={{ width: isPlatformAndroidtv ? 160 : 320, height: isPlatformAndroidtv ? 230 : 520, borderRadius: isPlatformAndroidtv ? 20 : 20 }}
//                                         source={{ uri: data.info.cover }}
//                                     />
//                                 }
//                                 {data &&
//                                     <View style={{ width: isPlatformAndroidtv ? 550 : 1200, marginLeft: isPlatformAndroidtv ? 30 : 70, marginTop: 5 }}>
//                                         {/* @ts-ignore */}
//                                         <Text style={styles.detailsTitle}>{params.item.name ? params.item.name.length > 40 ? params.item.name.substring(0, 40) + " ..." : params.item.name : ""}</Text>
//                                         {/* @ts-ignore */}
//                                         <Text style={{ color: "gray", fontSize: isPlatformAndroidtv ? windowWidth / 65 : windowWidth / 100 }}>
//                                             {data.info.plot}
//                                         </Text>
//                                     </View>
//                                 }
//                             </View>
//                             {/* @ts-ignore */}
//                             <Text style={styles.detailsTitle2}>Seasons & Episodes</Text>
//                             <View style={{ marginLeft: 0, marginBottom: 10 }}>
//                                 {data && data.seasons.length > 0 &&
//                                     <FlashList
//                                         data={data.seasons}
//                                         renderItem={renderSeasonsItem}
//                                         type="row"
//                                         estimatedItemSize={Dimensions.get("window").width / 10}
//                                         horizontal
//                                         showsHorizontalScrollIndicator={false}

//                                     />
//                                 }
//                                 <ScrollView contentContainerStyle={{ marginTop: !isWebBased ? 10 : 40 }}>
//                                     {data &&
//                                         <FlashList
//                                             key={focusedIten.season_number}
//                                             data={data.episodes[focusedIten]}
//                                             renderItem={rowRenderer}
//                                             type="row"
//                                             estimatedItemSize={Dimensions.get("window").width / 10}
//                                             horizontal
//                                             showsHorizontalScrollIndicator={false}

//                                         />
//                                     }
//                                 </ScrollView>
//                             </View>
//                         </View>
//                     </View>
//                 </ImageBackground>
//             </View>
//         </Screen>
//     )
// }

// const styles = StyleSheet.create({
//     background: {
//         backgroundColor: 'rgba(0, 0, 0, 0.7)'
//     },
//     detailsInfoContainer: {
//         flexDirection: "row"
//     },
//     poster: {
//         width: 200,
//         height: 280,
//         borderRadius: 20
//     },
//     relatedCarousel: {
//         paddingLeft: 20,
//         flex: 1
//     },
//     detailsTitle: {
//         color: "white",
//         fontSize: windowWidth / 40,
//         marginBottom: 20,
//         marginTop: 20,
//         fontWeight: "bold",
//     },
//     detailsTitle2: {
//         color: "white",
//         fontSize: Dimensions.get("window").width / 60,
//         marginTop: 50,
//         marginBottom: 20,
//         fontWeight: "bold"
//     },
//     genres: {
//         flexDirection: "row",
//         bottom: 10
//     },
//     genreitem: {
//         width: Dimensions.get("window").width / 8,
//         height: Dimensions.get("window").width / 40,
//         backgroundColor: "#2b3e54",
//         borderRadius: 15,
//         marginRight: !isWebBased ? 10 : 30,
//         marginTop: 10
//     },
//     detailsMenu: {
//         color: "#fff",
//         fontSize: 12,
//         marginRight: 4,
//         backgroundColor: "#222",
//         padding: 10,
//         borderRadius: 10,
//         top: 4
//     },
//     overview: {
//         color: "white",
//         fontSize: Dimensions.get("window").width / 70,
//         width: 500,
//         height: 80,
//         bottom: 10
//     },
//     button: {
//         borderRadius: 25,
//         width: Dimensions.get("window").width / 7,
//         height: Dimensions.get("window").width / 35,
//         alignItems: "center",
//         justifyContent: "center"
//     },
//     textButton: {
//         fontSize: Dimensions.get("window").width / 60,
//         color: "#fff",
//     },
//     carouselRelated: {
//         marginTop: 30
//     },
//     cardStyle: {
//         width: Dimensions.get("window").width / 3.88,
//         height: Dimensions.get("window").width / 6.2,
//         borderWidth: isFactorMobile ? 0 : Ratio(5),
//         borderRadius: Ratio(5),
//         borderColor: 'transparent',
//         backgroundColor: "#222",
//         fontSize: isFactorMobile ? 16 : Ratio(26),
//         marginHorizontal: isFactorTv ? Ratio(5) : 0,
//         marginVertical: isFactorTv ? Ratio(15) : 0,
//     },
//     image: {
//         width: Dimensions.get("window").width / 9.5,
//         height: Dimensions.get("window").width / 6.3
//     },
//     recyclerItem: {
//         width: Dimensions.get("window").width / 10,
//         height: Dimensions.get("window").height / 7,
//         justifyContent: "center",
//         backgroundColor: "#2b3e54",
//         marginRight: 20,
//         borderRadius: 10,
//     },
// });

// export default ScreenSeriesDetails;

import React, { useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions, BackHandler } from 'react-native';
import { Text, View, ScrollView, Image, FlashList, ImageBackground, StyleSheet, useTVRemoteHandler, Pressable, CreateListRenderItemInfo } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext, isWebBased } from '../../config';
import logo from "../../assets/logo.png";
import { useQuery } from 'react-query';
import { isFactorMobile, isPlatformMacos, isPlatformWeb, isFactorTv, isPlatformAndroidtv, isPlatformTizen, isEngineRnWeb } from '@rnv/renative';
import VODLayout from "../../assets/layers/vod_preview_layer.png";
import { PLAYLIST_STORAGE_PASSWORD, PLAYLIST_STORAGE_URL, PLAYLIST_STORAGE_USERNAME } from '../../storage/PlayListStorage';
import axios from 'axios';
import { VPN_STORAGE } from '../../storage/VPNStorage';
import { PROXY_URL } from '../../storage/ProxyUrl';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import Icon from 'react-native-vector-icons/MaterialIcons';

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

const ScreenSeriesDetails = ({ route, navigation, router }: { navigation?: any; router?: any; route?: any }) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    const params = isPlatformWeb ? router.query : route?.params;
    const [focusedIten, setFocusedItem] = React.useState<any>("1");
    const [episodes, setEpisodes] = React.useState();

    const { t, i18n } = useTranslation();

    // Fetcher function for series info
    const getResource = async () => {
        try {

            const vpnstorage = await VPN_STORAGE();
            const proxy_url = await PROXY_URL();

            const playlistStorageUrl = await PLAYLIST_STORAGE_URL();
            const playlistStorageUsername = await PLAYLIST_STORAGE_USERNAME();
            const playlistStoragePassword = await PLAYLIST_STORAGE_PASSWORD();

            let encodedStream = Buffer.from(`${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}&action=get_series_info&series_id=${params.item.series_id}`, 'utf-8').toString('base64');
            let decodedStream = `${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}&action=get_series_info&series_id=${params.item.series_id}`;

            const response = await fetch(vpnstorage ? vpnstorage == "on" ? proxy_url + encodedStream : decodedStream : decodedStream);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch series info');
        }
    };

    // Using the hook for series info
    const { data, error, isLoading } = useQuery('seasons', getResource, {
        cacheTime: 6 * 60 * 60 * 1000,
    });

    const onFocusSeason = (item: any) => {
        setFocusedItem(item.season_number);
    }

    const renderSeasonsItem = ({ item, focusRepeatContext, index }: any) => {
        return (
            <Pressable
                style={[
                    styles.genreitem
                ]}
                focusRepeatContext={focusRepeatContext}
                onFocus={() => onFocusSeason(item)}
                focusOptions={{
                    animator: {
                        type: 'background',
                        focus: {
                            backgroundColor: '#128DD3'
                        },
                    }
                }}
            >
                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", flex: 1 }}>
                    <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 75 }}>{item.name}</Text>
                </View>
            </Pressable>
        );
    };

    const rowRenderer = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable
                onPress={() => navigation.navigate(ROUTES.SERIE_PLAY, { item: item })}
                style={styles.recyclerItem}
                focusRepeatContext={focusRepeatContext}
                focusOptions={{
                    animator: {
                        type: "background",
                        focus: {
                            backgroundColor: "#128DD3"
                        }
                    }
                }}
            >
                <View style={{ flexDirection: "row" }}>
                    <View>
                        <Image resizeMode={'cover'} source={{ uri: !item.info.movie_image ? "https://assets.orbixplay.com/no_movie_image_placeholder.png" : item.info.movie_image }} style={{
                            width: Dimensions.get("window").height / 10,
                            height: Dimensions.get("window").height / 10,
                            borderTopRightRadius: 10,
                            borderTopLeftRadius: 10,
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                            alignSelf: 'center',
                        }} />
                    </View>
                    <View style={{ padding: Ratio(25), justifyContent: "center" }}>
                        <Text style={{ fontSize: windowWidth / 70, fontWeight: "bold", color: "white", marginLeft: Ratio(3) }}>{item.title ? item.title.length > 30 ? item.title.substring(0, 30) + " ..." : item.title : ""}</Text>
                    </View>
                </View>
            </Pressable>
        );
    };

    const handleBack = () => {
        navigation.goBack();
        return true;
    }

    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', handleBack);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', handleBack);
        };
    }, []);

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#111" }]}
            focusOptions={{ screenOrder: 4 }}
        >
            <ImageBackground source={VODLayout} resizeMode="cover" style={{ flex: 1 }}>
                <View style={{ flexDirection: "row" }}>
                    <View style={{ flex: 1 }}>
                        <View style={{ alignItems: "center", marginTop: Ratio(40) }}>
                            {data &&
                                <Image
                                    style={{ width: Ratio(400), height: Ratio(600), borderRadius: Ratio(10) }}
                                    source={{ uri: data.info.cover == "" || data.info.cover == null ? "https://assets.orbixplay.com/no_movie_image_placeholder.png" : data.info.cover }}

                                />
                            }
                            {data &&
                                <View style={{ width: Ratio(550) }}>
                                    <Text style={styles.detailsTitle}>
                                        {params.item.name ? params.item.name.substring(0, 30) + (params.item.name.length > 30 ? " ..." : "") : ""}
                                    </Text>
                                    <Text style={{ color: "gray", fontSize: windowWidth / 90, alignSelf: "center", textAlign: "center" }}>
                                        {data.info.plot ? data.info.plot.substring(0, 300) + (data.info.plot.length > 300 ? " ..." : data.info.plot) : ""}
                                    </Text>
                                </View>
                            }
                        </View>
                    </View>
                    <View style={{ flex: 2 }}>
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ flex: 1 }}>
                                {data && data.seasons.length > 0 ?
                                    <FlashList
                                        data={data.seasons}
                                        renderItem={renderSeasonsItem}
                                        type="row"
                                        ListHeaderComponent={() => (
                                            <Text style={styles.detailsTitle2}>{t('serials.seasons')}</Text>
                                        )}
                                        style={{
                                            height: height
                                        }}
                                        estimatedItemSize={Dimensions.get("window").width / 10}
                                        horizontal={false}
                                        showsHorizontalScrollIndicator={false}

                                    />
                                    :
                                    <>
                                        <Text style={styles.detailsTitle2}>{t('serials.seasons')}</Text>
                                        <View style={{ backgroundColor: "#2b3e54", width: Ratio(500), height: Ratio(600), borderRadius: Ratio(10), justifyContent: "center", alignItems: "center" }}>
                                            <Icon name='hourglass-empty' size={80} color="#fff" style={{ opacity: 0.2 }} />
                                        </View>
                                    </>
                                }
                            </View>
                            <View style={{ flex: 2 }}>
                                {data &&
                                    <FlashList
                                        key={focusedIten.season_number}
                                        data={data.episodes[focusedIten]}
                                        renderItem={rowRenderer}
                                        ListHeaderComponent={() => (
                                            <Text style={styles.detailsTitle2}>{t('serials.episodes')}</Text>
                                        )}
                                        type="row"
                                        estimatedItemSize={Dimensions.get("window").width / 10}
                                        style={{
                                            height: height
                                        }}
                                        horizontal={false}
                                        showsHorizontalScrollIndicator={false}

                                    />
                                }
                            </View>
                        </View>
                    </View>
                </View>
            </ImageBackground>
        </Screen>
    )
}

const styles = StyleSheet.create({
    background: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    detailsInfoContainer: {
        flexDirection: "row"
    },
    poster: {
        width: 200,
        height: 280,
        borderRadius: 20
    },
    relatedCarousel: {
        paddingLeft: 20,
        flex: 1
    },
    detailsTitle: {
        color: "white",
        alignSelf: "center",
        marginTop: Ratio(40),
        fontSize: windowWidth / 40,
        fontWeight: "bold",
    },
    detailsTitle2: {
        color: "white",
        fontSize: Dimensions.get("window").width / 50,
        marginTop: Ratio(50),
        marginBottom: Ratio(20),
        fontWeight: "bold",
    },
    genres: {
        flexDirection: "row",
        bottom: 10,
    },
    genreitem: {
        width: Dimensions.get("window").width / 5,
        height: Dimensions.get("window").width / 30,
        backgroundColor: "#2b3e54",
        borderRadius: Ratio(10),
        marginRight: !isWebBased ? 10 : 30,
        marginTop: 10
    },
    detailsMenu: {
        color: "#fff",
        fontSize: 12,
        marginRight: 4,
        backgroundColor: "#222",
        padding: 10,
        borderRadius: 10,
        top: 4
    },
    overview: {
        color: "white",
        fontSize: Dimensions.get("window").width / 70,
        width: 500,
        height: 80,
        bottom: 10
    },
    button: {
        borderRadius: 25,
        width: Dimensions.get("window").width / 7,
        height: Dimensions.get("window").width / 35,
        alignItems: "center",
        justifyContent: "center"
    },
    textButton: {
        fontSize: Dimensions.get("window").width / 60,
        color: "#fff",
    },
    carouselRelated: {
        marginTop: 30
    },
    cardStyle: {
        width: Dimensions.get("window").width / 3.88,
        height: Dimensions.get("window").width / 6.2,
        borderWidth: isFactorMobile ? 0 : Ratio(5),
        borderRadius: Ratio(5),
        borderColor: 'transparent',
        backgroundColor: "#222",
        fontSize: isFactorMobile ? 16 : Ratio(26),
        marginHorizontal: isFactorTv ? Ratio(5) : 0,
        marginVertical: isFactorTv ? Ratio(15) : 0,
    },
    image: {
        width: Dimensions.get("window").width / 9.5,
        height: Dimensions.get("window").width / 6.3
    },
    recyclerItem: {
        width: Dimensions.get("window").width / 2.5,
        height: Dimensions.get("window").height / 10,
        justifyContent: "center",
        backgroundColor: "#2b3e54",
        borderRadius: 10,
        marginBottom: 10,
        marginRight: 20
    },
});

export default ScreenSeriesDetails;