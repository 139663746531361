import React, { useCallback, useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions, ActivityIndicator } from 'react-native';
import { Text, View, ScrollView, Image, ImageBackground, useTVRemoteHandler, StyleSheet, Pressable } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext, isWebBased } from '../../config';
import logo from "../../assets/logo.png";
import { isPlatformAndroidtv, isPlatformTizen, getScaledValue, isPlatformWebos, isPlatformWeb } from '@rnv/renative';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GET_AUTH_TOKEN, GET_DEVICE_ID, GET_LICENSE_DATE } from '../../storage/AuthStorage';
import { useQuery, useQueryClient } from 'react-query';
import vpnicon from "../../assets/home/vpnicon.png";
import packagejson from "../../../package.json";
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { Buffer } from 'buffer';
import { PLAYLIST_STORAGE_PASSWORD, PLAYLIST_STORAGE_URL, PLAYLIST_STORAGE_USERNAME } from '../../storage/PlayListStorage';
import { differenceInDays, parse } from 'date-fns';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { SAVE_DATA_TO_FILE } from '../../services/saveDataToStorage';
import ConfirmDialog from '../components/ConfirmDialog';
import * as Sentry from "@sentry/react";
import { useTranslation } from 'react-i18next';

const ScreenPlaylist = ({ navigation, route, router }: { navigation?: any, route: any, router: any }) => {

    const queryClient = useQueryClient();

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    const [isloading, setIsLoading] = React.useState(false);
    const { t, i18n } = useTranslation();

    const params = isPlatformWeb ? router.query : route?.params;

    const [showDialogRemovePlaylist, setShowDialogRemovePLaylist] = React.useState(false);
    const [vpn, setVpn] = React.useState("off");

    const [deviceid, setDeviceId] = React.useState("");
    const [playlistName, setPlaylistName] = React.useState("");

    const [daysRemaining, setDaysRemaining] = React.useState(0);

    const getResourceQrCodePlaylist = async () => {
        try {
            const authToken = await GET_AUTH_TOKEN();
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/live-lbgjx/endpoint/getqrcodeplaylist', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch resource QR code');
        }
    };

    const { data: getqrocode, error: errgetqrcode, isLoading: isLoadingGetQrcode } = useQuery('add-playlist', getResourceQrCodePlaylist, {
        refetchInterval: 6 * 60 * 60 * 1000,
        cacheTime: 6 * 60 * 60 * 1000,
    });

    const checkURL = useCallback(async (data: any, proxy_url: string) => {

        const vpnStatus = await AsyncStorage.getItem("@vpn");

        let url = `${data.url}/player_api.php?username=${data.username}&password=${data.password}`;
        let encodedStream = Buffer.from(url, 'utf-8').toString('base64');

        if (vpnStatus == "on") {

            try {
                const response = await axios.get(proxy_url + encodedStream, { timeout: 3000 });
                if (response.status == 200) {
                    return {
                        status: true,
                        message: response.data ? response.data.user_info.status : "Not Valid"
                    };
                } else {
                    return {
                        status: false,
                        message: "Not Valid"
                    };
                }
            } catch (error) {
                console.log(error);
                Sentry.captureException;
                return {
                    status: false,
                    message: "Not Valid"
                };
            }

        } else {

            try {
                const response = await axios.get(url, { timeout: 3000 });
                if (response.status == 200) {
                    return {
                        status: true,
                        message: response.data ? response.data.user_info.status : "Not Valid"
                    };
                } else {
                    return {
                        status: false,
                        message: "Not Valid"
                    };
                }
            } catch (error) {
                Sentry.captureException;
                return {
                    status: false,
                    message: "Not Valid"
                };
            }

        }

    }, [vpn]);

    const getResourceUserData = useCallback(async () => {

        try {

            const authToken = await GET_AUTH_TOKEN();
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/live-lbgjx/endpoint/getmydata', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const dat = await response.json();

            await AsyncStorage.setItem('@proxy_url', dat.proxy_url);

            const playlistsWithStatus: any = await Promise.all(dat.playlists.map(async (element: any) => {

                const status: any = await checkURL(element, dat.proxy_url);

                return {
                    playlist: element,
                    status: status.status,
                    message: status.message,
                    proxy_url: dat.proxy_url
                };

            }));

            return {
                playlists: playlistsWithStatus,
                license_end_date: dat.license_end_date,
                vpn_license_end_date: dat.vpn_license_end_date,
                package_name: dat.package_name ? dat.package_name : "No Subscription"
            };

        } catch (error: any) {
            throw new Error(error);
        }

    }, [vpn]);

    const { data: getuserdata, error: erruserdata, isLoading: isLoadingUserData, refetch } = useQuery('user-data', getResourceUserData);

    const updatePlaylistsOnDb = async (playlist: any) => {

        const authToken = await GET_AUTH_TOKEN();

        try {

            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/live-lbgjx/endpoint/hideplaylist', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "playlistName": playlist
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update playlist');
            }

            const data = await response.json();
            refetch();
            setShowDialogRemovePLaylist(false);

            return data;

        } catch (error) {
            console.error("Error updating playlist:", error);
        }

    };

    const calculateDaysRemaining = async () => {
        const token = await GET_AUTH_TOKEN();
        const decoded: any = jwtDecode(`${token}`);
        refetch();
        const licenseDate = new Date(Number(decoded.user_data.license_end_date));
        const remainingDays = differenceInDays(licenseDate, new Date());
        setDaysRemaining(remainingDays);
    };

    const toggleVpn = async () => {
        if (vpn === "on") {
            setVpn("off");
            // await AsyncStorage.setItem("@vpn", "off");
        } else {
            setVpn("on");
            // await AsyncStorage.setItem("@vpn", "on");
        }
        refetch();
    };

    const checkTimestamp = (timestamp: any) => {
        var dataAktuale = new Date();
        if (timestamp.toString().length > 10) {
            timestamp = Math.floor(timestamp / 1000);
        }
        var dataTimestamp = new Date(timestamp * 1000);
        return dataTimestamp > dataAktuale;
    }

    const checkLicense = async () => {
        const token = await GET_AUTH_TOKEN();
        const decoded: any = jwtDecode(`${token}`);
        return checkTimestamp(decoded.user_data.license_end_date.$date.$numberLong);
    }

    React.useEffect(() => {
        calculateDaysRemaining();
    }, [daysRemaining]);

    React.useEffect(() => {
        if (params) {
            calculateDaysRemaining();
        }
    }, [params]);

    // React.useEffect(() => {
    //     const getVpnStatus = async () => {
    //         const vpnStatus: any = await AsyncStorage.getItem("@vpn");
    //         console.log(vpnStatus);
    //         setVpn(vpnStatus || "off");
    //     };
    //     getVpnStatus();
    // }, [vpn]);

    React.useEffect(() => {
        const getDeviceId = async () => {
            const did: any = await AsyncStorage.getItem("@device_id");
            setDeviceId(did);
        };
        getDeviceId();
    }, [deviceid]);

    useTVRemoteHandler((remote: any) => {

        const currentScreenName = navigation.getState().routes[navigation.getState().index].name;

        if (remote.eventKeyAction == "down" && remote.eventType == "back") {
            if (currentScreenName == "/playlist") {

                // if (showDialogRemovePlaylist) {
                //     setShowDialogRemovePLaylist(false);
                // }

                if (!showDialogRemovePlaylist) {
                    if (isPlatformTizen) {
                        if (confirm("Do you want to exit ?")) {
                            {/* @ts-ignore */ }
                            tizen.application.getCurrentApplication().exit();
                        } else {
                            console.log("User chose to stay in the app.");
                        }
                    } else if (isPlatformWebos) {
                        {/* @ts-ignore */ }
                        webOS.platformBack();
                    }
                }

            }
        }

    });

    const formatDate = (dateString: any) => {
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Muajt në JavaScript janë 0-bazë, prandaj shtojmë 1
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const cancelDialogRemovePlaylist = (data: boolean) => {
        if (data) {
            setShowDialogRemovePLaylist(false);
        }
    }

    const confirmDialogRemovePlaylist = async (data: boolean) => {
        if (playlistName) {
            await updatePlaylistsOnDb(playlistName);
        }
    }

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#0A1C34", flexDirection: "row", padding: Dimensions.get("screen").width / 30 }]}
            focusOptions={{ verticalWindowAlignment: 'low-edge', focusKey: 'page' }}
        >
            <View style={{ flex: 1 }}>
                <ScrollView
                    style={{ marginTop: Ratio(100), flex: 0.9 }}
                    stickyHeaderIndices={[0]}
                >
                    <View style={{ flexDirection: "row", backgroundColor: "#0A1C34" }}>
                        <View style={{ flex: 1, marginBottom: Ratio(-17), paddingTop: Ratio(10) }}>
                            <Text style={{ color: "gray", marginBottom: 10, fontSize: Dimensions.get("window").width / 70, textTransform: "uppercase", fontWeight: "bold" }}>
                                {t('playlists.playlist')}
                            </Text>
                            <View style={{ marginTop: 20 }}>
                                {getuserdata?.playlists.length == 0 &&
                                    <View>
                                        <Text style={{ color: "gray", marginBottom: 10, fontSize: Dimensions.get("window").width / 95 }}>{t('playlists.dont_have_playlist_label')}</Text>
                                    </View>
                                }
                            </View>
                        </View>
                    </View>
                    <View>
                        {getuserdata?.playlists.length > 0 &&
                            <View>
                                {getuserdata?.playlists.map((record: any, index: number) => (
                                    <View style={{ flexDirection: "row" }} key={index}>
                                        <Pressable
                                            style={{
                                                flex: 1,
                                                height: Dimensions.get("window").width / 27,
                                                backgroundColor: "#2b3e54",
                                                paddingLeft: 30,
                                                paddingRight: 30,
                                                marginBottom: 10,
                                                flexDirection: "row",
                                                borderRadius: 20
                                            }}
                                            onMouseDown={async () => {

                                                if (record.playlist.whitelist) {

                                                    if ((await checkURL(record.playlist, record.proxy_url)).status) {
                                                        await AsyncStorage.setItem("@active_playlist", JSON.stringify({
                                                            hide_playlist_info: record.playlist.hide_playlist_info,
                                                            url: record.playlist.url,
                                                            whitelist: record.playlist.whitelist,
                                                            username: record.playlist.username,
                                                            password: record.playlist.password
                                                        }))
                                                        queryClient.resetQueries();
                                                        await AsyncStorage.setItem("@proxy_url", record.proxy_url);
                                                        navigation.navigate(ROUTES.HOME, { subscriptiondata: { license_end_date: getuserdata?.license_end_date, vpn_license_end_date: getuserdata?.vpn_license_end_date } });
                                                    } else {
                                                        alert("Your playlist is not available.")
                                                    }

                                                } else {

                                                    if (new Date(getuserdata?.license_end_date) < new Date()) {
                                                        navigation.navigate(ROUTES.CHECK_LICENSE, { navigation: navigation });
                                                    } else {

                                                        if ((await checkURL(record.playlist, record.proxy_url)).status) {
                                                            await AsyncStorage.setItem("@active_playlist", JSON.stringify({
                                                                hide_playlist_info: record.playlist.hide_playlist_info,
                                                                url: record.playlist.url,
                                                                whitelist: record.playlist.whitelist,
                                                                username: record.playlist.username,
                                                                password: record.playlist.password
                                                            }))
                                                            queryClient.resetQueries();
                                                            await AsyncStorage.setItem("@proxy_url", record.proxy_url);
                                                            navigation.navigate(ROUTES.HOME, { subscriptiondata: { license_end_date: getuserdata?.license_end_date, vpn_license_end_date: getuserdata?.vpn_license_end_date } });
                                                        } else {
                                                            alert("Your playlist is not available.")
                                                        }

                                                    }

                                                }

                                            }}
                                            onPress={async () => {

                                                if (record.playlist.whitelist) {

                                                    if ((await checkURL(record.playlist, record.proxy_url)).status) {
                                                        await AsyncStorage.setItem("@active_playlist", JSON.stringify({
                                                            hide_playlist_info: record.playlist.hide_playlist_info,
                                                            url: record.playlist.url,
                                                            whitelist: record.playlist.whitelist,
                                                            username: record.playlist.username,
                                                            password: record.playlist.password
                                                        }))
                                                        queryClient.resetQueries();
                                                        await AsyncStorage.setItem("@proxy_url", record.proxy_url);
                                                        navigation.navigate(ROUTES.HOME, { subscriptiondata: { license_end_date: getuserdata?.license_end_date, vpn_license_end_date: getuserdata?.vpn_license_end_date } });
                                                    } else {
                                                        alert("Your playlist is not available.")
                                                    }

                                                } else {

                                                    if (new Date(getuserdata?.license_end_date) < new Date()) {
                                                        navigation.navigate(ROUTES.CHECK_LICENSE, { navigation: navigation });
                                                    } else {

                                                        if ((await checkURL(record.playlist, record.proxy_url)).status) {
                                                            await AsyncStorage.setItem("@active_playlist", JSON.stringify({
                                                                hide_playlist_info: record.playlist.hide_playlist_info,
                                                                url: record.playlist.url,
                                                                whitelist: record.playlist.whitelist,
                                                                username: record.playlist.username,
                                                                password: record.playlist.password
                                                            }))
                                                            queryClient.resetQueries();
                                                            await AsyncStorage.setItem("@proxy_url", record.proxy_url);
                                                            navigation.navigate(ROUTES.HOME, { subscriptiondata: { license_end_date: getuserdata?.license_end_date, vpn_license_end_date: getuserdata?.vpn_license_end_date } });
                                                        } else {
                                                            alert("Your playlist is not available.")
                                                        }

                                                    }

                                                }

                                            }}
                                            focusOptions={{
                                                animator: {
                                                    type: "background",
                                                    focus: {
                                                        backgroundColor: "#128DD3"
                                                    }
                                                }
                                            }}
                                        >
                                            <View style={{ flex: 1, justifyContent: "center" }}>
                                                <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 70 }}>
                                                    {record.playlist.playlistName}
                                                </Text>
                                            </View>
                                            <View style={{ alignSelf: "center", marginRight: 20, marginLeft: Ratio(20), width: Ratio(100) }}>
                                                {record.message &&
                                                    <Text style={{ fontSize: Ratio(20), color: record.message == "Expired" ? "orange" : record.message == "Active" ? "green" : "#f03030" }}>{record.message}</Text>
                                                }
                                            </View>
                                            <View style={{ alignSelf: "center" }}>
                                                {record.status ?
                                                    <View style={{ width: 40, height: 40, backgroundColor: record.message == "Expired" ? "orange" : "green", borderRadius: 100 }} />
                                                    :
                                                    <View style={{ width: 40, height: 40, backgroundColor: record.message == "Expired" ? "orange" : "#f03030", borderRadius: 100 }} />
                                                }
                                            </View>
                                        </Pressable>
                                        <Pressable
                                            onMouseDown={() => {
                                                setShowDialogRemovePLaylist(true);
                                                setPlaylistName(record.playlist.playlistName);
                                                // if (window.confirm("Do you want to remove ?")) {
                                                //     updatePlaylistsOnDb(record.playlist);
                                                // } else {
                                                //     console.log("cancel remove");
                                                // }
                                            }}
                                            onPress={() => {
                                                setShowDialogRemovePLaylist(true);
                                                setPlaylistName(record.playlist.playlistName);

                                                // if (window.confirm("Do you want to remove ?")) {
                                                //     updatePlaylistsOnDb(record.playlist);
                                                // } else {
                                                //     console.log("cancel remove");
                                                // }
                                            }}
                                            style={{
                                                height: Dimensions.get("window").width / 27,
                                                width: Dimensions.get("window").width / 27,
                                                borderRadius: 20,
                                                marginRight: Ratio(20),
                                                marginLeft: Ratio(10),
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor: "#2b3e54"
                                            }}
                                            focusOptions={{
                                                animator: {
                                                    type: "background",
                                                    focus: {
                                                        backgroundColor: "#128DD3"
                                                    }
                                                }
                                            }}
                                        >
                                            <View>
                                                <Icon name='delete' size={40} color="#ddd" />
                                            </View>
                                        </Pressable>
                                    </View>
                                ))}
                            </View>
                        }
                    </View>
                    <View>
                        <View style={{ flexDirection: "row", marginTop: Ratio(50) }}>
                            <View style={{ flex: 0.5 }}>
                                <Pressable
                                    onPress={() => {
                                        toggleVpn();
                                    }}
                                    style={{
                                        backgroundColor: vpn == "on" ? "#33cc33" : "#808080",
                                        height: Dimensions.get("window").width / 27,
                                        paddingLeft: 30,
                                        marginTop: !isWebBased ? 10 : 30,
                                        justifyContent: "center",
                                        marginRight: 10,
                                        borderRadius: 20
                                    }}
                                    focusOptions={{
                                        animator: {
                                            type: 'background',
                                            focus: {
                                                backgroundColor: "#128DD3"
                                            }
                                        }
                                    }}
                                >
                                    <View style={{ flexDirection: "row" }}>
                                        <Image resizeMode="contain" source={vpnicon} style={{ width: Dimensions.get("window").width / 60, height: Dimensions.get("window").width / 60, marginRight: Ratio(20), marginTop: 2 }} />
                                        <Text style={{ fontSize: Dimensions.get("window").width / 70, color: "#0A1C34", textTransform: "uppercase", fontWeight: "bold" }}>{vpn == "on" ? t('playlists.privacy_mode_label') : t('playlists.privacy_mode_label')}</Text>
                                    </View>
                                </Pressable>
                            </View>
                            <View style={{ flex: 0.49 }}>
                                <Pressable
                                    style={{
                                        height: Dimensions.get("window").width / 27,
                                        paddingLeft: 30,
                                        backgroundColor: "#2b3e54",
                                        marginTop: !isWebBased ? 10 : 30,
                                        justifyContent: "center",
                                        marginRight: 10,
                                        borderRadius: 20
                                    }}
                                    onPress={() => refetch()}
                                    onMouseDown={() => refetch()}
                                    focusOptions={{
                                        animator: {
                                            type: "background",
                                            focus: {
                                                backgroundColor: "#128DD3"
                                            }
                                        }
                                    }}
                                >
                                    <View style={{ flexDirection: "row" }}>
                                        <FontAwesome color="#ddd" size={Dimensions.get("window").width / 60} style={{ marginRight: Ratio(20), marginTop: 2 }} name="refresh" />
                                        <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 70, textTransform: "uppercase", fontWeight: "bold" }}>
                                            {t('playlists.refresh_label')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </ScrollView>
                <View style={{ flex: 0.05 }}>
                    <View>
                        <Text style={{ color: "#ddd", fontWeight: "bold", fontSize: Dimensions.get("window").width / 110 }}>{t('playlists.license_label')}</Text>
                        {new Date() > new Date(getuserdata?.license_end_date) ?
                            <Text style={{ color: "#ddd", fontWeight: "bold", fontSize: Dimensions.get("window").width / 80 }}>{getuserdata?.package_name} - {t('playlists.expired_label')}</Text>
                            :
                            <Text style={{ color: "#ddd", fontWeight: "bold", fontSize: Dimensions.get("window").width / 80 }}>{getuserdata?.package_name} {
                                new Date(getuserdata?.license_end_date).getFullYear() < 2900 ? t('playlists.expires_in_label') + formatDate(getuserdata?.license_end_date)
                                    :
                                    getuserdata?.package_name == "Premium" ? " - " + formatDate(getuserdata?.vpn_license_end_date)
                                        :
                                        t('playlists.lifetime_label')
                            }</Text>
                        }
                    </View>
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <View style={{ alignItems: "center" }}>
                    <Image source={logo} style={{ width: !isWebBased ? 160 : 300, height: !isWebBased ? 50 : 100 }} />
                </View>
                <Text style={{ color: "gray", fontSize: Dimensions.get("window").width / 90, fontWeight: "bold", marginBottom: 22, textAlign: "center", alignSelf: "center", textTransform: "uppercase" }}>
                    {t('playlists.scan_playlist_label')}
                </Text>
                <View
                    style={{
                        alignSelf: "center",
                        backgroundColor: "#fff",
                        width: !isWebBased ? Dimensions.get("window").width / 3.8 : Dimensions.get("window").width / 4.5,
                        borderRadius: 20,
                        height: !isWebBased ? Dimensions.get("window").width / 3.8 : Dimensions.get("window").width / 4.5,
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    {getqrocode &&
                        <Image source={{ uri: getqrocode?.qr_playlist_url }} style={{
                            width: !isWebBased ? Dimensions.get("window").width / 4 : Dimensions.get("window").width / 5,
                            height: !isWebBased ? Dimensions.get("window").width / 4 : Dimensions.get("window").width / 5,
                        }} />
                    }
                </View>
                <View style={{ alignSelf: "center", marginTop: 10, width: 500, alignItems: "center" }}>
                    <Text style={{ marginTop: 10, fontSize: Dimensions.get("window").width / 80, color: "gray", textAlign: "center" }}>or visit</Text>
                    <Text style={{ marginTop: 10, fontSize: Dimensions.get("window").width / 80, color: "#ddd", textAlign: "center" }}><span style={{ color: "#128DD3" }}>https://orbixplay.com/mylist</span> {t('playlists.enter_device_id_label')}</Text>
                    <Text style={{ marginTop: 10, width: 1000, fontSize: Dimensions.get("window").width / 70, color: "orange", textAlign: "center", textTransform: "uppercase" }}>{deviceid}</Text>
                </View>
                <Text style={{ color: "#ddd", alignSelf: "center", fontSize: Dimensions.get("window").width / 120, marginTop: Dimensions.get("window").width / 18 }}>{t('playlists.version_label')}. {packagejson.version}</Text>
            </View>
            {showDialogRemovePlaylist &&
                <View style={{ position: "absolute", bottom: 150 }}>
                    <ConfirmDialog type="info" title={t('playlists.remove_playlists_label') + playlistName} description={t('playlists.want_to_remove_label')} cancelDialog={cancelDialogRemovePlaylist} confirmDialog={confirmDialogRemovePlaylist} />
                </View>
            }
        </Screen>
    )

}

export default ScreenPlaylist;