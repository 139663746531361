import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode from 'jwt-decode';

export const GET_AUTH_TOKEN = async () => {
	try {

		const value = await AsyncStorage.getItem('@access_token');
		const decodedToken: any = jwtDecode(`${value}`);

		const expirationTime = decodedToken?.exp * 1000 - 2 * 60 * 1000;

		if (Date.now() >= expirationTime) {

			try {

				const refreshToken = await AsyncStorage.getItem("@refresh_token");

				const response = await fetch(
					"https://services.cloud.mongodb.com/api/client/v2.0/auth/session",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${refreshToken}`,
						},
					}
				);

				const data = await response.json();
				await AsyncStorage.setItem("@access_token", data.access_token);

				if (data.access_token) {
					return data.access_token;
				}

				console.log("token refreshed", data.access_token);

			} catch (error) {
				console.log(error, "refresh token error");
			}

		} else {
			if (value !== null) {
				return value;
			}
		}

	} catch (e) {
		// error reading value
	}
}

export const GET_LICENSE_DATE = async () => {
	try {
		const value = await AsyncStorage.getItem('@license_timestamp');
		if (value !== null) {
			return value;
		}
	} catch (e) {
		// error reading value
	}
}

export const GET_DEVICE_ID = async () => {
	try {
		const value = await AsyncStorage.getItem('@device_id');
		if (value !== null) {
			return value;
		}
	} catch (e) {
		// error reading value
	}
}