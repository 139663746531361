import React, { useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions } from 'react-native';
import { Text, View, ScrollView, Image, ImageBackground, useTVRemoteHandler, StyleSheet, Pressable } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext, isWebBased } from '../../config';
import logo from "../../assets/logo.png";
import { isPlatformAndroidtv, isPlatformTizen, getScaledValue, isPlatformWeb } from '@rnv/renative';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GET_AUTH_TOKEN, GET_LICENSE_DATE } from '../../storage/AuthStorage';
import { useQuery } from 'react-query';
import vpnicon from "../../assets/home/vpnicon.png";
import Icon from 'react-native-vector-icons/Ionicons';
import { PLAYLIST_STORAGE_PASSWORD, PLAYLIST_STORAGE_URL, PLAYLIST_STORAGE_USERNAME } from '../../storage/PlayListStorage';
import { Buffer } from 'buffer';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

const ScreenMorePlaylist = ({ navigation, route, router }: { navigation?: any, route: any, router: any }) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);
    const params = isPlatformWeb ? router.query : route?.params;

    const [date, setDate] = React.useState(new Date());

    const checkTimestamp = (timestamp: any) => {
        var dataAktuale = new Date();
        if (timestamp.toString().length > 10) {
            timestamp = Math.floor(timestamp / 1000);
        }
        var dataTimestamp = new Date(timestamp * 1000);
        return dataTimestamp > dataAktuale;
    }

    const [license, setLicense] = React.useState(true);

    const [vpn, setVpn] = React.useState("off");

    React.useEffect(() => {
        const getVpnStatus = async () => {
            const vpnStatus = await AsyncStorage.getItem("@vpn");
            setVpn(vpnStatus || "off");
        };

        getVpnStatus();
    }, [date]);

    const getProxyData = async () => {
        try {
            const authToken = await GET_AUTH_TOKEN();
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/live-lbgjx/endpoint/getmyproxy', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch resource user data');
        }
    };

    const { data: proxydata, error: errproxy, isLoading: isLProxy } = useQuery('proxy-data', getProxyData, {
        cacheTime: 6 * 60 * 60 * 1000
    });


    const checkURL = async (data: any) => {
        const vpnStatus = await AsyncStorage.getItem("@vpn");

        try {
            let url = `${data.url}/player_api.php?username=${data.username}&password=${data.password}&action=get_live_streams`;
            let encodedStream = Buffer.from(url, 'utf-8').toString('base64');

            if (vpnStatus == "on") {
                if (proxydata) {
                    try {
                        const response = await axios.get(proxydata.proxy_url + encodedStream, { timeout: 10000 });
                        console.log("active proxy");
                        if (response.status == 200) {
                            return true;
                        } else {
                            return false;
                        }
                    } catch (error) {
                        return false;
                    }
                }
            } else {
                try {
                    const response = await axios.get(url, { timeout: 10000 });
                    console.log("inactive proxy");
                    if (response.status == 200) {
                        return true;
                    } else {
                        return false;
                    }
                } catch (error) {
                    return false;
                }
            }
        } catch (error) {
            return false;
        }
    };

    const getResourceUserData = async () => {

        try {

            const authToken = await GET_AUTH_TOKEN();
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/live-lbgjx/endpoint/getmydata', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const dat = await response.json();

            console.log(dat);

            const playlistsWithStatus: any = await Promise.all(dat.playlists.map(async (element: any) => {
                const status = await checkURL(element);
                return {
                    playlist: element,
                    status: status
                };
            }));

            return playlistsWithStatus;

        } catch (error) {
            throw new Error('Failed to fetch resource user data');
        }

    };

    const { data: getuserdata, error: erruserdata, isLoading: isLoadingUserData, refetch } = useQuery('user-data', getResourceUserData);

    console.log(getuserdata);

    const [daysRemaining, setDaysRemaining] = React.useState('');

    const calculateDaysRemaining = async () => {

        const currentTimestamp = Math.floor(Date.now() / 1000);
        const licenseTimestamp: any = await GET_LICENSE_DATE();

        const secondsRemaining = JSON.parse(licenseTimestamp) - currentTimestamp;
        const daysRemaining = Math.ceil(secondsRemaining / (60 * 60 * 24));

        setDaysRemaining(daysRemaining.toString());

    };

    React.useEffect(() => {

        const getLic = async () => {
            setLicense(checkTimestamp(await GET_LICENSE_DATE()));
        }

        getLic();
        calculateDaysRemaining();

    }, []);

    const [tvHandlerRemote, setTvHandlerRemote] = React.useState<any>();

    useTVRemoteHandler((remote: any) => {
        setTvHandlerRemote(remote);
    });

    const checkLicense = async () => {

        const token = await GET_AUTH_TOKEN();
        const decoded: any = jwtDecode(`${token}`);

        return checkTimestamp(decoded.user_data.license_end_date.$date.$numberLong);

    }

    React.useEffect(() => {

        if (tvHandlerRemote) {
            if (isPlatformTizen) {
                if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "back") {
                    navigation.goBack();
                }
            }
        }

    }, [tvHandlerRemote]);

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#0A1C34", padding: !isWebBased ? 10 : 30, flexDirection: "row" }]}
            focusOptions={{ verticalWindowAlignment: 'low-edge', focusKey: 'page' }}
        >
            <ScrollView contentContainerStyle={{ alignItems: "center", flex: 1 }}>
                <View style={{ marginBottom: 30 }}>
                    <Image source={logo} style={{ width: !isWebBased ? 160 : 300, height: !isWebBased ? 50 : 100 }} />
                </View>
                {getuserdata &&
                    <View style={{
                        width: "50%",
                        marginBottom: 10,
                        borderRadius: 20
                    }}>
                        {getuserdata?.map((record: any, index: number) => (
                            <Pressable
                                key={index}
                                style={{
                                    padding: !isWebBased ? 10 : 30,
                                    backgroundColor: "#2b3e54",
                                    flexDirection: "row",
                                    marginBottom: 10,
                                    borderRadius: 20
                                }}
                                onPress={async () => {

                                    if (await checkLicense()) {

                                        try {

                                            let url = `${record.playlist.url}/player_api.php?username=${record.playlist.username}&password=${record.playlist.password}&action=get_live_streams`;
                                            let encodedStream = Buffer.from(url, 'utf-8').toString('base64');

                                            let response: any;

                                            if (vpn == "on") {
                                                if (params.usr) {
                                                    response = await fetch(params.usr.proxy_url + encodedStream);
                                                }
                                            } else {
                                                response = await fetch(url);
                                            }

                                            const status = response.status;

                                            if (status === 200) {

                                                await AsyncStorage.setItem("@active_playlist", JSON.stringify({
                                                    url: record.playlist.url, // "http://reb0rn.xyz/player_api.php",
                                                    username: record.playlist.username, // "fons_69157137",
                                                    password: record.playlist.password //"07586648"
                                                }))
                                                navigation.navigate(ROUTES.HOME);

                                            } else {
                                                alert("Your playlist is not available.")
                                            }

                                        } catch (error) {
                                            alert("Your playlist is not available.")
                                        }

                                    } else {
                                        navigation.navigate(ROUTES.CHECK_LICENSE);
                                    }

                                }}
                                focusOptions={{
                                    animator: {
                                        type: "background",
                                        focus: {
                                            backgroundColor: "#128DD3"
                                        }
                                    }
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 70 }}>
                                        {record.playlist.playlistName}
                                    </Text>
                                </View>
                                <View style={{ alignSelf: "center" }}>
                                    {record.status ?
                                        <View style={{ width: 40, height: 40, backgroundColor: "green", borderRadius: 100 }} />
                                        :
                                        <View style={{ width: 40, height: 40, backgroundColor: "red", borderRadius: 100 }} />
                                    }
                                </View>
                            </Pressable>
                        ))}
                    </View>
                }
            </ScrollView>
            <View style={{ flex: 1 }}>
            <View
                        style={{
                            alignSelf: "center",
                            backgroundColor: "#fff",
                            width: !isWebBased ? Dimensions.get("window").width / 3 : Dimensions.get("window").width / 4.5,
                            borderRadius: 50,
                            height: !isWebBased ? Dimensions.get("window").width / 3 : Dimensions.get("window").width / 4.5,
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        
                    </View>
            </View>
        </Screen>
    )
}

export default ScreenMorePlaylist;