import React, { useContext, useRef } from 'react';
import { Button, ScrollView as RNScrollView, useWindowDimensions, Dimensions, ToastAndroid } from 'react-native';
import { Text, View, ScrollView, Image, ImageBackground, useTVRemoteHandler, StyleSheet, Pressable } from '@flexn/create';
import { Api, isPlatformWebos } from '@rnv/renative';
import { ROUTES, Ratio, ThemeContext, isWebBased } from '../../config';
import { useNavigate, useOpenURL } from '../../hooks';
import Screen from '../screen';
import logo from "../../assets/logo.png";
import tvicon from "../../assets/home/tvicon.png";
import moviesicon from "../../assets/home/moviesicon.png";
import seriesicon from "../../assets/home/seriesicon.png";
import playlisticon from "../../assets/home/playlisticon.png";
import settingsicon from "../../assets/settings.png";
import element from "../../assets/home/element.png";
import vpnicon from "../../assets/home/vpnicon.png";
import { isPlatformTizen, isPlatformAndroidtv } from '@rnv/renative';
import { PLAYLIST_STORAGE_PASSWORD, PLAYLIST_STORAGE_URL, PLAYLIST_STORAGE_USERNAME } from '../../storage/PlayListStorage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GET_AUTH_TOKEN, GET_LICENSE_DATE } from '../../storage/AuthStorage';
import { differenceInDays, parse } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { useQuery } from 'react-query';
import axios from 'axios';
import { PROXY_URL } from '../../storage/ProxyUrl';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

const ScreenHome = ({ navigation, route }: { navigation?: any, route: any }) => {

    const [showToast, setShowToast] = React.useState(false);

    const { height } = useWindowDimensions();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate({ navigation });
    const openURL = useOpenURL();

    const [responseStatus, setResponseStatus] = React.useState<number>(0);

    const [activewhitelist, setActiveWhiteList] = React.useState<any>(false);

    const { theme, toggle } = useContext(ThemeContext);

    const [date, setDate] = React.useState(new Date());
    const [time, setTime] = React.useState("");

    const [vpn, setVpn] = React.useState("off"); // Gjendja lokale për vlerën e VPN

    React.useEffect(() => {
        // Funksioni për marrjen e vlerës nga AsyncStorage
        const getVpnStatus = async () => {
            const vpnStatus = await AsyncStorage.getItem("@vpn");
            setVpn(vpnStatus || "off"); // Në rast se nuk ka vlerë, vendos "off" si vlerë parazgjedhje
        };

        // Thirr funksionin për herë të parë
        getVpnStatus();
    }, [date]); // Për të siguruar që useEffect thirret vetëm një herë gjatë inicializimit

    const [proxy, setProxy] = React.useState("");

    const getProxyData = async () => {
        const proxyurl_storage = await AsyncStorage.getItem("@proxy_url");
        if (proxyurl_storage) {
            setProxy(proxyurl_storage);
        }
    };

    const toggleVpn = async () => {
        if (vpn === "on") {
            await AsyncStorage.setItem("@vpn", "off");
            setVpn("off"); // Rifresko gjendjen lokale
            navigation.navigate(ROUTES.HOME);
        } else {
            await AsyncStorage.setItem("@vpn", "on");
            setVpn("on"); // Rifresko gjendjen lokale
            navigation.navigate(ROUTES.HOME);
        }
    };

    const getDeviceData = async () => {
        try {
            const authToken = await GET_AUTH_TOKEN();
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/live-lbgjx/endpoint/getmydata', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            throw new Error('Failed to fetch resource user data');
        }
    }

    const { data: getdevicedata, error, refetch: refetchDeviceData } = useQuery('device-data', getDeviceData);

    const checkURL = async () => {

        try {

            const vpnStatus = await AsyncStorage.getItem("@vpn");
            const proxy_url = await PROXY_URL();

            const playlistStorageUrl = await PLAYLIST_STORAGE_URL();
            const playlistStorageUsername = await PLAYLIST_STORAGE_USERNAME();
            const playlistStoragePassword = await PLAYLIST_STORAGE_PASSWORD();

            let encodedStream = Buffer.from(`${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}`, 'utf-8').toString('base64');
            let decodedStream = `${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}`;

            if (vpnStatus == "on") {

                try {
                    const response = await axios.get(proxy_url + encodedStream, { timeout: 3000 });

                    if (response.status == 200) {
                        setResponseStatus(200);
                    } else {
                        setResponseStatus(400);
                    }
                } catch (error) {
                    setResponseStatus(400);
                }

            } else {

                try {
                    const response = await axios.get(decodedStream, { timeout: 3000 });
                    if (response.status == 200) {
                        setResponseStatus(200);
                    } else {
                        setResponseStatus(400);
                    }
                } catch (error) {
                    setResponseStatus(400);
                }

            }

        } catch (error) {
            setResponseStatus(400);
        }

    };

    React.useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);
        return function cleanup() {
            clearInterval(timer)
        }
    });

    useTVRemoteHandler((remote: any) => {

        const currentScreenName = navigation.getState().routes[navigation.getState().index].name;

        if (remote.eventKeyAction == "down" && remote.eventType == "back") {
            if (currentScreenName == "/") {
                if (isPlatformTizen) {
                    if (confirm("Do you want to exit ?")) {
                        {/* @ts-ignore */ }
                        tizen.application.getCurrentApplication().exit();
                    } else {
                        console.log("User chose to stay in the app.");
                    }
                } else if (isPlatformWebos) {
                    {/* @ts-ignore */ }
                    webOS.platformBack();
                }
            }
        }

    });

    const [daysRemaining, setDaysRemaining] = React.useState(0);

    const calculateDaysRemaining = async () => {
        const token = await GET_AUTH_TOKEN();
        const decoded: any = jwtDecode(`${token}`);

        const licenseDate = new Date(Number(decoded.user_data.license_end_date.$date.$numberLong));

        const remainingDays = differenceInDays(licenseDate, new Date());

        setDaysRemaining(remainingDays);
    };

    const activeplaylist = async () => {
        const val: any = await AsyncStorage.getItem("@active_playlist");
        const playlist = JSON.parse(val);
        setActiveWhiteList(playlist.whitelist)
    }

    React.useEffect(() => {
        activeplaylist();
    }, [activewhitelist])

    React.useEffect(() => {
        getProxyData();
    }, []);

    React.useEffect(() => {
        calculateDaysRemaining();
        checkURL();
    }, []);

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#0A1C34" }]}
        >
            <View style={{ flexDirection: "row", paddingLeft: Ratio(80), paddingRight: Ratio(70), paddingTop: Ratio(40) }}>
                <View style={{ flex: 1 }}>
                    <Image source={logo} style={{ width: Dimensions.get("window").width / 7, height: Dimensions.get("window").width / 30 }} />
                </View>
                <View style={{ flex: 1, alignItems: "flex-end", justifyContent: "center" }}>
                    <View>
                        <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 70, marginTop: 15 }}>{date.toDateString()} {date.toLocaleTimeString()}</Text>
                    </View>
                    {/* {Number(daysRemaining) < 30 ?
                        <>
                            {Number(daysRemaining) <= 0 ?
                                <View>
                                    <Text style={{ color: "red", fontSize: Dimensions.get("window").width / 60, marginTop: !isWebBased ? 0 : 15 }}>
                                        Your license has expired
                                    </Text>
                                </View>
                                :
                                <View>
                                    <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 60, marginTop: !isWebBased ? 0 : 15 }}>
                                        Your trial expire in: {daysRemaining} days
                                    </Text>
                                </View>
                            }
                        </>
                        :
                        <View>
                            <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 60 }}>
                                FREE TRIAL
                            </Text>
                        </View>
                    } */}
                </View>
            </View>
            <View style={{ flexGrow: 1 }} />
            <View style={{ alignItems: "center" }}>
                <View style={{ flexDirection: "row" }}>
                    <Pressable
                        onMouseDown={() => {
                            if (responseStatus === 200) {
                                navigation.navigate(ROUTES.CHANNEL_LIST_TV)
                            } else {
                                if (isPlatformAndroidtv) {
                                    ToastAndroid.showWithGravity(
                                        'Your playlist is incorrect. Please verify your playlist.',
                                        ToastAndroid.SHORT,
                                        ToastAndroid.CENTER,
                                    );
                                } else {
                                    alert("Your playlist is incorrect. Please verify your playlist.");
                                }
                            }
                        }}
                        onPress={() => {
                            if (responseStatus === 200) {
                                navigation.navigate(ROUTES.CHANNEL_LIST_TV)
                            } else {
                                if (isPlatformAndroidtv) {
                                    ToastAndroid.showWithGravity(
                                        'Your playlist is incorrect. Please verify your playlist.',
                                        ToastAndroid.SHORT,
                                        ToastAndroid.CENTER,
                                    );
                                } else {
                                    alert("Your playlist is incorrect. Please verify your playlist.");
                                }
                            }
                        }}
                        style={{
                            width: Dimensions.get("window").width / 3.3,
                            height: Dimensions.get("window").width / 4.5,
                            backgroundColor: "#2b3e54",
                            borderRadius: 20,
                            margin: Ratio(20),
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        focusOptions={{
                            animator: {
                                type: "background",
                                focus: {
                                    backgroundColor: "#128DD3"
                                }
                            }
                        }}
                    >
                        <Image source={tvicon} style={{ width: Dimensions.get("window").width / 10, height: Dimensions.get("window").width / 10, marginTop: !isWebBased ? 20 : 40 }} />
                        <Image source={element} style={{ width: Dimensions.get("window").width / 4, height: Dimensions.get("window").width / 65, marginTop: !isWebBased ? 30 : 50 }} />
                        <Text style={{ fontSize: Dimensions.get("window").width / 35, textTransform: "uppercase", fontWeight: "bold", padding: 30, color: "#b7c6d3" }}>{t('home.livetv_label')}</Text>
                    </Pressable>
                    <Pressable
                        onMouseDown={() => {
                            if (responseStatus === 200) {
                                navigation.navigate(ROUTES.MOVIES)
                            } else {
                                if (isPlatformAndroidtv) {
                                    ToastAndroid.showWithGravity(
                                        'Your playlist is incorrect. Please verify your playlist.',
                                        ToastAndroid.SHORT,
                                        ToastAndroid.CENTER,
                                    );
                                } else {
                                    alert("Your playlist is incorrect. Please verify your playlist.");
                                }
                            }
                        }}
                        onPress={() => {
                            if (responseStatus === 200) {
                                navigation.navigate(ROUTES.MOVIES)
                            } else {
                                if (isPlatformAndroidtv) {
                                    ToastAndroid.showWithGravity(
                                        'Your playlist is incorrect. Please verify your playlist.',
                                        ToastAndroid.SHORT,
                                        ToastAndroid.CENTER,
                                    );
                                } else {
                                    alert("Your playlist is incorrect. Please verify your playlist.");
                                }
                            }
                        }}
                        style={{
                            width: Dimensions.get("window").width / 3.3,
                            height: Dimensions.get("window").width / 4.5,
                            backgroundColor: "#2b3e54",
                            borderRadius: 20,
                            margin: Ratio(20),
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        focusOptions={{
                            animator: {
                                type: "background",
                                focus: {
                                    backgroundColor: "#128DD3"
                                }
                            }
                        }}
                    >
                        <Image source={moviesicon} style={{ width: Dimensions.get("window").width / 10, height: Dimensions.get("window").width / 10, marginTop: !isWebBased ? 20 : 40 }} />
                        <Image source={element} style={{ width: Dimensions.get("window").width / 4, height: Dimensions.get("window").width / 65, marginTop: !isWebBased ? 30 : 50 }} />
                        <Text style={{ fontSize: Dimensions.get("window").width / 35, textTransform: "uppercase", fontWeight: "bold", padding: 30, color: "#b7c6d3" }}>{t('home.movies_label')}</Text>
                    </Pressable>
                    <Pressable
                        onMouseDown={() => {
                            if (responseStatus === 200) {
                                navigation.navigate(ROUTES.SERIES)
                            } else {
                                if (isPlatformAndroidtv) {
                                    ToastAndroid.showWithGravity(
                                        'Your playlist is incorrect. Please verify your playlist.',
                                        ToastAndroid.SHORT,
                                        ToastAndroid.CENTER,
                                    );
                                } else {
                                    alert("Your playlist is incorrect. Please verify your playlist.");
                                }
                            }
                        }}
                        onPress={() => {
                            if (responseStatus === 200) {
                                navigation.navigate(ROUTES.SERIES)
                            } else {
                                if (isPlatformAndroidtv) {
                                    ToastAndroid.showWithGravity(
                                        'Your playlist is incorrect. Please verify your playlist.',
                                        ToastAndroid.SHORT,
                                        ToastAndroid.CENTER,
                                    );
                                } else {
                                    alert("Your playlist is incorrect. Please verify your playlist.");
                                }
                            }
                        }}
                        style={{
                            width: Dimensions.get("window").width / 3.3,
                            height: Dimensions.get("window").width / 4.5,
                            backgroundColor: "#2b3e54",
                            borderRadius: 20,
                            margin: Ratio(20),
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        focusOptions={{
                            animator: {
                                type: "background",
                                focus: {
                                    backgroundColor: "#128DD3"
                                }
                            }
                        }}
                    >
                        <Image source={seriesicon} style={{ width: Dimensions.get("window").width / 10, height: Dimensions.get("window").width / 10, marginTop: !isWebBased ? 20 : 40 }} />
                        <Image source={element} style={{ width: Dimensions.get("window").width / 4, height: Dimensions.get("window").width / 65, marginTop: !isWebBased ? 30 : 50 }} />
                        <Text style={{ fontSize: Dimensions.get("window").width / 35, textTransform: "uppercase", fontWeight: "bold", padding: 30, color: "#b7c6d3" }}>{t('home.series_label')}</Text>
                    </Pressable>
                </View>
            </View>
            <View style={{ flexGrow: 1 }} />
            <View style={{ flexDirection: "row", paddingLeft: Ratio(80), paddingRight: Ratio(70), paddingBottom: Ratio(80) }}>
                <Pressable
                    onPress={async () => {

                        const val: any = await AsyncStorage.getItem("@active_playlist");
                        const playlist = JSON.parse(val);

                        if (playlist.whitelist) {
                            toggleVpn();
                        } else {
                            if (new Date(getdevicedata?.vpn_license_end_date) < new Date()) {
                                navigation.navigate(ROUTES.VPN, { navigation: navigation, subscriptiondata: { license_end_date: getdevicedata?.license_end_date, vpn_license_end_date: getdevicedata?.vpn_license_end_date } });
                            } else {
                                toggleVpn();
                            }
                        }

                    }}
                    onMouseDown={async () => {

                        const val: any = await AsyncStorage.getItem("@active_playlist");
                        const playlist = JSON.parse(val);

                        if (playlist.whitelist) {
                            toggleVpn();
                        } else {
                            if (new Date(getdevicedata?.vpn_license_end_date) < new Date()) {
                                navigation.navigate(ROUTES.VPN, { navigation: navigation, subscriptiondata: { license_end_date: getdevicedata?.license_end_date, vpn_license_end_date: getdevicedata?.vpn_license_end_date } });
                            } else {
                                toggleVpn();
                            }
                        }

                    }}
                    style={{
                        justifyContent: "center",
                        backgroundColor: vpn == "on" ? "#33cc33" : "#808080",
                        marginTop: 30,
                        height: Dimensions.get("window").width / 27,
                        paddingLeft: Ratio(30),
                        paddingRight: Ratio(30),
                        marginRight: 10,
                        borderRadius: 20
                    }}
                    focusOptions={{
                        animator: {
                            type: 'background',
                            focus: {
                                backgroundColor: "#128DD3"
                            }
                        }
                    }}
                >
                    <View style={{ flexDirection: "row" }}>
                        {activewhitelist ?
                            <>
                                <Image resizeMode="contain" source={vpnicon} style={{ width: Dimensions.get("window").width / 60, height: Dimensions.get("window").width / 60, marginRight: Ratio(20), marginTop: 2 }} />
                                <Text style={{ fontSize: Dimensions.get("window").width / 70, color: "#0A1C34", textTransform: "uppercase", fontWeight: "bold" }}>{vpn == "on" ? t('playlists.privacy_mode_label') : t('playlists.privacy_mode_label')}</Text>
                            </>
                            :
                            <>
                                {(new Date(getdevicedata?.vpn_license_end_date) > new Date()) ?
                                    <>
                                        <Image resizeMode="contain" source={vpnicon} style={{ width: Dimensions.get("window").width / 60, height: Dimensions.get("window").width / 60, marginRight: Ratio(20), marginTop: 2 }} />
                                        <Text style={{ fontSize: Dimensions.get("window").width / 70, color: "#0A1C34", textTransform: "uppercase", fontWeight: "bold" }}>{vpn == "on" ? t('playlists.privacy_mode_label') : t('playlists.privacy_mode_label')}</Text>
                                    </>
                                    :
                                    <>
                                        <Image resizeMode="contain" source={require("../../assets/padlock.png")} style={{ width: Dimensions.get("window").width / 60, height: Dimensions.get("window").width / 60, marginRight: Ratio(20), marginTop: 2 }} />
                                        <Text style={{ fontSize: Dimensions.get("window").width / 70, color: "#0A1C34", textTransform: "uppercase", fontWeight: "bold" }}>{vpn == "on" ? t('playlists.privacy_mode_label') : t('playlists.privacy_mode_label')}</Text>
                                    </>
                                }
                            </>
                        }
                    </View>
                </Pressable>
                <View style={{ flexGrow: 1 }} />
                <Pressable
                    onMouseDown={() => navigation.navigate(ROUTES.SEARCH)}
                    onPress={() => navigation.navigate(ROUTES.SEARCH)}
                    style={{
                        backgroundColor: "#2b3e54",
                        justifyContent: "center",
                        width: Dimensions.get("window").width / 27,
                        height: Dimensions.get("window").width / 27,
                        marginTop: 30,
                        marginRight: 10,
                        borderRadius: 20
                    }}
                    focusOptions={{
                        animator: {
                            type: 'background',
                            focus: {
                                backgroundColor: "#128DD3"
                            }
                        }
                    }}
                >
                    <View style={{ flexDirection: "row", alignSelf: "center" }}>
                        <Image resizeMode="contain" source={{ uri: "https://samsung-bigscreen-build.pages.dev/static/media/search.png" }} style={{ width: Dimensions.get("window").width / 60, height: Dimensions.get("window").width / 60 }} />
                    </View>
                </Pressable>
                <Pressable
                    onMouseDown={() => navigation.navigate(ROUTES.SETTINGS)}
                    onPress={() => navigation.navigate(ROUTES.SETTINGS)}
                    style={{
                        backgroundColor: "#2b3e54",
                        justifyContent: "center",
                        width: Dimensions.get("window").width / 27,
                        height: Dimensions.get("window").width / 27,
                        marginTop: 30,
                        marginRight: 10,
                        borderRadius: 20
                    }}
                    focusOptions={{
                        animator: {
                            type: 'background',
                            focus: {
                                backgroundColor: "#128DD3"
                            }
                        }
                    }}
                >
                    <View style={{ flexDirection: "row", alignSelf: "center" }}>
                        <Image resizeMode="contain" source={{ uri: "https://samsung-bigscreen-build.pages.dev/static/media/settings.png" }} style={{ width: Dimensions.get("window").width / 60, height: Dimensions.get("window").width / 60 }} />
                    </View>
                </Pressable>
                <Pressable
                    onMouseDown={() => navigation.navigate(ROUTES.PLAYLIST)}
                    onPress={() => navigation.navigate(ROUTES.PLAYLIST)}
                    style={{
                        backgroundColor: "#2b3e54",
                        height: Dimensions.get("window").width / 27,
                        justifyContent: "center",
                        paddingLeft: Ratio(30),
                        paddingRight: Ratio(150),
                        marginTop: !isWebBased ? 10 : 30,
                        marginRight: 10,
                        borderRadius: 20
                    }}
                    focusOptions={{
                        animator: {
                            type: 'background',
                            focus: {
                                backgroundColor: "#128DD3"
                            }
                        }
                    }}
                >
                    <View style={{ flexDirection: "row" }}>
                        <Image resizeMode="contain" source={playlisticon} style={{ width: Dimensions.get("window").width / 60, height: Dimensions.get("window").width / 60, marginRight: Ratio(20), marginTop: Ratio(3) }} />
                        <Text style={{ fontSize: Dimensions.get("window").width / 70, color: "#ddd", textTransform: "uppercase", fontWeight: "bold" }}>{t('playlists.playlist')}</Text>
                    </View>
                </Pressable>
            </View>
        </Screen>
    );

};

const styles = StyleSheet.create({
    recyclerItem: {
        borderRadius: isPlatformAndroidtv ? 20 : 50,
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.33)",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.6,
        justifyContent: 'center'
    },
    recyclerItemSettings: {
        width: windowWidth / 8,
        height: windowWidth / 12,
        backgroundColor: "rgba(255, 255, 255, 0.33)",
        borderRadius: isPlatformAndroidtv ? 15 : 30,
        alignItems: "center",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.6,
        justifyContent: 'center'
    },
    txtButton: {
        color: "#fff",
        fontSize: windowWidth / 50,
        fontWeight: "bold",
        marginTop: isPlatformAndroidtv ? 20 : 60,
        textTransform: "uppercase"
    },
    txtButtonSettings: {
        color: "#fff",
        fontSize: windowWidth / 80,
        fontWeight: "bold",
        marginTop: isPlatformAndroidtv ? 15 : 5,
        marginLeft: 20,
        textTransform: "uppercase"
    },
    toast: {
        position: 'absolute',
        bottom: 30, // Distance from the bottom of the screen
        left: 20,
        right: 20,
        backgroundColor: '#128DD3', // Dark grey background
        padding: 20,
        borderRadius: 40,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
    },
    text: {
        color: '#fff',
        textAlign: 'center',
        fontSize: Dimensions.get("window").width / 80
    },
});

export default ScreenHome;