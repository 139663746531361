import React, { useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions, I18nManager, BackHandler } from 'react-native';
import { Text, View, ScrollView, Image, ImageBackground, useTVRemoteHandler, StyleSheet, Pressable } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext, isWebBased } from '../../config';
import vpn from "../../assets/vpn.png";
import playlisticon from "../../assets/playlist.png";
import { isPlatformTizen, isPlatformAndroidtv, isEngineRnWeb } from '@rnv/renative';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { PLAYLIST_STORAGE_PASSWORD, PLAYLIST_STORAGE_URL, PLAYLIST_STORAGE_USERNAME } from '../../storage/PlayListStorage';
import { useQuery } from 'react-query';
import logo from "../../assets/logo.png";
import { GET_AUTH_TOKEN } from '../../storage/AuthStorage';
import { VPN_STORAGE } from '../../storage/VPNStorage';
import { Buffer } from 'buffer';
import { PROXY_URL } from '../../storage/ProxyUrl';
import { useTranslation } from 'react-i18next';

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

const ScreenSettings = ({ navigation }: { navigation?: any }) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    const { t, i18n } = useTranslation();

    const [date, setDate] = React.useState(new Date());
    const [playlistInfo, setPlaylistInfo] = React.useState<any>({});

    const getPlaylistInfo = async () => {
        const val: any = await AsyncStorage.getItem("@active_playlist");
        const playlist = JSON.parse(val);
        console.log(playlist);

        if (val !== null) {
            setPlaylistInfo(playlist);
        }
    }

    const getResourceUserData = async () => {

        try {

            const authToken = await GET_AUTH_TOKEN();
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/live-lbgjx/endpoint/getmydata', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const dat = await response.json();

            return dat;

        } catch (error) {
            throw new Error('Failed to fetch resource user data');
        }

    };

    const { data: getuserdata, error: erruserdata, isLoading: isLoadingUserData, refetch } = useQuery('user-data-settings', getResourceUserData);

    const [proxy, setProxy] = React.useState("");

    const getProxyData = async () => {
        const proxyurl_storage = await AsyncStorage.getItem("@proxy_url");
        if (proxyurl_storage) {
            setProxy(proxyurl_storage);
        }
    };

    // Fetcher function
    const getResource = async () => {

        try {

            const vpnstorage = await VPN_STORAGE();
            const proxy_url = await PROXY_URL();

            const playlistStorageUrl = await PLAYLIST_STORAGE_URL();
            const playlistStorageUsername = await PLAYLIST_STORAGE_USERNAME();
            const playlistStoragePassword = await PLAYLIST_STORAGE_PASSWORD();

            let encodedStream = Buffer.from(`${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}`, 'utf-8').toString('base64');
            let decodedStream = `${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}`;
            const response = await fetch(vpnstorage ? vpnstorage == "on" ? proxy_url + encodedStream : decodedStream : decodedStream);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch live streams');
        }

    };

    // Using the hook
    const { data: userinfo, error, isLoading } = useQuery('user-info', getResource);

    React.useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);
        return function cleanup() {
            clearInterval(timer)
        }
    });

    React.useEffect(() => {
        getPlaylistInfo();
    }, [userinfo])

    const [selectedLanguage, setSelectedLanguage] = React.useState('en');

    const changeLanguage = (data: any) => {


        setTimeout(async () => {

            setSelectedLanguage(data);

            i18n.changeLanguage(data);

            if (data == "ar") {
                document.body.dir = i18n.dir();
            } else {
                document.body.dir = i18n.dir();
            }

            await AsyncStorage.setItem("@i18n", data);
            navigation.navigate(ROUTES.PLAYLIST);

        }, 1000);

    }

    const GET_LANGUAGE = async () => {
        const value = await AsyncStorage.getItem("@i18n");
        if (value !== null) {
            setSelectedLanguage(value);
        } else {
            setSelectedLanguage("en");
        }
    }

    React.useEffect(() => {
        GET_LANGUAGE();
    }, [selectedLanguage])

    const [tvHandlerRemote, setTvHandlerRemote] = React.useState<any>();

    useTVRemoteHandler((remote: any) => {
        setTvHandlerRemote(remote);
    });

    React.useEffect(() => {

        if (tvHandlerRemote) {
            if (isEngineRnWeb) {
                if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "back") {
                    navigation.goBack();
                }
            }
        }

    }, [tvHandlerRemote]);

    React.useEffect(() => {
        getProxyData();
    }, [])

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#0A1C34", padding: Ratio(50) }]}
            focusOptions={{ verticalWindowAlignment: 'low-edge', focusKey: 'page' }}
        >
            <View style={{ flexDirection: "row", marginTop: Ratio(-20) }}>
                <View style={{ flex: 1 }}>
                    <Image source={logo} style={{ width: !isWebBased ? 160 : 300, height: !isWebBased ? 50 : 100 }} />
                </View>
                <View style={{ flex: 1, alignItems: "flex-end", justifyContent: "center" }}>
                    <View>
                        <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 80, marginTop: -10 }}>{date.toDateString()} {date.toLocaleTimeString()}</Text>
                    </View>
                </View>
            </View>
            <View style={{ padding: 0.2, backgroundColor: "#2b3e54", marginTop: Ratio(30) }} />
            <View>
                <View style={{ marginTop: Ratio(40) }}>
                    <Text style={{ fontSize: Ratio(30), color: "#fff" }}>{t('settings.change_language')}</Text>
                </View>
                <View style={{ flexDirection: "row", marginTop: Ratio(30) }}>
                    <Pressable
                        style={{ marginRight: Ratio(30) }}
                        onPress={() => changeLanguage("en")}
                    >
                        <Image
                            source={{ uri: 'https://flagcdn.com/w320/gb.png' }}
                            style={{ width: Ratio(80), height: Ratio(80), borderRadius: Ratio(100) }}
                        />
                    </Pressable>
                    <Pressable
                        style={{ marginRight: Ratio(30) }}
                        onPress={() => changeLanguage("it")}
                    >
                        <Image
                            source={{ uri: 'https://flagcdn.com/w320/it.png' }}
                            style={{ width: Ratio(80), height: Ratio(80), borderRadius: Ratio(100) }}
                        />
                    </Pressable>
                    <Pressable
                        style={{ marginRight: Ratio(30) }}
                        onPress={() => changeLanguage("es")}
                    >
                        <Image
                            source={{ uri: 'https://flagcdn.com/w320/es.png' }}
                            style={{ width: Ratio(80), height: Ratio(80), borderRadius: Ratio(100) }}
                        />
                    </Pressable>
                    <Pressable
                        style={{ marginRight: Ratio(30) }}
                        onPress={() => changeLanguage("fr")}
                    >
                        <Image
                            source={{ uri: 'https://flagcdn.com/w320/fr.png' }}
                            style={{ width: Ratio(80), height: Ratio(80), borderRadius: Ratio(100) }}
                        />
                    </Pressable>
                    <Pressable
                        style={{ marginRight: Ratio(30) }}
                        onPress={() => changeLanguage("ar")}
                    >
                        <Image
                            source={{ uri: 'https://flagcdn.com/w320/sa.png' }}
                            style={{ width: Ratio(80), height: Ratio(80), borderRadius: Ratio(100) }}
                        />
                    </Pressable>
                    <Pressable
                        style={{ marginRight: Ratio(30) }}
                        onPress={() => changeLanguage("zh")}
                    >
                        <Image
                            source={{ uri: 'https://flagcdn.com/w320/cn.png' }}
                            style={{ width: Ratio(80), height: Ratio(80), borderRadius: Ratio(100) }}
                        />
                    </Pressable>
                    <Pressable
                        style={{ marginRight: Ratio(30) }}
                        onPress={() => changeLanguage("de")}
                    >
                        <Image
                            source={{ uri: 'https://flagcdn.com/w320/de.png' }}
                            style={{ width: Ratio(80), height: Ratio(80), borderRadius: Ratio(100) }}
                        />
                    </Pressable>
                    <Pressable
                        style={{ marginRight: Ratio(30) }}
                        onPress={() => changeLanguage("el")}
                    >
                        <Image
                            source={{ uri: 'https://flagcdn.com/w320/gr.png' }}
                            style={{ width: Ratio(80), height: Ratio(80), borderRadius: Ratio(100) }}
                        />
                    </Pressable>
                </View>
            </View>
            <View style={{ padding: 0.2, backgroundColor: "#2b3e54", marginTop: Ratio(30) }} />
            <View style={{ marginTop: Ratio(30) }}>
                <View style={{ flexDirection: "row" }}>
                    <View>
                        {playlistInfo &&
                            <>
                                <Text style={{ color: "#ddd", fontSize: Ratio(30) }}>{t('settings.playlistinfo_label')}</Text>
                                <View style={{ marginTop: Ratio(25), marginBottom: 10 }}>
                                    {playlistInfo.hide_playlist_info ?
                                        <Text style={{ color: "#ddd", fontSize: Ratio(30) }}>
                                            {playlistInfo.playlistName}
                                        </Text>
                                        :
                                        <View>
                                            <Text style={{ color: "#ddd", fontSize: Ratio(30) }}>
                                                {playlistInfo.playlistName}
                                            </Text>
                                            <Text style={{ color: "#ddd", fontSize: Ratio(30) }}>
                                                {playlistInfo.url}
                                            </Text>
                                            <Text style={{ color: "#ddd", fontSize: Ratio(30) }}>
                                                {playlistInfo.username}
                                            </Text>
                                            <Text style={{ color: "#ddd", fontSize: Ratio(30) }}>
                                                {playlistInfo.password}
                                            </Text>
                                        </View>
                                    }
                                </View>
                            </>
                        }
                    </View>
                    <View style={{ marginLeft: Ratio(60) }}>
                        <Text style={{ fontSize: Ratio(30), color: "#fff" }}>{t('settings.user_info_label')}</Text>
                        {userinfo &&
                            <View style={{ marginTop: Ratio(15) }}>
                                {userinfo?.user_info.exp_date !== null &&
                                    <View>
                                        <Text style={{ color: "#ddd", fontSize: Ratio(22), marginTop: 8 }}>Expire</Text>
                                        <View style={{ marginTop: 2 }}>
                                            <Text style={{ color: "#ddd", fontSize: Ratio(30) }}>{`${new Date(Number(userinfo?.user_info.exp_date) * 1000).toLocaleString()}`}</Text>
                                        </View>
                                    </View>
                                }
                                <View>
                                    <Text style={{ color: "#ddd", fontSize: Ratio(22), marginTop: 8 }}>Status</Text>
                                    <View style={{ backgroundColor: "green", width: 130, height: 35, alignItems: "center", justifyContent: "center", borderRadius: 3, marginTop: 8 }}>
                                        <Text style={{ color: "#ddd", fontSize: Ratio(22) }}>{userinfo?.user_info.status}</Text>
                                    </View>
                                </View>
                            </View>
                        }
                    </View>
                </View>
            </View>
        </Screen>
    )
}

const styles = StyleSheet.create({
    recyclerItem: {
        width: windowWidth / 4,
        height: windowWidth / 4,
        borderRadius: isPlatformAndroidtv ? 20 : 50,
        alignItems: "center",
        backgroundColor: "#222",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.6,
        justifyContent: 'center'
    },
    recyclerItemSettings: {
        width: windowWidth / 8,
        height: windowWidth / 12,
        backgroundColor: "#222",
        borderRadius: isPlatformAndroidtv ? 15 : 30,
        alignItems: "center",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.6,
        justifyContent: 'center'
    },
    txtButton: {
        color: "#fff",
        fontSize: windowWidth / 50,
        fontWeight: "bold",
        marginTop: isPlatformAndroidtv ? 20 : 60,
        textTransform: "uppercase"
    },
    txtButtonSettings: {
        color: "#fff",
        fontSize: windowWidth / 80,
        fontWeight: "bold",
        marginTop: isPlatformAndroidtv ? 15 : 25,
        textTransform: "uppercase"
    }
});

export default ScreenSettings;