import React, { useCallback, useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions } from 'react-native';
import { Text, View, ScrollView, Image, useTVRemoteHandler, CreateListRenderItemInfo, StyleSheet, Pressable, FlashList } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext, isWebBased } from '../../config';
import logo from "../../assets/logo.png";
import { isPlatformTizen, isPlatformAndroidtv, isEngineRnWeb } from '@rnv/renative';
import { useQuery } from 'react-query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moviesData from "../../../vodjsonfile.json";
import channelsData from "../../../channelsjsonfile.json";
import seriesData from "../../../seriesjsonfile.json";
import noimage from "../../assets/noimage.png";

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

const ScreenSearch = ({ navigation }: { navigation?: any }) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    const [channels, setChannels] = React.useState<any>([]);
    const [movies, setMovies] = React.useState<any>([])
    const [series, setSeries] = React.useState<any>([]);

    const [date, setDate] = React.useState(new Date());
    const [time, setTime] = React.useState("");

    React.useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);
        return function cleanup() {
            clearInterval(timer)
        }
    });

    const [input, setInput] = React.useState('');

    const handlePress = (value: any) => {
        setInput(input + value);
    };

    const renderInput = () => {
        if (input === '') {
            {/* @ts-ignore */ }
            return <Text style={styles.placeholderText}>Search for channels, movies or serials ...</Text>;
        } else {
            {/* @ts-ignore */ }
            return <Text style={styles.inputText}>{input}</Text>;
        }
    };

    const handleDelete = () => {
        setInput(input.slice(0, -1));
    };

    const [tvHandlerRemote, setTvHandlerRemote] = React.useState<any>();

    useTVRemoteHandler((remote: any) => {
        setTvHandlerRemote(remote);
    });

    React.useEffect(() => {

        if (tvHandlerRemote) {
            if (isEngineRnWeb) {
                if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "back") {
                    if (input !== "") {
                        handleDelete();
                    } else {
                        navigation.goBack();
                    }
                }
            }
        }

    }, [tvHandlerRemote]);

    const searchData = useCallback((array: any) => {
        return array.filter((obj: any) => obj.name.includes(input));
    }, [input]);

    const keyboardData = [
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
        ['z', 'x', 'c', 'v', 'b', 'n', 'm'],
        ['!', '@', '#', '$', '%', '&', '*', '(', ')', ' ']
    ];

    const rowRendererChannels = ({ item, focusRepeatContext }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable
                onPress={() => navigation.navigate(ROUTES.LIVETV, { channel: item })}
                style={styles.recyclerItemChannel}
                focusRepeatContext={focusRepeatContext}
                focusOptions={{
                    animator: {
                        type: "background",
                        focus: {
                            backgroundColor: "#128DD3"
                        }
                    }
                }}
            >
                <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
                    <Image resizeMode="contain" style={styles.channelLogo} source={item.stream_icon == "" ? noimage : { uri: `${item['stream_icon']}` }} />
                    <Text style={{ fontSize: Ratio(20), color: "#ddd" }}>{item.name !== null ? item.name.substring(0, 10) + (item.name.length > 10 ? " ..." : "") : ""}</Text>
                </View>
            </Pressable>
        );
    };

    const rowRenderer = ({ item, focusRepeatContext }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable
                onPress={() => navigation.navigate(ROUTES.MOVIE_DETAILS, { item: item })}
                style={styles.recyclerItem}
                focusRepeatContext={focusRepeatContext}
                focusOptions={{
                    animator: {
                        type: "background",
                        focus: {
                            backgroundColor: "#128DD3"
                        }
                    }
                }}
            >
                <View style={{
                    width: Ratio(143),
                    height: Ratio(193),
                    marginTop: Ratio(3.5),
                    borderRadius: 20,
                    justifyContent: 'center', // Kjo rresht bën që përmbajtja brenda View të qendrojë vertikalisht
                    alignItems: 'center', // Kjo rresht bën që përmbajtja brenda View të qendrojë horizontalisht
                    overflow: 'hidden', // Siguron që imazhi të mos dalë jashtë kufijve të View
                }}>
                    <Image resizeMode={'cover'} source={{ uri: item.stream_icon }} style={{
                        position: 'absolute', // Bën që imazhi të vendoset si sfond
                        width: '100%',
                        height: '100%',
                    }} />
                    <Text style={{ fontSize: Ratio(20), color: "#ddd", backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: 10, padding: 5, position: "absolute" }}>{item.name !== null ? item.name.substring(0, 10) + (item.name.length > 10 ? " ..." : "") : ""}</Text>
                </View>
            </Pressable>
        );
    };

    const rowRendererSeries = ({ item, focusRepeatContext }: CreateListRenderItemInfo<any>) => {
        return (
            <Pressable
                onPress={() => navigation.navigate(ROUTES.MOVIE_DETAILS, { item: item })}
                style={styles.recyclerItem}
                focusRepeatContext={focusRepeatContext}
                focusOptions={{
                    animator: {
                        type: "background",
                        focus: {
                            backgroundColor: "#128DD3"
                        }
                    }
                }}
            >
                <View style={{
                    width: Ratio(143),
                    height: Ratio(193),
                    marginTop: Ratio(3.5),
                    borderRadius: 20,
                    justifyContent: 'center', // Kjo rresht bën që përmbajtja brenda View të qendrojë vertikalisht
                    alignItems: 'center', // Kjo rresht bën që përmbajtja brenda View të qendrojë horizontalisht
                    overflow: 'hidden', // Siguron që imazhi të mos dalë jashtë kufijve të View
                }}>
                    <Image resizeMode={'cover'} source={{ uri: item.cover }} style={{
                        position: 'absolute', // Bën që imazhi të vendoset si sfond
                        width: '100%',
                        height: '100%',
                    }} />
                    <Text style={{ fontSize: Ratio(20), color: "#ddd", backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: 10, padding: 5, position: "absolute" }}>{item.name !== null ? item.name.substring(0, 10) + (item.name.length > 10 ? " ..." : "") : ""}</Text>
                </View>
            </Pressable>
        );
    };

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#0A1C34" }]}
            focusOptions={{ verticalWindowAlignment: 'low-edge', focusKey: 'page' }}
        >
            <View>
                <View style={{ height: Dimensions.get("window").width / 20, flexDirection: "row", marginLeft: Ratio(50), marginBottom: 20 }}>
                    <View style={{ marginTop: 30, marginLeft: isPlatformAndroidtv ? 0 : 40 }}>
                        <Image source={logo} style={{ width: isPlatformAndroidtv ? 160 : 300, height: isPlatformAndroidtv ? 50 : 100 }} />
                    </View>
                </View>
            </View>
            <View style={{ flexDirection: "row" }}>
                <View style={styles.keyboardContainer}>
                    <View style={{ marginBottom: Ratio(50) }}>
                        <Text style={styles.inputText}>{renderInput()}</Text>
                    </View>
                    {keyboardData.map((row, rowIndex) => (
                        <View key={rowIndex} style={styles.row}>
                            {row.map((key, index) => (
                                <Pressable
                                    key={index}
                                    style={[styles.button, key == ' ' && {
                                        width: Ratio(130),
                                        height: Ratio(70),
                                    }]}
                                    onPress={() => handlePress(key)}
                                    focusOptions={{
                                        animator: {
                                            type: 'background',
                                            focus: {
                                                backgroundColor: '#128DD3'
                                            },
                                        }
                                    }}
                                >
                                    <Text style={styles.buttonText}>{key == ' ' ? "Space" : key}</Text>
                                </Pressable>
                            ))}
                        </View>
                    ))}
                    {input !== '' && (
                        <Pressable
                            style={styles.deleteButton}
                            onPress={handleDelete}
                            focusOptions={{
                                animator: {
                                    type: 'background',
                                    focus: {
                                        backgroundColor: '#128DD3'
                                    },
                                }
                            }}
                        >
                            {/* @ts-ignore */}
                            <Text style={styles.buttonText}>Undo</Text>
                        </Pressable>
                    )}
                </View>
                <View style={{ flex: 1 }}>
                    <ScrollView>
                        <View style={{ marginTop: 40 }}>
                            <Text style={{ color: "#ddd", fontSize: Ratio(40), marginBottom: 20 }}>Channels</Text>
                            {channelsData.filter((obj: any) => obj.name.includes(input)).slice(0, 20).length > 0 ?
                                <FlashList
                                    data={searchData(channelsData).slice(0, 15)}
                                    renderItem={rowRendererChannels}
                                    horizontal
                                    type="row"
                                    estimatedItemSize={Dimensions.get("window").width / 5}
                                />
                                :
                                <View>
                                    <Text style={{ color: "gray", fontSize: Ratio(30), marginBottom: 20 }}>No channels found</Text>
                                </View>
                            }
                        </View>
                        <View style={{ marginTop: 40 }}>
                            <Text style={{ color: "#ddd", fontSize: Ratio(40), marginBottom: 20 }}>Movies</Text>
                            {moviesData.filter((obj: any) => obj.name.includes(input)).slice(0, 20).length > 0 ?
                                <FlashList
                                    data={searchData(moviesData)}
                                    renderItem={rowRenderer}
                                    horizontal
                                    type="row"
                                    estimatedItemSize={Dimensions.get("window").width / 5}
                                />
                                :
                                <View>
                                    <Text style={{ color: "gray", fontSize: Ratio(30), marginBottom: 20 }}>No movies found</Text>
                                </View>
                            }
                        </View>
                        <View style={{ marginTop: 40 }}>
                            <Text style={{ color: "#ddd", fontSize: Ratio(40), marginBottom: 20 }}>Series</Text>
                            {seriesData.filter((obj: any) => obj.name.includes(input)).slice(0, 20).length > 0 ?
                                <FlashList
                                    data={searchData(seriesData)}
                                    renderItem={rowRendererSeries}
                                    horizontal
                                    type="row"
                                    estimatedItemSize={Dimensions.get("window").width / 5}
                                />
                                :
                                <View>
                                    <Text style={{ color: "gray", fontSize: Ratio(30), marginBottom: 20 }}>No series found</Text>
                                </View>
                            }
                        </View>
                    </ScrollView>
                </View>
            </View>
        </Screen>
    )
}

const styles = StyleSheet.create({
    inputContainer: {
        padding: Ratio(10),
        alignItems: 'center',
    },
    inputText: {
        paddingLeft: isPlatformAndroidtv ? 20 : 25,
        backgroundColor: "#2b3e54",
        width: Ratio(800),
        alignSelf: "center",
        padding: isPlatformAndroidtv ? 10 : 20,
        borderRadius: isPlatformAndroidtv ? 20 : 60,
        fontSize: isPlatformAndroidtv ? 15 : 40,
        color: "#b6c6d2",
    },
    keyboardContainer: {
        marginTop: Ratio(30),
        justifyContent: "center",
        flex: 1
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        margin: Ratio(5),
        padding: isPlatformAndroidtv ? 1 : 20,
        justifyContent: "center",
        width: Ratio(70),
        height: Ratio(70),
        backgroundColor: '#2b3e54',
        borderRadius: isPlatformAndroidtv ? 5 : 10,
    },
    buttonText: {
        fontSize: Ratio(20),
        textAlign: "center",
        color: "#fff",
    },
    placeholderText: {
        paddingLeft: 25,
        fontSize: isPlatformAndroidtv ? 15 : 40,
        color: '#ddd',
        opacity: 0.5
    },
    deleteButton: {
        alignSelf: "center",
        borderRadius: 20,
        marginTop: 20,
        padding: 10,
        backgroundColor: "#2b3e54",
        width: Ratio(500)
    },
    recyclerItemChannel: {
        width: Ratio(200),
        height: Ratio(200),
        backgroundColor: "#2b3e54",
        borderRadius: 20,
        marginRight: !isWebBased ? 10 : 30
    },
    recyclerItem: {
        width: Ratio(150),
        height: Ratio(200),
        backgroundColor: "#2b3e54",
        alignItems: "center",
        borderRadius: 20,
        marginRight: !isWebBased ? 10 : 30
    },
    image: {
        width: Ratio(150),
        height: Ratio(200),
    },
    channelLogo: {
        width: Ratio(120),
        height: Ratio(120),
    },
});

export default ScreenSearch;


