import AsyncStorage from '@react-native-async-storage/async-storage';

export const PROXY_URL = async () => {
	try {
		const value = await AsyncStorage.getItem('@proxy_url');
		if (value !== null) {
			return value;
		}
	} catch (e) {
		// error reading value
	}
}