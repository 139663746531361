import React, { useCallback, useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions, ActivityIndicator } from 'react-native';
import { Text, View, CreateListRenderItemInfo, Image, ImageBackground, FlashList, StyleSheet, Pressable, ScrollView, useTVRemoteHandler } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext, getWidth } from '../../config';
import background from "../../assets/light_background.jpg"
import noimage from "../../assets/noimage.png";
import moviesicon from "../../assets/movies.png";
import seriesicon from "../../assets/series.png";
import searchicon from "../../assets/search.png";
import settingsicon from "../../assets/settings.png";
import playlisticon from "../../assets/playlist.png";
import { useQuery } from 'react-query';
import logo from "../../assets/logo.png";
import { isPlatformTizen, isPlatformAndroidtv, isEngineRnWeb } from '@rnv/renative';
import axios from 'axios';
import { PLAYLIST_STORAGE_PASSWORD, PLAYLIST_STORAGE_URL, PLAYLIST_STORAGE_USERNAME } from '../../storage/PlayListStorage';
import { Buffer } from 'buffer';
import { VPN_STORAGE } from '../../storage/VPNStorage';
import { PROXY_URL } from '../../storage/ProxyUrl';

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

const ScreenChannelsMenu = ({ navigation }: any) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    const flashListRef: any = useRef(null);

    const [category, setCategory] = React.useState("");

    // Fetcher function for categories
    const getResourceCategories = async () => {
        try {

            const vpnstorage = await VPN_STORAGE();
            const proxy_url = await PROXY_URL();

            const playlistStorageUrl = await PLAYLIST_STORAGE_URL();
            const playlistStorageUsername = await PLAYLIST_STORAGE_USERNAME();
            const playlistStoragePassword = await PLAYLIST_STORAGE_PASSWORD();

            let encodedStream = Buffer.from(`${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}&action=get_live_categories`, 'utf-8').toString('base64');
            let decodedStream = `${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}&action=get_live_categories`;

            const response = await fetch(vpnstorage ? vpnstorage == "on" ? proxy_url + encodedStream : decodedStream : decodedStream);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch live categories');
        }
    };

    // Using the hook for categories
    const { data: categories, error: errCategories, isLoading: isLoadingCategories } = useQuery('live-categories', getResourceCategories, {
        cacheTime: 6 * 60 * 60 * 1000,
    });

    // Fetcher function for channels in a specific category
    const getResourceChannels = async (category: any) => {
        try {

            const vpnstorage = await VPN_STORAGE();
            const proxy_url = await PROXY_URL();

            const playlistStorageUrl = await PLAYLIST_STORAGE_URL();
            const playlistStorageUsername = await PLAYLIST_STORAGE_USERNAME();
            const playlistStoragePassword = await PLAYLIST_STORAGE_PASSWORD();

            let encodedStream = Buffer.from(`${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}&action=get_live_streams&category_id=${category}`, 'utf-8').toString('base64');
            let decodedStream = `${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}&action=get_live_streams&category_id=${category}`;

            const response = await fetch(vpnstorage ? vpnstorage == "on" ? proxy_url + encodedStream : decodedStream : decodedStream);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch live streams in the selected category');
        }
    };

    // Using the hook for channels in a specific category
    const { data: channels, error: errChannels, isLoading: isLoadingChannels, isFetching: isFetchingChannels } = useQuery(['live-channels-categories', category], () => getResourceChannels(category), {
        cacheTime: 6 * 60 * 60 * 1000,
    });

    const [date, setDate] = React.useState(new Date());
    const [time, setTime] = React.useState("");

    React.useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);
        return function cleanup() {
            clearInterval(timer)
        }
    });

    const onFocusCategory = (item: any) => {
        setCategory(item);
    }

    const [tvHandlerRemote, setTvHandlerRemote] = React.useState<any>();

    useTVRemoteHandler((remote: any) => {
        setTvHandlerRemote(remote);
    });


    const RenderCategory = ({ item, focusRepeatContext, index }: any) => {
        return (
            <Pressable
                style={[
                    styles.recyclerCategoriesItem
                ]}
                focusRepeatContext={focusRepeatContext}
                onFocus={() => {
                    onFocusCategory(item.category_id);
                }}
                focusOptions={{
                    focusKey: index,
                    // hasPreferredFocus: Number(index) === 20 ? true : false,
                    animator: {
                        type: 'background',
                        focus: {
                            backgroundColor: '#128DD3'
                        }
                    }
                }}
            >
                <View style={{ flexDirection: "row", marginHorizontal: Ratio(40) }}>
                    <Text style={{ color: "#ddd", fontSize: Ratio(30), textTransform: "capitalize" }}>
                        {item.category_name.length > 22 ? `${item.category_name.substring(0, 22)}...` : item.category_name}
                    </Text>
                </View>
            </Pressable>
        );
    };

    const RenderChannel = ({ item, focusRepeatContext, index }: any) => {
        return (
            <Pressable
                style={[
                    styles.recyclerChannelItem
                ]}
                focusRepeatContext={focusRepeatContext}
                onPress={() => {
                    navigation.navigate(ROUTES.LIVETV, { channel: item })
                }}
                // @ts-ignore
                focusOptions={{
                    animator: {
                        type: 'background',
                        focus: {
                            backgroundColor: '#128DD3'
                        }
                    }
                }}
            >
                <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                    <View style={{ backgroundColor: "#2b3e54", borderRadius: Ratio(50), width: Ratio(60), height: Ratio(60), alignItems: "center", justifyContent: "center", marginHorizontal: Ratio(20) }}>
                        <Image resizeMode="contain" style={styles.channelLogo} source={item.stream_icon == "" ? noimage : { uri: `${item['stream_icon']}` }} />
                    </View>
                    <View style={{ flexDirection: "row", alignSelf: "center" }}>
                        <Text style={{ color: "#ddd", fontSize: Ratio(30), textTransform: "capitalize", marginHorizontal: Ratio(10) }}>{item.num}</Text>
                        <Text style={{ color: "#ddd", fontSize: Ratio(30), textTransform: "capitalize" }}>{item.name !== null ? item.name.substring(0, 25) + (item.name.length > 25 ? " ..." : "") : ""}</Text>
                    </View>
                </View>
            </Pressable>
        );
    };

    React.useEffect(() => {

        if (tvHandlerRemote) {
            if (isEngineRnWeb) {
                if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "back") {
                    navigation.goBack();
                }
            }
        }

    }, [tvHandlerRemote]);

    return (
        <Screen style={[styles.screen]}>
            <View style={{ flexDirection: "row", flex: 1 }}>
                {categories &&
                    <FlashList
                        ref={flashListRef}
                        data={categories}
                        renderItem={RenderCategory}
                        type="row"
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={{ paddingTop: Ratio(40) }}
                        style={{ flex: 0.3 }}
                        horizontal={false}
                        estimatedItemSize={Ratio(300)}
                    />
                }
                {isFetchingChannels ?
                    <View style={{ alignSelf: "flex-start" }}>
                        {/* <ActivityIndicator size={40} color="#333" /> */}
                    </View>
                    :
                    <FlashList
                        data={isFetchingChannels && channels.length === 0 ? new Array(15).fill(null) : category !== "" && channels}
                        contentContainerStyle={{ paddingTop: Ratio(40) }}
                        renderItem={RenderChannel}
                        showsVerticalScrollIndicator={false}
                        style={{ flex: 0.35 }}
                        type="row"
                        horizontal={false}
                        estimatedItemSize={Ratio(100)}
                    />
                }
            </View>
        </Screen>
    )
}

const styles = StyleSheet.create({
    screen: {
        backgroundColor: "#0A1C34",
        width: windowWidth,
        height: windowHeight,
        position: 'absolute',
        justifyContent: "center",
    },
    recyclerItemSettings: {
        width: windowWidth / 8,
        height: windowWidth / 12,
        backgroundColor: "#2b3e54",
        borderRadius: 30,
        alignItems: "center",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.8,
        justifyContent: 'center'
    },
    txtButton: {
        color: "#fff",
        fontSize: isPlatformAndroidtv ? windowWidth / 95 : windowWidth / 110,
        width: 250,
        textAlign: "center"
    },
    txtButtonSettings: {
        color: "#fff",
        fontSize: windowWidth / 90,
        fontWeight: "bold",
        marginTop: 25,
        textTransform: "uppercase"
    },
    channelLogo: {
        width: Ratio(40),
        height: Ratio(40),
    },
    genreitem: {
        width: Dimensions.get("window").width / 5,
        height: Dimensions.get("window").width / 30,
        borderRadius: 15,
        marginRight: 30,
        backgroundColor: "#2b3e54",
        marginTop: 10
    },
    recyclerCategoriesItem: {
        width: isPlatformAndroidtv ? getWidth() / 8 : getWidth() / 4,
        height: isPlatformAndroidtv ? getWidth() / 40 : getWidth() / 20,
        marginBottom: Ratio(10),
        borderRadius: Ratio(10),
        backgroundColor: "#2b3e54",
        alignItems: "flex-start",
        justifyContent: 'center',
        alignSelf: "center"
    },
    recyclerItem: {
        width: windowWidth / 6.6,
        height: windowWidth / 6.6,
        borderRadius: 20,
        marginTop: 15,
        margin: 5,
        backgroundColor: "#111",
        justifyContent: 'center'
    },
    recyclerChannelItem: {
        width: isPlatformAndroidtv ? getWidth() / 6 : getWidth() / 3,
        height: isPlatformAndroidtv ? getWidth() / 40 : getWidth() / 20,
        marginBottom: Ratio(10),
        borderRadius: Ratio(10),
        backgroundColor: "#2b3e54",
        alignItems: "flex-start",
        justifyContent: 'center',
        alignSelf: "center"
    }
});

export default ScreenChannelsMenu;