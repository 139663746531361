import React from 'react';
import { App, Debugger } from '@flexn/create';
import { Dimensions, LogBox } from 'react-native';
import { ThemeProvider, checkPlatform } from '../config';
import Navigation from '../navigation';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query';
import jwtDecode from 'jwt-decode';
import AsyncStorage from '@react-native-async-storage/async-storage';
// @ts-ignore
import startup from "../assets/video.mp4";
import { isEngineRnWeb, isPlatformTizen, isPlatformWebos, isWebBased } from '@rnv/renative';
import * as Sentry from "@sentry/react";
import CryptoJS from 'crypto-js';
import packageJson from "../../package.json";
import i18n from '../i18n';
import { I18nextProvider } from 'react-i18next';

Sentry.init({
    dsn: "https://9f35669a553765253c7856e4c6034c45@o4506824143929344.ingest.us.sentry.io/4506859713593344",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

LogBox.ignoreAllLogs();

const generate12DigitId = (inputString: string) => {
    console.log(inputString);

    try {
        const hash = CryptoJS.SHA256(inputString).toString(CryptoJS.enc.Hex);
        const shortHash = hash.substring(0, 12).toUpperCase();

        return shortHash.substring(0, 2) + ':' +
            shortHash.substring(2, 4) + ':' +
            shortHash.substring(4, 6) + ':' +
            shortHash.substring(6, 8) + ':' +
            shortHash.substring(8, 10) + ':' +
            shortHash.substring(10, 12);
    } catch (error) {
        console.error("Error generating ID:", error);
        return "No ID found"; // Trajtimi i gabimit sipas nevojave të aplikacionit tuaj
    }

};

const MyApp = () => {

    const queryClient = new QueryClient();

    const [version, setVersion] = React.useState("");
    const [id, setId] = React.useState("");
    const [orbixplayId, setOrbixplayId] = React.useState("");

    const TizenGetMACAddress = async () => {
        try {

            // @ts-ignore
            console.log("DUID: " + webapis.productinfo.getDuid());
            setId(generate12DigitId(webapis.productinfo.getDuid()));
            setOrbixplayId(generate12DigitId(webapis.productinfo.getDuid()));
            await AsyncStorage.setItem('@device_id', generate12DigitId(webapis.productinfo.getDuid()));

            // tizen.systeminfo.getPropertyValue("ETHERNET_NETWORK", async function (ethernet) {
            //     console.log("MAC Address: " + ethernet.macAddress);
            //     setId(generate12DigitId(ethernet.macAddress));
            //     setOrbixplayId(generate12DigitId(ethernet.macAddress));
            //     await AsyncStorage.setItem('@device_id', generate12DigitId(ethernet.macAddress));
            // }, function (error: any) {
            //     console.error("Error getting MAC address: " + error.message);
            // });
        } catch (e: any) {
            console.error("Exception while getting MAC address: " + e.message);
        }
    }

    React.useEffect(() => {

        const getLicense = async () => {

            try {

                if (id) {
                    const response = await fetch('https://eu-central-1.aws.realm.mongodb.com/api/client/v2.0/app/live-lbgjx/auth/providers/custom-function/login', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "deviceid": id,
                            "device_mac_address": id,
                            "orbixplay_id": orbixplayId,
                            "app_name": "OrbixPLay",
                            "app_version": packageJson.version,
                            "ntype": 1,
                            "firmware": "10101",
                            "screensize": Dimensions.get("window").width + "x" + Dimensions.get("window").height,
                            "os": checkPlatform
                        }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const responseData = await response.json();
                    const decoded: any = jwtDecode(responseData.access_token);

                    await AsyncStorage.setItem('@license_timestamp', decoded.user_data.license_end_date);
                    await AsyncStorage.setItem('@access_token', responseData.access_token);
                    await AsyncStorage.setItem('@refresh_token', responseData.refresh_token);

                }

            } catch (error) {
                console.error('Error during API call:', error);
            }

        };

        getLicense();

    }, [id]);

    const [showVideo, setShowVideo] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => {
            setShowVideo(false);
        }, 6500);
    }, []);

    React.useEffect(() => {

        const getInitialLanguage = async () => {
            try {
                const storedLanguage = await AsyncStorage.getItem('@i18n');

                if (storedLanguage == "ar") {
                    i18n.changeLanguage(storedLanguage ? storedLanguage : 'en');
                    document.body.dir = i18n.dir();
                } else {
                    i18n.changeLanguage(storedLanguage ? storedLanguage : 'en');

                }

            } catch (error) {
                i18n.changeLanguage('en');
            }
        };

        getInitialLanguage();

    }, []);

    React.useEffect(() => {

        if (isPlatformTizen) {
            // TizenGetMACAddress();


            // setId("sgdeviceos");

        } else if (isPlatformWebos) {
            // @ts-ignore
            webOS.service.request('luna://com.webos.service.sm', {
                method: 'deviceid/getIDs',
                parameters: {
                    idType: ['LGUDID'],
                },
                onSuccess: async function (inResponse: any) {
                    setId(generate12DigitId(inResponse.idList[0].idValue));
                    setOrbixplayId(generate12DigitId(inResponse.idList[0].idValue));
                    await AsyncStorage.setItem('@device_id', generate12DigitId(inResponse.idList[0].idValue));
                    console.log('Result: ' + JSON.stringify(inResponse));
                    // To-Do something
                },
                onFailure: function (inError: any) {
                    console.log('Failed to get system ID information');
                    console.log('[' + inError.errorCode + ']: ' + inError.errorText);
                    // To-Do something
                    return;
                },
            });
        }

    }, [showVideo])

    return (
        <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18n}>
                <ThemeProvider>
                    <App style={{ flex: 1 }}>
                        {showVideo ?
                            (
                                <video
                                    autoPlay
                                    src="https://samsung-bigscreen-build.pages.dev/static/media/video.mp4"
                                    width="100%"
                                    height="100%"
                                />
                            ) :
                            (
                                <>
                                    <Navigation />
                                    <Debugger />
                                </>
                            )
                        }
                    </App>
                </ThemeProvider>
            </I18nextProvider>
        </QueryClientProvider>
    )
};

export default MyApp;
