import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "playlists": {
                playlist: "Playlists",
                enter_device_id_label: "and enter this device id:",
                dont_have_playlist_label: "You don't have any playlist",
                playlist_unavailable_label: 'Playlist Unavailable',
                your_playlist_not_available: 'Your playlist is not available.',
                remove_playlists_label: 'Remove Playlist',
                want_to_remove_label: 'Do you want to remove ?',
                remove_cancel_label: 'Cancel',
                lifetime_label: " - Lifetime",
                scan_playlist_label: "Scan to add playlist",
                refresh_label: "Refresh",
                privacy_mode_label: "Privacy Mode",
                version_label: "Version",
                license_label: "License",
                expired_label: "Expired",
                not_valid_label: "Not Valid",
                valid_label: "Valid",
                expires_in_label: "expires in "
            },
            "home": {
                playlist_incorrect_label: 'Your playlist is incorrect. Please verify your playlist.',
                livetv_label: 'Live TV',
                movies_label: 'Movies',
                series_label: 'Series'
            },
            livetv: {
                channels_menu_label: 'Channels Menu'
            },
            vods: {
                director_label: 'Director',
                actors_label: 'Actors',
                genres_label: 'Genres',
                play_label: 'Play',
            },
            serials: {
                seasons: 'Seasons',
                episodes: "Episodes"
            },
            settings: {
                change_language: "Languages",
                change_player_label: 'Change Player',
                playlistinfo_label: 'Playlist Info',
                privacy_policy_label: 'Privacy & Policy',
                terms_of_use_label: "Terms of use",
                upgrade_label: 'Upgrade',
                status_label: "Status",
                upgrade_app_label: 'Upgrade App',
                user_info_label: "User Info"
            },
            check_license: {
                you_dont_have_license: "You don't have a license",
                scan_to_buy: "Scan to buy license",
                how_to_activate: "How to activate",
                scan_qr_visit: "Scan QR code or visit",
                type_mac_address: "Type your device Mac Address",
                mac_address: "Mac Address",
                activate: "Click 'Activate' button and process payment",
                myplaylists: "My Playlists",
                check: "Check License",
            },
            vpn: {
                no_privacymode: "No Privacy Mode license is available!",
                scan_to_activate: "Please scan to activate.",
                how_to_activate: "How to activate",
                scan_qr_visit: "Scan QR code or visit",
                type_mac_address: "Type your device Mac Address",
                mac_address: "Mac Address",
                activate: "Click 'Activate' button and process payment",
                goback: "Go Back",
                check: "Check License",
            }
        }
    },
    it: {
        "translation": {
            "playlists": {
                "playlist": "Playlist",
                "enter_device_id_label": "e inserisci questo ID dispositivo:",
                "dont_have_playlist_label": "Non hai nessuna playlist",
                "playlist_unavailable_label": "Playlist Non Disponibile",
                "your_playlist_not_available": "La tua playlist non è disponibile.",
                "remove_playlists_label": "Rimuovi Playlist",
                "want_to_remove_label": "Vuoi rimuovere?",
                "remove_cancel_label": "Annulla",
                "lifetime_label": " - A vita",
                "scan_playlist_label": "Scansiona per aggiungere playlist",
                "refresh_label": "Aggiorna",
                "privacy_mode_label": "Modalità Privacy",
                "version_label": "Versione",
                "license_label": "Licenza",
                "expired_label": "Scaduta",
                "not_valid_label": "Non Valida",
                "valid_label": "Valida",
                "expires_in_label": "scade tra"
            },
            "home": {
                "playlist_incorrect_label": "La tua playlist è errata. Verifica la tua playlist.",
                "livetv_label": "TV in Diretta",
                "movies_label": "Film",
                "series_label": "Serie"
            },
            "livetv": {
                "channels_menu_label": "Menu Canali"
            },
            "vods": {
                "director_label": "Regista",
                "actors_label": "Attori",
                "genres_label": "Generi",
                "play_label": "Riproduci"
            },
            "serials": {
                "seasons": "Stagioni",
                "episodes": "Episodi"
            },
            "settings": {
                "change_language": "Lingue",
                "change_player_label": "Cambia Player",
                "playlistinfo_label": "Info Playlist",
                "privacy_policy_label": "Privacy & Politica",
                "terms_of_use_label": "Termini d'Uso",
                "upgrade_label": "Aggiorna",
                "status_label": "Stato",
                "upgrade_app_label": "Aggiorna App",
                "user_info_label": "Info Utente"
            },
            check_license: {
                you_dont_have_license: "Non hai una licenza",
                scan_to_buy: "Scansiona per acquistare la licenza",
                how_to_activate: "Come attivare",
                scan_qr_visit: "Scansiona il codice QR o visita",
                type_mac_address: "Inserisci il tuo indirizzo MAC del dispositivo",
                mac_address: "Indirizzo MAC",
                activate: "Clicca sul pulsante 'Attiva' e procedi con il pagamento",
                myplaylists: "Le mie Playlist",
                check: "Controlla Licenza",
            },
            vpn: {
                no_privacymode: "Non è disponibile alcuna licenza per la Modalità Privacy!",
                scan_to_activate: "Scansiona per attivare.",
                how_to_activate: "Come attivare",
                scan_qr_visit: "Scansiona il codice QR o visita",
                type_mac_address: "Inserisci il tuo indirizzo MAC del dispositivo",
                mac_address: "Indirizzo MAC",
                activate: "Clicca sul pulsante 'Attiva' e procedi con il pagamento",
                goback: "Torna Indietro",
                check: "Controlla Licenza",
            }
        }
    },
    es: {
        "translation": {
            "playlists": {
                "playlist": "Listas de Reproducción",
                "enter_device_id_label": "e ingresa este ID de dispositivo:",
                "dont_have_playlist_label": "No tienes ninguna lista de reproducción",
                "playlist_unavailable_label": "Lista de Reproducción No Disponible",
                "your_playlist_not_available": "Tu lista de reproducción no está disponible.",
                "remove_playlists_label": "Eliminar Lista de Reproducción",
                "want_to_remove_label": "¿Quieres eliminar?",
                "remove_cancel_label": "Cancelar",
                "lifetime_label": " - De por Vida",
                "scan_playlist_label": "Escanear para agregar lista de reproducción",
                "refresh_label": "Actualizar",
                "privacy_mode_label": "Modo Privado",
                "version_label": "Versión",
                "license_label": "Licencia",
                "expired_label": "Expirada",
                "not_valid_label": "No Válida",
                "valid_label": "Válida",
                "expires_in_label": "expira en"
            },
            "home": {
                "playlist_incorrect_label": "Tu lista de reproducción es incorrecta. Verifica tu lista de reproducción.",
                "livetv_label": "TV en Vivo",
                "movies_label": "Películas",
                "series_label": "Series"
            },
            "livetv": {
                "channels_menu_label": "Menú de Canales"
            },
            "vods": {
                "director_label": "Director",
                "actors_label": "Actores",
                "genres_label": "Géneros",
                "play_label": "Reproducir"
            },
            "serials": {
                "seasons": "Temporadas",
                "episodes": "Episodios"
            },
            "settings": {
                "change_language": "Idiomas",
                "change_player_label": "Cambiar Reproductor",
                "playlistinfo_label": "Información de la Lista de Reproducción",
                "privacy_policy_label": "Política de Privacidad",
                "terms_of_use_label": "Términos de Uso",
                "upgrade_label": "Actualizar",
                "status_label": "Estado",
                "upgrade_app_label": "Actualizar App",
                "user_info_label": "Información del Usuario"
            },
            check_license: {
                you_dont_have_license: "No tienes una licencia",
                scan_to_buy: "Escanear para comprar la licencia",
                how_to_activate: "Cómo activar",
                scan_qr_visit: "Escanear código QR o visitar",
                type_mac_address: "Ingresa la dirección MAC de tu dispositivo",
                mac_address: "Dirección MAC",
                activate: "Haz clic en el botón 'Activar' y procesa el pago",
                myplaylists: "Mis Listas de Reproducción",
                check: "Verificar Licencia",
            },
            vpn: {
                no_privacymode: "¡No hay disponible ninguna licencia de Modo de Privacidad!",
                scan_to_activate: "Escanear para activar.",
                how_to_activate: "Cómo activar",
                scan_qr_visit: "Escanear código QR o visitar",
                type_mac_address: "Ingresa la dirección MAC de tu dispositivo",
                mac_address: "Dirección MAC",
                activate: "Haz clic en el botón 'Activar' y procesa el pago",
                goback: "Volver",
                check: "Verificar Licencia",
            }
        }
    },
    fr: {
        "translation": {
            "playlists": {
                "playlist": "Listes de Lecture",
                "enter_device_id_label": "et entrez cet identifiant de périphérique :",
                "dont_have_playlist_label": "Vous n'avez aucune liste de lecture",
                "playlist_unavailable_label": "Liste de Lecture Indisponible",
                "your_playlist_not_available": "Votre liste de lecture n'est pas disponible.",
                "remove_playlists_label": "Supprimer la Liste de Lecture",
                "want_to_remove_label": "Voulez-vous supprimer ?",
                "remove_cancel_label": "Annuler",
                "lifetime_label": " - À vie",
                "scan_playlist_label": "Scanner pour ajouter une liste de lecture",
                "refresh_label": "Actualiser",
                "privacy_mode_label": "Mode Privé",
                "version_label": "Version",
                "license_label": "Licence",
                "expired_label": "Expiré",
                "not_valid_label": "Non Valide",
                "valid_label": "Valide",
                "expires_in_label": "expire dans"
            },
            "home": {
                "playlist_incorrect_label": "Votre liste de lecture est incorrecte. Veuillez vérifier votre liste de lecture.",
                "livetv_label": "TV en Direct",
                "movies_label": "Films",
                "series_label": "Séries"
            },
            "livetv": {
                "channels_menu_label": "Menu des Chaînes"
            },
            "vods": {
                "director_label": "Réalisateur",
                "actors_label": "Acteurs",
                "genres_label": "Genres",
                "play_label": "Lire"
            },
            "serials": {
                "seasons": "Saisons",
                "episodes": "Épisodes"
            },
            "settings": {
                "change_language": "Langues",
                "change_player_label": "Changer de Lecteur",
                "playlistinfo_label": "Infos Liste de Lecture",
                "privacy_policy_label": "Politique de Confidentialité",
                "terms_of_use_label": "Conditions d'Utilisation",
                "upgrade_label": "Mettre à Jour",
                "status_label": "Statut",
                "upgrade_app_label": "Mettre à Jour l'App",
                "user_info_label": "Infos Utilisateur"
            },
            check_license: {
                you_dont_have_license: "Vous n'avez pas de licence",
                scan_to_buy: "Scanner pour acheter une licence",
                how_to_activate: "Comment activer",
                scan_qr_visit: "Scanner le code QR ou visiter",
                type_mac_address: "Entrez l'adresse MAC de votre appareil",
                mac_address: "Adresse MAC",
                activate: "Cliquez sur le bouton 'Activer' et procédez au paiement",
                myplaylists: "Mes Listes de Lecture",
                check: "Vérifier la Licence",
            },
            vpn: {
                no_privacymode: "Aucune licence de Mode de Confidentialité disponible!",
                scan_to_activate: "Veuillez scanner pour activer.",
                how_to_activate: "Comment activer",
                scan_qr_visit: "Scanner le code QR ou visiter",
                type_mac_address: "Entrez l'adresse MAC de votre appareil",
                mac_address: "Adresse MAC",
                activate: "Cliquez sur le bouton 'Activer' et procédez au paiement",
                goback: "Retourner",
                check: "Vérifier la Licence",
            }
        }
    },
    zh: {
        "translation": {
            "playlists": {
                "playlist": "播放列表",
                "enter_device_id_label": "并输入此设备ID：",
                "dont_have_playlist_label": "你没有任何播放列表",
                "playlist_unavailable_label": "播放列表不可用",
                "your_playlist_not_available": "你的播放列表不可用。",
                "remove_playlists_label": "移除播放列表",
                "want_to_remove_label": "你想移除吗？",
                "remove_cancel_label": "取消",
                "lifetime_label": " - 终身",
                "scan_playlist_label": "扫描以添加播放列表",
                "refresh_label": "刷新",
                "privacy_mode_label": "隐私模式",
                "version_label": "版本",
                "license_label": "许可证",
                "expired_label": "已过期",
                "not_valid_label": "无效",
                "valid_label": "有效",
                "expires_in_label": "将在...到期"
            },
            "home": {
                "playlist_incorrect_label": "你的播放列表不正确。请核实你的播放列表。",
                "livetv_label": "直播电视",
                "movies_label": "电影",
                "series_label": "连续剧"
            },
            "livetv": {
                "channels_menu_label": "频道菜单"
            },
            "vods": {
                "director_label": "导演",
                "actors_label": "演员",
                "genres_label": "类型",
                "play_label": "播放"
            },
            "serials": {
                "seasons": "季",
                "episodes": "集"
            },
            "settings": {
                "change_language": "语言",
                "change_player_label": "更换播放器",
                "playlistinfo_label": "播放列表信息",
                "privacy_policy_label": "隐私政策",
                "terms_of_use_label": "使用条款",
                "upgrade_label": "升级",
                "status_label": "状态",
                "upgrade_app_label": "升级应用",
                "user_info_label": "用户信息"
            },
            check_license: {
                you_dont_have_license: "您没有许可证",
                scan_to_buy: "扫描购买许可证",
                how_to_activate: "如何激活",
                scan_qr_visit: "扫描二维码或访问",
                type_mac_address: "输入您的设备MAC地址",
                mac_address: "MAC地址",
                activate: "点击“激活”按钮并完成支付流程",
                myplaylists: "我的播放列表",
                check: "检查许可证",
            },
            vpn: {
                no_privacymode: "没有可用的隐私模式许可证！",
                scan_to_activate: "请扫描以激活。",
                how_to_activate: "如何激活",
                scan_qr_visit: "扫描二维码或访问",
                type_mac_address: "输入您的设备MAC地址",
                mac_address: "MAC地址",
                activate: "点击“激活”按钮并完成支付流程",
                goback: "返回",
                check: "检查许可证",
            }
        }
    },
    de: {
        "translation": {
            "playlists": {
                "playlist": "Wiedergabelisten",
                "enter_device_id_label": "und geben Sie diese Geräte-ID ein:",
                "dont_have_playlist_label": "Sie haben keine Wiedergabelisten",
                "playlist_unavailable_label": "Wiedergabeliste Nicht Verfügbar",
                "your_playlist_not_available": "Ihre Wiedergabeliste ist nicht verfügbar.",
                "remove_playlists_label": "Wiedergabeliste Entfernen",
                "want_to_remove_label": "Möchten Sie entfernen?",
                "remove_cancel_label": "Abbrechen",
                "lifetime_label": " - Lebenslang",
                "scan_playlist_label": "Scannen zum Hinzufügen einer Wiedergabeliste",
                "refresh_label": "Aktualisieren",
                "privacy_mode_label": "Privatsphärenmodus",
                "version_label": "Version",
                "license_label": "Lizenz",
                "expired_label": "Abgelaufen",
                "not_valid_label": "Nicht Gültig",
                "valid_label": "Gültig",
                "expires_in_label": "läuft ab in"
            },
            "home": {
                "playlist_incorrect_label": "Ihre Wiedergabeliste ist falsch. Bitte überprüfen Sie Ihre Wiedergabeliste.",
                "livetv_label": "Live-TV",
                "movies_label": "Filme",
                "series_label": "Serien"
            },
            "livetv": {
                "channels_menu_label": "Kanäle Menü"
            },
            "vods": {
                "director_label": "Regisseur",
                "actors_label": "Schauspieler",
                "genres_label": "Genres",
                "play_label": "Abspielen"
            },
            "serials": {
                "seasons": "Staffeln",
                "episodes": "Episoden"
            },
            "settings": {
                "change_language": "Sprachen",
                "change_player_label": "Player Wechseln",
                "playlistinfo_label": "Wiedergabelisteninformationen",
                "privacy_policy_label": "Datenschutzrichtlinie",
                "terms_of_use_label": "Nutzungsbedingungen",
                "upgrade_label": "Aktualisieren",
                "status_label": "Status",
                "upgrade_app_label": "App Aktualisieren",
                "user_info_label": "Benutzerinformationen"
            },
            check_license: {
                you_dont_have_license: "Sie haben keine Lizenz",
                scan_to_buy: "Scannen Sie zum Kauf der Lizenz",
                how_to_activate: "Wie aktivieren",
                scan_qr_visit: "Scannen Sie den QR-Code oder besuchen Sie",
                type_mac_address: "Geben Sie die MAC-Adresse Ihres Geräts ein",
                mac_address: "MAC-Adresse",
                activate: "Klicken Sie auf die Schaltfläche 'Aktivieren' und führen Sie die Zahlung durch",
                myplaylists: "Meine Playlists",
                check: "Lizenz überprüfen",
            },
            vpn: {
                no_privacymode: "Keine Datenschutzmodus-Lizenz verfügbar!",
                scan_to_activate: "Bitte scannen, um zu aktivieren.",
                how_to_activate: "Wie aktivieren",
                scan_qr_visit: "Scannen Sie den QR-Code oder besuchen Sie",
                type_mac_address: "Geben Sie die MAC-Adresse Ihres Geräts ein",
                mac_address: "MAC-Adresse",
                activate: "Klicken Sie auf die Schaltfläche 'Aktivieren' und führen Sie die Zahlung durch",
                goback: "Zurück",
                check: "Lizenz überprüfen",
            }
        }
    },
    el: {
        "translation": {
            "playlists": {
                "playlist": "Λίστες αναπαραγωγής",
                "enter_device_id_label": "και εισάγετε αυτό το αναγνωριστικό συσκευής:",
                "dont_have_playlist_label": "Δεν έχετε καμία λίστα αναπαραγωγής",
                "playlist_unavailable_label": "Η λίστα αναπαραγωγής δεν είναι διαθέσιμη",
                "your_playlist_not_available": "Η λίστα αναπαραγωγής σας δεν είναι διαθέσιμη.",
                "remove_playlists_label": "Αφαίρεση λίστας αναπαραγωγής",
                "want_to_remove_label": "Θέλετε να αφαιρέσετε;",
                "remove_cancel_label": "Άκυρο",
                "lifetime_label": " - Διάρκεια ζωής",
                "scan_playlist_label": "Σάρωση για προσθήκη λίστας αναπαραγωγής",
                "refresh_label": "Ανανέωση",
                "privacy_mode_label": "Λειτουργία απορρήτου",
                "version_label": "Έκδοση",
                "license_label": "Άδεια",
                "expired_label": "Έληξε",
                "not_valid_label": "Μη έγκυρο",
                "valid_label": "Έγκυρο",
                "expires_in_label": "λήγει σε"
            },
            "home": {
                "playlist_incorrect_label": "Η λίστα αναπαραγωγής σας είναι εσφαλμένη. Επαληθεύστε τη λίστα αναπαραγωγής σας.",
                "livetv_label": "Ζωντανή TV",
                "movies_label": "Ταινίες",
                "series_label": "Σειρές"
            },
            "livetv": {
                "channels_menu_label": "Μενού Καναλιών"
            },
            "vods": {
                "director_label": "Σκηνοθέτης",
                "actors_label": "Ηθοποιοί",
                "genres_label": "Είδη",
                "play_label": "Αναπαραγωγή"
            },
            "serials": {
                "seasons": "Σεζόν",
                "episodes": "Επεισόδια"
            },
            "settings": {
                "change_language": "Γλώσσες",
                "change_player_label": "Αλλαγή Αναπαραγωγέα",
                "playlistinfo_label": "Πληροφορίες Λίστας Αναπαραγωγής",
                "privacy_policy_label": "Πολιτική Απορρήτου",
                "terms_of_use_label": "Όροι Χρήσης",
                "upgrade_label": "Αναβάθμιση",
                "status_label": "Κατάσταση",
                "upgrade_app_label": "Αναβάθμιση Εφαρμογής",
                "user_info_label": "Πληροφορίες Χρήστη"
            },
            check_license: {
                you_dont_have_license: "Δεν έχετε άδεια",
                scan_to_buy: "Σάρωση για αγορά άδειας",
                how_to_activate: "Πώς να ενεργοποιήσετε",
                scan_qr_visit: "Σάρωση QR κωδικού ή επίσκεψη",
                type_mac_address: "Πληκτρολογήστε τη διεύθυνση MAC της συσκευής σας",
                mac_address: "Διεύθυνση MAC",
                activate: "Κάντε κλικ στο κουμπί 'Ενεργοποίηση' και ολοκληρώστε την πληρωμή",
                myplaylists: "Οι Λίστες μου Αναπαραγωγής",
                check: "Έλεγχος Άδειας",
            },
            vpn: {
                no_privacymode: "Δεν υπάρχει διαθέσιμη άδεια για Λειτουργία Απορρήτου!",
                scan_to_activate: "Παρακαλώ σκανάρετε για να ενεργοποιήσετε.",
                how_to_activate: "Πώς να ενεργοποιήσετε",
                scan_qr_visit: "Σάρωση QR κωδικού ή επίσκεψη",
                type_mac_address: "Πληκτρολογήστε τη διεύθυνση MAC της συσκευής σας",
                mac_address: "Διεύθυνση MAC",
                activate: "Κάντε κλικ στο κουμπί 'Ενεργοποίηση' και ολοκληρώστε την πληρωμή",
                goback: "Πίσω",
                check: "Έλεγχος Άδειας",
            }
        }
    },
    ar: {
        "translation": {
            "playlists": {
                "playlist": "قوائم التشغيل",
                "enter_device_id_label": "وأدخل هذا المعرف للجهاز:",
                "dont_have_playlist_label": "ليس لديك أي قائمة تشغيل",
                "playlist_unavailable_label": "قائمة التشغيل غير متاحة",
                "your_playlist_not_available": "قائمة التشغيل الخاصة بك غير متاحة.",
                "remove_playlists_label": "إزالة قائمة التشغيل",
                "want_to_remove_label": "هل تريد الإزالة؟",
                "remove_cancel_label": "إلغاء",
                "lifetime_label": " - مدى الحياة",
                "scan_playlist_label": "امسح لإضافة قائمة التشغيل",
                "refresh_label": "تحديث",
                "privacy_mode_label": "وضع الخصوصية",
                "version_label": "الإصدار",
                "license_label": "الرخصة",
                "expired_label": "منتهية الصلاحية",
                "not_valid_label": "غير صالح",
                "valid_label": "صالح",
                "expires_in_label": "ينتهي في"
            },
            "home": {
                "playlist_incorrect_label": "قائمة التشغيل الخاصة بك غير صحيحة. يرجى التحقق من قائمة التشغيل الخاصة بك.",
                "livetv_label": "التلفاز المباشر",
                "movies_label": "أفلام",
                "series_label": "مسلسلات"
            },
            "livetv": {
                "channels_menu_label": "قائمة القنوات"
            },
            "vods": {
                "director_label": "المخرج",
                "actors_label": "الممثلين",
                "genres_label": "الأنواع",
                "play_label": "تشغيل"
            },
            "serials": {
                "seasons": "المواسم",
                "episodes": "الحلقات"
            },
            "settings": {
                "change_language": "اللغات",
                "change_player_label": "تغيير المشغل",
                "playlistinfo_label": "معلومات قائمة التشغيل",
                "privacy_policy_label": "سياسة الخصوصية",
                "terms_of_use_label": "شروط الاستخدام",
                "upgrade_label": "ترقية",
                "status_label": "الحالة",
                "upgrade_app_label": "ترقية التطبيق",
                "user_info_label": "معلومات المستخدم"
            },
            check_license: {
                you_dont_have_license: "ليس لديك ترخيص",
                scan_to_buy: "مسح لشراء الترخيص",
                how_to_activate: "كيفية التفعيل",
                scan_qr_visit: "مسح رمز الاستجابة السريعة أو زيارة",
                type_mac_address: "أدخل عنوان MAC لجهازك",
                mac_address: "عنوان MAC",
                activate: "انقر فوق الزر 'تفعيل' وأكمل عملية الدفع",
                myplaylists: "قوائم التشغيل الخاصة بي",
                check: "التحقق من الترخيص",
            },
            vpn: {
                no_privacymode: "لا توجد رخصة لوضع الخصوصية متاحة!",
                scan_to_activate: "يرجى المسح للتفعيل.",
                how_to_activate: "كيفية التفعيل",
                scan_qr_visit: "مسح رمز الاستجابة السريعة أو زيارة",
                type_mac_address: "أدخل عنوان MAC لجهازك",
                mac_address: "عنوان MAC",
                activate: "انقر فوق الزر 'تفعيل' وأكمل عملية الدفع",
                goback: "العودة",
                check: "التحقق من الترخيص",
            }
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        compatibilityJSON: "v3",
        lng: i18n.language ? i18n.language : "en",
        fallbackLng: i18n.language ? i18n.language : "en",
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;