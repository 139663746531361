import React, { useContext, useRef, useState } from 'react';
import { isFactorMobile, isPlatformMacos, isPlatformWeb, isFactorTv, isPlatformAndroidtv, isEngineRnWeb } from '@rnv/renative';
import {
    App,
    Image,
    Text,
    StyleSheet,
    useTVRemoteHandler,
    View,
    ScrollView,
    Pressable,
    ActivityIndicator
} from '@flexn/create';
import { Dimensions, useWindowDimensions } from 'react-native';
import Screen from '../screen';
import { ThemeContext, ROUTES, Ratio, isWebBased } from '../../config';
import logo from "../../../assets/images/applay_logo.png";
import { interval } from '../../utils';
import { useQuery, useMutation } from 'react-query';
import { useIsFocused } from "@react-navigation/native";
import Icon from 'react-native-vector-icons/Ionicons';
import { VPN_STORAGE } from '../../storage/VPNStorage';
import { Buffer } from 'buffer';
import { PLAYLIST_STORAGE_PASSWORD, PLAYLIST_STORAGE_URL, PLAYLIST_STORAGE_USERNAME } from '../../storage/PlayListStorage';
import { GET_AUTH_TOKEN } from '../../storage/AuthStorage';

let ControlsTimeout: any;

const ScreenPlaySerie = ({ route, navigation, router }: { route?: any, navigation?: any, router?: any }) => {

    const { theme } = useContext(ThemeContext);
    const params = isPlatformWeb ? router.query : route?.params;

    const playerRef: any = useRef();
    const [isVideoBuffering, setIsVideoBuffering] = useState(false);

    const { height } = useWindowDimensions();

    const [activeVpn, setActiveVpn] = React.useState(false);

    const [tvHandlerRemote, setTvHandlerRemote] = React.useState<any>();

    const [liveStream, setLiveStream] = React.useState("");
    const [vpnliveStream, setVpnLiveStream] = React.useState("");

    const getProxyData = async () => {
        try {
            const authToken = await GET_AUTH_TOKEN();
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/live-lbgjx/endpoint/getmyproxy', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch resource user data');
        }
    };

    const { data: proxydata, error: errproxy, isLoading: isLProxy } = useQuery('proxy-data', getProxyData, {
        cacheTime: 6 * 60 * 60 * 1000
    });

    // player states
    const [seek, setSeek] = React.useState(10);
    const [currentSeek, setCurrentSeek] = React.useState(0);
    const [durationSeconds, setDurationSeconds] = React.useState(0);
    const [progressSeconds, setProgressSeconds] = React.useState(0);
    const [progress, setProgress] = React.useState(0);
    const [controls, setControls] = React.useState(true);
    const [play, setPlay] = React.useState(false);
    const [iskeypress, setIskeypress] = React.useState(false);

    useTVRemoteHandler((remote: any) => {
        setTvHandlerRemote(remote);
    });

    const toHoursAndMinutes = (totalSeconds: number) => {

        const totalMinutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = Math.floor(seconds).toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    };

    React.useEffect(() => {
        if (tvHandlerRemote) {

            clearTimeout(ControlsTimeout);

            if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "left") {

                setControls(true);
                //setIskeypress(true);

                playerRef.current.currentTime = progressSeconds - 150;


            }

            if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "right") {
                setControls(true);
                //setIskeypress(true);
                playerRef.current.currentTime = progressSeconds + 150;

            }

            if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "select") {
                setControls(true);
                if (playerRef.current.paused) {
                    playerRef.current.play();
                    setPlay(true);
                }
                else {
                    playerRef.current.pause();
                    setPlay(false);
                }
            }

            if (isEngineRnWeb) {
                if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "back") {
                    navigation.goBack();
                }
            }

            // if (tvHandlerRemote.eventKeyAction == "down" && (tvHandlerRemote.eventType == "left" || tvHandlerRemote.eventType == "right")) {

            //     if (isWebBased) {
            //         playerRef.current.seekTo(progressSeconds + 0);
            //     } else {

            //     }
            //     //setIskeypress(false);

            // }

        }

    }, [tvHandlerRemote]);

    const handleStart = () => {

        console.log('1111111111111 start');
        // setTimeout(() => {
        //     setIsProgressBarVisible(false);
        // }, 5000);
    };

    const handleBuffer = () => {
        console.log('222222222222 buffer');

        // setIsBuffering(true);
    };

    // Hide loading throbber when buffering ends
    const handleBufferEnd = () => {
        console.log(play, '3333333333333 buffer end');
        //setPlay(false);

        // setTimeout(() => {
        //     setControls(false);
        // }, 5000);

        ControlsTimeout = setTimeout(() => {
            console.log(play, " set timeout", Date.now());
            setControls(false);
        }, 5000);


        // setIsBuffering(false);
    };

    React.useEffect(() => {

        return () => {
            clearTimeout(ControlsTimeout);
        }

    }, [])

    React.useEffect(() => {

        const vpn = async () => {
            if (await VPN_STORAGE() == "on") {
                setActiveVpn(true);
            } else {
                setActiveVpn(false);
            }
        }

        vpn();

        const stream = async () => {

            setLiveStream(`${await PLAYLIST_STORAGE_URL()}:80/series/${await PLAYLIST_STORAGE_USERNAME()}/${await PLAYLIST_STORAGE_PASSWORD()}/${params.item.id}.${params.item.container_extension}`);
            let encodedStream = Buffer.from(`${await PLAYLIST_STORAGE_URL()}/movie/${await PLAYLIST_STORAGE_USERNAME()}/${await PLAYLIST_STORAGE_PASSWORD()}/` + params.item.id + ".mp4", 'utf-8').toString('base64');
            if (proxydata) {
                setVpnLiveStream(proxydata?.proxy_url + encodedStream);
            }
        }

        vpn();
        stream();

    }, [])

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#111" }]}
            focusOptions={{ screenOrder: 5 }}
        >
            {controls &&
                <View style={styles.videoControls}>
                    <View style={{ padding: isPlatformAndroidtv ? 30 : 50 }}>
                        {/* @ts-ignore */}
                        <Text style={{ color: "white", fontSize: isPlatformAndroidtv ? 40 : 120 }}>{params.item.name}</Text>
                    </View>
                    <View style={{ justifyContent: "center", alignSelf: "center", flex: 1, marginTop: -100 }}>
                        {
                            isVideoBuffering
                                ? <View>
                                    <ActivityIndicator size="large" color="#ddd" />
                                </View>
                                : play
                                    ? <Icon style={{ color: "white", fontSize: 50 }} name="pause" />
                                    : <Icon style={{ color: "white", fontSize: 50 }} name="play" />
                        }
                    </View>
                    <View style={{ marginBottom: isPlatformAndroidtv ? 20 : 40, alignSelf: "center", flexDirection: "row" }}>
                        <View style={{ marginRight: 10 }}>
                            {/* @ts-ignore */}
                            <Text style={{ color: "white", fontSize: isPlatformAndroidtv ? 14 : 25 }}>{toHoursAndMinutes(progressSeconds)}</Text>
                        </View>
                        <View style={{ alignSelf: "center" }}>
                            {isWebBased &&
                                <progress
                                    style={{
                                        width: Dimensions.get("screen").width - 450,
                                    }}
                                    value={progress}
                                    color='#128DD3'
                                />
                            }
                        </View>
                        <View style={{ marginLeft: 10 }}>
                            {/* @ts-ignore */}
                            <Text style={{ color: "white", fontSize: isPlatformAndroidtv ? 14 : 25 }}>{toHoursAndMinutes(durationSeconds)}</Text>
                        </View>
                    </View>
                </View>
            }
            {/* {!controls && isVideoBuffering &&
                <ActivityIndicator size="large" color="#ddd" />
            } */}
            <video
                ref={playerRef}
                autoPlay
                width="100%"
                height="100%"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0
                }}
                src={activeVpn ? vpnliveStream : liveStream}
                onError={() => {
                    console.log('Error in live video');
                }}
                onLoad={() => {
                    console.log('Live video loaded');
                }}
                onPlay={() => {

                    // setTimeout(() => {
                    //     setControls(false);
                    // }, 5000);

                }}
                onDurationChange={(e: any) => {
                    console.log('Duration of live video changed', e);
                    setDurationSeconds(e.target.duration);
                }}
                onWaiting={() => {
                    console.log('Live video buffering');
                    setIsVideoBuffering(true);
                }}
                onPlaying={() => {
                    console.log('Live video buffering ended');
                    setIsVideoBuffering(false);

                    ControlsTimeout = setTimeout(() => {
                        console.log(play, " set timeout", Date.now());
                        setControls(false);
                    }, 1000);
                }}
                onTimeUpdate={(e: any) => {
                    console.log(e);
                    setProgress(Math.floor(e.target.currentTime / durationSeconds * 100) / 100);
                    setProgressSeconds(e.target.currentTime);
                }}
            />
            {/* <ReactPlayer
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0
                }}
                ref={playerRef}


                onStart={handleStart}

                onBuffer={() => {
                    setIsVideoBuffering(true);
                }}
                onBufferEnd={() => {
                    setIsVideoBuffering(false);

                    ControlsTimeout = setTimeout(() => {
                        console.log(play, " set timeout", Date.now());
                        setControls(false);
                    }, 1000);

                }}


                width="100%"
                height="100%"
                url={activeVpn ? vpnliveStream : liveStream}
                playing={play}
                onDuration={(e: any) => {
                    setDurationSeconds(e);
                }}
                onPlay={() => {
                    setTimeout(() => {
                        setControls(false);
                    }, 5000);
                }}
                onProgress={(e: any) => {
                    console.log(e);
                    if (!controls) setProgress(e.played);
                }}
            /> */}
        </Screen>
    );

};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
    },
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    videoControls: {
        zIndex: 1,
        backgroundColor: "#000",
        height: "100%",
        opacity: 0.8
    }
});

export default ScreenPlaySerie;