import React, { useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions } from 'react-native';
import { Text, View, ScrollView, Image, ImageBackground, useTVRemoteHandler, StyleSheet, Pressable } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext } from '../../config';
import logo from "../../assets/logo.png";
import { isPlatformAndroidtv, isPlatformTizen, isPlatformWebos } from '@rnv/renative';
import axios from 'axios';
import { useQuery } from 'react-query';
import { GET_AUTH_TOKEN } from '../../storage/AuthStorage';
import AsyncStorage from '@react-native-async-storage/async-storage';

const AcceptTerms = ({ navigation }: { navigation?: any }) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "rgb(10, 28, 52)" }]}
            focusOptions={{ verticalWindowAlignment: 'low-edge', focusKey: 'page' }}
        >
            <ScrollView>
                <View style={{ height: Dimensions.get("window").width / 20, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <View style={{ marginTop: 30 }}>
                        <Image source={logo} style={{ width: isPlatformAndroidtv ? 160 : 300, height: isPlatformAndroidtv ? 50 : 100 }} />
                    </View>
                </View>
                <View style={{ justifyContent: "center", alignItems: "center", marginTop: Ratio(30) }}>
                    <Text style={{ color: "#ddd", fontSize: Ratio(25), textTransform: "uppercase" }}>OrbixPlay User Agreement and Disclaimer</Text>
                    <View style={{ alignItems: "center", marginTop: Ratio(10), width: Ratio(1500) }}>
                        <Text style={{ color: "#ddd", fontSize: Ratio(27), marginTop: Ratio(5) }}>Before you proceed, please read and accept the following terms and conditions:</Text>
                        <Text style={{ color: "#ddd", fontSize: Ratio(27), marginTop: Ratio(30) }}>1. Content Disclaimer: OrbixPlay is a platform designed to enhance your digital experience. However, it is important to note that OrbixPlay does not host, provide, or distribute video content. Our service is structured to support your digital journey, but we do not bear responsibility for the availability, quality, or content of video material, as this is outside the scope of our services.</Text>
                        <Text style={{ color: "#ddd", fontSize: Ratio(27), marginTop: Ratio(25) }}>2. No Liability for Third-Party Content: OrbixPlay LLC is not liable for any third-party content you may access through our application. Your engagement with any external content, including its legality, reliability, or appropriateness, is solely at your own risk.</Text>
                        <Text style={{ color: "#ddd", fontSize: Ratio(27), marginTop: Ratio(25) }}>3. Jurisdiction and Compliance: OrbixPlay LLC operates under the jurisdiction of the United States. Users are responsible for ensuring their use of the application is in compliance with all local, state, and federal laws.</Text>
                        <Text style={{ color: "#ddd", fontSize: Ratio(27), marginTop: Ratio(25) }}>4. Pricing Policy: Prices for OrbixPlay’s services are subject to change at any time without prior notice. By accepting this agreement, you acknowledge and agree that pricing may vary and that any such changes will not affect your current service until the renewal of your subscription.</Text>
                        <Text style={{ color: "#ddd", fontSize: Ratio(27), marginTop: Ratio(25) }}>5. No Warranty: The OrbixPlay application and its services are provided "as is" without any express or implied warranties, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</Text>
                        <Text style={{ color: "#ddd", fontSize: Ratio(27), marginTop: Ratio(25) }}>6. Acceptance of Terms: By clicking “Accept”, you agree to be bound by the terms of this disclaimer and acknowledge that any violation may result in termination of your access to OrbixPlay services.</Text>
                        <View style={{ flexDirection: "row" }}>
                            <Pressable
                                onMouseDown={async () => {
                                    navigation.navigate(ROUTES.PLAYLIST);
                                    await AsyncStorage.setItem("accept_terms", "true");
                                }}
                                onPress={async () => {
                                    navigation.navigate(ROUTES.PLAYLIST);
                                    await AsyncStorage.setItem("accept_terms", "true");
                                }}
                                style={{
                                    paddingLeft: Dimensions.get("window").width / 50,
                                    paddingRight: Dimensions.get("window").width / 50,
                                    paddingTop: Ratio(5),
                                    paddingBottom: Ratio(5),
                                    backgroundColor: "#2b3e54",
                                    borderRadius: 15,
                                    marginRight: Ratio(20),
                                    marginTop: Ratio(50)
                                }}
                                focusOptions={{
                                    animator: {
                                        type: "background",
                                        focus: {
                                            backgroundColor: "#128DD3"
                                        }
                                    }
                                }}
                            >
                                <View>
                                    <Text style={{ fontSize: Ratio(40), color: "#ddd" }}>Agree</Text>
                                </View>
                            </Pressable>
                            <Pressable
                                onMouseDown={async () => {

                                    if (isPlatformTizen) {
                                        if (confirm("Do you want to exit ?")) {
                                            {/* @ts-ignore */ }
                                            tizen.application.getCurrentApplication().exit();
                                        } else {
                                            console.log("User chose to stay in the app.");
                                        }
                                    } else if (isPlatformWebos) {
                                        {/* @ts-ignore */ }
                                        webOS.platformBack();
                                    }

                                }}
                                onPress={async () => {

                                    if (isPlatformTizen) {
                                        if (confirm("Do you want to exit ?")) {
                                            {/* @ts-ignore */ }
                                            tizen.application.getCurrentApplication().exit();
                                        } else {
                                            console.log("User chose to stay in the app.");
                                        }
                                    } else if (isPlatformWebos) {
                                        {/* @ts-ignore */ }
                                        webOS.platformBack();
                                    }

                                }}
                                style={{
                                    paddingLeft: Dimensions.get("window").width / 50,
                                    paddingRight: Dimensions.get("window").width / 50,
                                    paddingTop: Ratio(5),
                                    paddingBottom: Ratio(5),
                                    backgroundColor: "#2b3e54",
                                    borderRadius: 15,
                                    marginTop: Ratio(50)
                                }}
                                focusOptions={{
                                    animator: {
                                        type: "background",
                                        focus: {
                                            backgroundColor: "#128DD3"
                                        }
                                    }
                                }}
                            >
                                <View>
                                    <Text style={{ fontSize: Ratio(40), color: "#ddd" }}>Disagree</Text>
                                </View>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </Screen>
    )
}

export default AcceptTerms;