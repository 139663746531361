import React, { useCallback, useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions, ActivityIndicator } from 'react-native';
import { Text, View, CreateListRenderItemInfo, Image, ImageBackground, FlashList, StyleSheet, Pressable, ScrollView, focusElementByFocusKey } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext, getWidth } from '../../config';
import background from "../../assets/light_background.jpg"
import tvicon from "../../assets/tv.png";
import moviesicon from "../../assets/movies.png";
import seriesicon from "../../assets/series.png";
import searchicon from "../../assets/search.png";
import settingsicon from "../../assets/settings.png";
import playlisticon from "../../assets/playlist.png";
import { useQuery } from 'react-query';
import logo from "../../assets/logo.png";
import { isPlatformTizen, isPlatformAndroidtv } from '@rnv/renative';
import axios from 'axios';
import { PLAYLIST_STORAGE_PASSWORD, PLAYLIST_STORAGE_URL, PLAYLIST_STORAGE_USERNAME } from '../../storage/PlayListStorage';
import noimage from "../../assets/noimage.png";

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

const ScreenChannelsList = ({ goToChannel }: any) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    const flashListRef: any = useRef(null);

    const [category, setCategory] = React.useState("");

    // Fetcher function for categories
    const getResourceCategories = async () => {
        try {
            const playlistStorageUrl = await PLAYLIST_STORAGE_URL();
            const playlistStorageUsername = await PLAYLIST_STORAGE_USERNAME();
            const playlistStoragePassword = await PLAYLIST_STORAGE_PASSWORD();

            const response = await fetch(`${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}&action=get_live_categories`);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch live categories');
        }
    };

    // Using the hook for categories
    const { data: categories, error: errCategories, isLoading: isLoadingCategories } = useQuery('live-categories', getResourceCategories, {
        cacheTime: 6 * 60 * 60 * 1000,
    });

    // Fetcher function for channels in a specific category
    const getResourceChannels = async (category: any) => {
        try {
            const playlistStorageUrl = await PLAYLIST_STORAGE_URL();
            const playlistStorageUsername = await PLAYLIST_STORAGE_USERNAME();
            const playlistStoragePassword = await PLAYLIST_STORAGE_PASSWORD();

            const response = await fetch(`${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}&action=get_live_streams&category_id=${category}`);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch live streams in the selected category');
        }
    };

    // Using the hook for channels in a specific category
    const { data: channels, error: errChannels, isLoading: isLoadingChannels, isFetching: isFetchingChannels } = useQuery(['live-channels-categories', category], () => getResourceChannels(category), {
        cacheTime: 6 * 60 * 60 * 1000,
    });

    const [date, setDate] = React.useState(new Date());
    const [time, setTime] = React.useState("");

    React.useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);
        return function cleanup() {
            clearInterval(timer)
        }
    });

    const onFocusCategory = (item: any) => {
        setCategory(item);
    }

    const RenderCategory = useCallback(({ item, focusRepeatContext, index }: any) => {
        return (
            <Pressable
                style={[
                    styles.recyclerCategoriesItem
                ]}
                focusRepeatContext={focusRepeatContext}
                focusOptions={{
                    // @ts-ignore
                    forbiddenFocusDirections: [index === 0 && "up", index === categories?.length - 1 && "down"]
                }}
                onFocus={() => {
                    console.log(item);

                    onFocusCategory(item.category_id);
                }}
                onMouseDown={() => {
                    console.log(item);

                    onFocusCategory(item.category_id);
                }}
                // @ts-ignore
                focusOptions={{
                    focusKey: index,
                    // hasPreferredFocus: Number(index) === 20 ? true : false,
                    animator: {
                        type: 'background',
                        focus: {
                            backgroundColor: '#128DD3'
                        }
                    }
                }}
            >
                <View style={{ flexDirection: "row", marginHorizontal: Ratio(40) }}>
                    <Text style={{ color: "#ddd", fontSize: Ratio(30), textTransform: "capitalize" }}>{item.category_name}</Text>
                </View>
            </Pressable>
        );
    }, []);

    const RenderChannel = ({ item, focusRepeatContext, index }: any) => {
        return (
            <Pressable
                style={[
                    styles.recyclerChannelItem
                ]}
                focusRepeatContext={focusRepeatContext}
                onPress={() => {
                    goToChannel(item);
                }}
                onMouseDown={() => {
                    goToChannel(item);
                }}
                // @ts-ignore
                focusOptions={{
                    animator: {
                        type: 'background',
                        focus: {
                            backgroundColor: '#128DD3'
                        }
                    }
                }}
            >
                <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                    <View style={{ backgroundColor: "#2b3e54", borderRadius: Ratio(50), width: Ratio(60), height: Ratio(60), alignItems: "center", justifyContent: "center", marginHorizontal: Ratio(20) }}>
                        <Image resizeMode="contain" style={styles.channelLogo} source={item.stream_icon == "" ? noimage : { uri: `${item['stream_icon']}` }} />
                    </View>
                    <View style={{ flexDirection: "row", alignSelf: "center" }}>
                        <Text style={{ color: "#ddd", fontSize: Ratio(30), textTransform: "capitalize", marginHorizontal: Ratio(10) }}>{item.num}</Text>
                        <Text style={{ color: "#ddd", fontSize: Ratio(30), textTransform: "capitalize" }}>{item.name !== null ? item.name.substring(0, 30) + (item.name.length > 30 ? " ..." : "") : ""}</Text>
                    </View>
                </View>
            </Pressable>
        );
    };

    return (
        <Screen style={[styles.screen]} focusOptions={{ screenOrder: 4 }}>
            <View style={{ flexDirection: "row", flex: 1 }}>
                {categories &&
                    <FlashList
                        ref={flashListRef}
                        data={categories}
                        renderItem={RenderCategory}
                        type="row"
                        contentContainerStyle={{ paddingTop: Ratio(40) }}
                        style={{ flex: 0.3 }}
                        horizontal={false}
                        estimatedItemSize={Ratio(100)}
                    />
                }
                {isFetchingChannels ?
                    <View style={{ alignSelf: "flex-start" }}>
                        {/* <ActivityIndicator size={40} color="#333" /> */}
                    </View>
                    :
                    <FlashList
                        data={isFetchingChannels && channels.length === 0 ? new Array(15).fill(null) : category !== "" && channels}
                        contentContainerStyle={{ paddingTop: Ratio(40) }}
                        renderItem={RenderChannel}
                        showsVerticalScrollIndicator={false}
                        style={{ flex: 0.35 }}
                        type="row"
                        horizontal={false}
                        estimatedItemSize={Ratio(100)}
                    />
                }
            </View>
        </Screen>
    )
}

const styles = StyleSheet.create({
    screen: {
        backgroundColor: "#111",
        width: windowWidth,
        height: windowHeight,
        position: 'absolute',
        justifyContent: "center",
        zIndex: 1,
        opacity: 0.96,
    },
    recyclerItemSettings: {
        width: windowWidth / 8,
        height: windowWidth / 12,
        backgroundColor: "#2b3e54",
        borderRadius: 30,
        alignItems: "center",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.8,
        justifyContent: 'center'
    },
    txtButton: {
        color: "#fff",
        fontSize: isPlatformAndroidtv ? windowWidth / 95 : windowWidth / 110,
        width: 250,
        textAlign: "center"
    },
    txtButtonSettings: {
        color: "#fff",
        fontSize: windowWidth / 90,
        fontWeight: "bold",
        marginTop: 25,
        textTransform: "uppercase"
    },
    channelLogo: {
        width: Ratio(40),
        height: Ratio(40),
    },
    genreitem: {
        width: Dimensions.get("window").width / 5,
        height: Dimensions.get("window").width / 30,
        borderRadius: 15,
        marginRight: 30,
        backgroundColor: "#2b3e54",
        marginTop: 10
    },
    recyclerCategoriesItem: {
        width: getWidth() / 4,
        height: getWidth() / 25,
        marginBottom: Ratio(10),
        borderRadius: Ratio(10),
        backgroundColor: "#2b3e54",
        alignItems: "flex-start",
        justifyContent: 'center',
        alignSelf: "center"
    },
    recyclerItem: {
        width: windowWidth / 6.6,
        height: windowWidth / 6.6,
        borderRadius: 20,
        marginTop: 15,
        margin: 5,
        backgroundColor: "#2b3e54",
        justifyContent: 'center'
    },
    recyclerChannelItem: {
        width: getWidth() / 3,
        height: getWidth() / 25,
        marginBottom: Ratio(10),
        borderRadius: Ratio(10),
        backgroundColor: "#2b3e54",
        alignItems: "flex-start",
        justifyContent: 'center',
        alignSelf: "center"
    }
});

export default ScreenChannelsList;