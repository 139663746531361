import React, { useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions, BackHandler } from 'react-native';
import { Text, View, CreateListRenderItemInfo, Image, ImageBackground, FlashList, StyleSheet, Pressable, useTVRemoteHandler, ActivityIndicator } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext } from '../../config';
import Osd from './Osd';
import { useQuery } from 'react-query';
import ScreenChannelsList from './ChannelsList';
import { PLAYLIST_STORAGE_PASSWORD, PLAYLIST_STORAGE_URL, PLAYLIST_STORAGE_USERNAME } from '../../storage/PlayListStorage';
import { Buffer } from 'buffer';
import { VPN_STORAGE } from '../../storage/VPNStorage';
import { isEngineRnWeb, isPlatformTizen } from '@rnv/renative';
import { GET_AUTH_TOKEN } from '../../storage/AuthStorage';

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

let OSDtimeout: any;

const ScreenLiveTv = ({ navigation, route }: { navigation?: any, route?: any }) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    const [isloading, setIsLoading] = React.useState(false);
    const [isOnline, setIsOnline] = React.useState(navigator.onLine);

    const [clickedNumbers, setClickedNumbers] = React.useState<any>([]);

    const playerRef: any = useRef();

    const [isConnected, setIsConnected] = React.useState(true);

    const [isVideoBuffering, setIsVideoBuffering] = React.useState(false);
    const [videoBufferingProgress, setVideoBufferingProgress] = React.useState(0);

    const getProxyData = async () => {
        try {
            const authToken = await GET_AUTH_TOKEN();
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/live-lbgjx/endpoint/getmyproxy', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch resource user data');
        }
    };

    const { data: proxydata, error: errproxy, isLoading: isLProxy } = useQuery('proxy-data', getProxyData, {
        cacheTime: 6 * 60 * 60 * 1000
    });

    // Fetcher function
    const getResource = async () => {
        try {
            const playlistStorageUrl = await PLAYLIST_STORAGE_URL();
            const playlistStorageUsername = await PLAYLIST_STORAGE_USERNAME();
            const playlistStoragePassword = await PLAYLIST_STORAGE_PASSWORD();

            const response = await fetch(`${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}&action=get_live_streams`);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch live streams');
        }
    };

    // Using the hook
    const { data: channels, error, isLoading } = useQuery('live-channels', getResource, {
        refetchInterval: 6 * 60 * 60 * 1000,
        cacheTime: 6 * 60 * 60 * 1000,
    });

    const [tvHandlerRemote, setTvHandlerRemote] = React.useState<any>();

    const [activeVpn, setActiveVpn] = React.useState(false);

    const [indexChannel, setIndexChannel] = React.useState(0);
    const [liveChannel, setLiveChannel] = React.useState<any>({});
    const [liveStream, setLiveStream] = React.useState("");
    const [vpnliveStream, setVpnLiveStream] = React.useState("");

    const [openOSD, setOpenOSD] = React.useState<boolean>(false);

    const [openMenuChannels, setOpenMenuChannels] = React.useState<boolean>(false);

    useTVRemoteHandler((remote: any) => {
        setTvHandlerRemote(remote);
    });

    React.useEffect(() => {

        if (tvHandlerRemote) {

            if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "up") {
                if (!openMenuChannels) {
                    if (indexChannel < channels.length - 1) {
                        setIndexChannel((indexChannel: any) => indexChannel + 1);
                        console.log("iiiiiiii ndex ===> ", indexChannel)
                    }
                }
            }

            if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "down") {
                if (!openMenuChannels) {
                    if (indexChannel > 0) {
                        setIndexChannel((indexChannel: any) => indexChannel - 1);
                        console.log("iiiiiiii ndex ===> ", indexChannel)

                    }
                }
            }

            if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "left") {

            }

            if (isEngineRnWeb) {

                if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "back") {
                    if (openOSD || openMenuChannels) {
                        setOpenOSD(false);
                        setOpenMenuChannels(false);
                    } else {
                        navigation.goBack();
                    }
                }

            }

            if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "right") {

            }

            if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "select") {
                setOpenOSD(true);
            }

            const number = parseInt(tvHandlerRemote.eventType, 10);
            if (tvHandlerRemote.eventKeyAction == 'keyup' && !isNaN(number)) {
                if (clickedNumbers.length < 4) {
                    setClickedNumbers((prevNumbers: any) => [...prevNumbers, number.toString()]);
                }
            }

        }

    }, [tvHandlerRemote]);

    const remoteKeyHandler = (e: any) => {
        // console.log(e);
        const thisEvent = {
            eventType: e.key,
            eventKeyAction: e.type,
            velocity: 0
        }
        // console.log("===> ", thisEvent);
        setTvHandlerRemote(thisEvent);
    };

    if (isPlatformTizen) {

        React.useEffect(() => {

            {/* @ts-ignore */ }
            tizen.tvinputdevice.registerKey("0");
            {/* @ts-ignore */ }
            tizen.tvinputdevice.registerKey("1");
            {/* @ts-ignore */ }
            tizen.tvinputdevice.registerKey("2");
            {/* @ts-ignore */ }
            tizen.tvinputdevice.registerKey("3");
            {/* @ts-ignore */ }
            tizen.tvinputdevice.registerKey("4");
            {/* @ts-ignore */ }
            tizen.tvinputdevice.registerKey("5");
            {/* @ts-ignore */ }
            tizen.tvinputdevice.registerKey("6");
            {/* @ts-ignore */ }
            tizen.tvinputdevice.registerKey("7");
            {/* @ts-ignore */ }
            tizen.tvinputdevice.registerKey("8");
            {/* @ts-ignore */ }
            tizen.tvinputdevice.registerKey("9");

            window.addEventListener("keydown", remoteKeyHandler);
            window.addEventListener("keyup", remoteKeyHandler);

            return () => {
                window.removeEventListener("keydown", remoteKeyHandler);
                window.addEventListener("keyup", remoteKeyHandler);
            };

        }, []);

    } else {
        useTVRemoteHandler((remote: any) => {
            setTvHandlerRemote(remote);
        });
    }

    React.useEffect(() => {
        let ch_number = clickedNumbers.join('');
        if (ch_number) {
            const channelIndx = channels?.findIndex((d: any) => d.num === parseInt(ch_number, 10));
            if (channelIndx !== -1) {
                const timernumbers = setTimeout(() => {
                    setClickedNumbers([]);
                    setIndexChannel(channelIndx);
                    goToChannel(channels[channelIndx]);
                }, 3000);
                return () => clearTimeout(timernumbers);
            } else {
                const clearText = setTimeout(() => {
                    setClickedNumbers([]);
                }, 2000);
                return () => clearTimeout(clearText);
            }
        }
    }, [clickedNumbers, channels]);

    const selectChannel = (channel: any) => {
        if (channels) {
            const channelIndx = channels.findIndex((d: any) => d.name == channel.name);
            setIndexChannel(channelIndx);
            goToChannel(channels[channelIndx]);
        }
    }

    const goToChannel = (record: any) => {
        if (record) {
            setOpenOSD(true);
            setLiveChannel(record);
            setOpenMenuChannels(false);
        }
    }

    const channelsMenu = (data: boolean) => {
        setOpenMenuChannels(data);
    }

    React.useEffect(() => {
        if (openMenuChannels) {
            setOpenOSD(false);
        }
    }, [openMenuChannels, openOSD])

    React.useEffect(() => {

        const vpn = async () => {
            if (await VPN_STORAGE() == "on") {
                setActiveVpn(true);
            } else {
                setActiveVpn(false);
            }
        }

        var avPlayer = webapis.avplay;

        var listener = {
            onbufferingstart: function () {
                console.log("Buffering start.");
            },
            onbufferingprogress: function (percent: any) {
                console.log("Buffering progress data : " + percent);
            },
            onbufferingcomplete: function () {
                console.log("Buffering complete.");
            },
            oncurrentplaytime: function (currentTime: any) {
                console.log("Current playtime: " + currentTime);
            },
            onevent: function (eventType: any, eventData: any) {
                console.log("Event type: " + eventType + ", data: " + eventData);
            },
            onerror: function (eventType: any) {
                console.log("Error type: " + eventType);
            },
            onsubtitlechange: function (duration: any, text: any, data3: any, data4: any) {
                console.log("Subtitle Text: " + text);
            },
            ondrmevent: function (drmEvent: any, drmData: any) {
                console.log("DRM callback: " + drmEvent + ", data: " + drmData);
            }
        };

        avPlayer.setListener(listener);

        avPlayer.stop();

        const stream = async () => {
            if (channels && channels?.length > 0) {
                goToChannel(channels[indexChannel] ? channels[indexChannel] : channels[0]);
                setLiveStream(channels[indexChannel] ? `${await PLAYLIST_STORAGE_URL()}/${await PLAYLIST_STORAGE_USERNAME()}/${await PLAYLIST_STORAGE_PASSWORD()}/` + channels[indexChannel].stream_id : channels[0].stream_id);


                if (isPlatformTizen) {
                    // Apri il Video
                    avPlayer.open(channels[indexChannel] ? `${await PLAYLIST_STORAGE_URL()}/${await PLAYLIST_STORAGE_USERNAME()}/${await PLAYLIST_STORAGE_PASSWORD()}/` + channels[indexChannel].stream_id : channels[0].stream_id);
                    avPlayer.setDisplayRect(0, 0, 1920, 1080);

                    // Commenta questa riga per vedere se fa differenza
                    // avPlayer.setDisplayMethod("PLAYER_DISPLAY_MODE_FULL_SCREEN");
                    avPlayer.prepare();

                    // Manda in Play il Video
                    avPlayer.play();
                }

                let encodedStream = Buffer.from(channels[indexChannel] ? `${await PLAYLIST_STORAGE_URL()}/${await PLAYLIST_STORAGE_USERNAME()}/${await PLAYLIST_STORAGE_PASSWORD()}/` + channels[indexChannel].stream_id : channels[0].stream_id, 'utf-8').toString('base64');
                if (proxydata) {

                    setVpnLiveStream(proxydata?.proxy_url + encodedStream);

                    if (isPlatformTizen) {

                        // Apri il Video
                        avPlayer.open(proxydata?.proxy_url + encodedStream);
                        avPlayer.setDisplayRect(0, 0, 1920, 1080);

                        // Commenta questa riga per vedere se fa differenza
                        // avPlayer.setDisplayMethod("PLAYER_DISPLAY_MODE_FULL_SCREEN");
                        avPlayer.prepare();

                        // Manda in Play il Video
                        avPlayer.play();
                    }

                }
            }
        }

        vpn();
        stream();

        return () => {

            if (isPlatformTizen) {
                avPlayer.stop();
            }
        }

    }, [indexChannel, channels]);

    React.useEffect(() => {

        OSDtimeout = setTimeout(() => {
            setOpenOSD(false);
        }, 5000);

        return () => {
            clearTimeout(OSDtimeout);
        }

    }, [indexChannel, openOSD])

    React.useEffect(() => {
        if (channels) {
            if (route.params) {
                if (route.params.channel) {
                    const chn: any = channels;
                    const channelIndx = chn?.findIndex((d: any) => {
                        return d.name === route.params.channel.name;
                    });
                    setIndexChannel(channelIndx);
                    goToChannel(channels[channelIndx]);
                }
            }
        }
    }, [route.params ? route.params.channel : null, channels]);

    React.useEffect(() => {
        // Funksioni që do të thirret kur statusi i lidhjes ndryshon
        const updateNetworkStatus = () => {

            setIsOnline(navigator.onLine);
            setIsLoading(true);

            if (navigator.onLine) {
                const video = playerRef.current;
                console.log(video, "videoooooo");
                if (video) {
                    console.log("start videooo");

                    setTimeout(() => {

                        video.load(); // Rifreskon transmetimin
                        video.play() // Provon të rifillojë luajtjen
                            .then(() => {
                                console.log("Live stream is now playing.");
                                setIsLoading(false);
                            })
                            .catch((error: any) => {
                                console.error("Error trying to resume the live stream:", error);
                                // Mund të shtosh këtu logjikën për të trajtuar gabimin ose për të kërkuar veprim nga përdoruesi.
                            });

                    }, 5000);

                }
            }
        };

        // Vendosim dëgjues për ngjarjet e lidhjes dhe shkëputjes
        window.addEventListener('online', updateNetworkStatus);
        window.addEventListener('offline', updateNetworkStatus);

        // Pastrimi: hiq dëgjuesit kur komponenti zhduket
        return () => {
            window.removeEventListener('online', updateNetworkStatus);
            window.removeEventListener('offline', updateNetworkStatus);
        };

    }, []); // Vëmë varësinë boshe që të ekzekutohet vetëm në montimin fillestar

    return (
        <Screen
            style={[{ minHeight: height, backgroundColor: "#000" }]}
            focusOptions={{ verticalWindowAlignment: 'bot-edge', focusKey: 'page', screenOrder: 4 }}
        >
            {channels && channels?.length > 0 ?
                <>
                    {openOSD && channels &&
                        <Osd navigation={navigation} channel={liveChannel} channelsMenu={channelsMenu} />
                    }
                    {openMenuChannels &&
                        <ScreenChannelsList goToChannel={selectChannel} />
                    }
                    {isloading &&
                        <View style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            justifyContent: 'center', // Vendos përmbajtjen në qendër vertikalisht
                            alignItems: 'center', // Vendos përmbajtjen në qendër horizontalisht
                            zIndex: 1
                        }}>
                            <ActivityIndicator size="large" color="#ddd" />
                            {!isOnline &&
                                <Text style={{ color: "#ddd", fontSize: 25, marginTop: 15 }}>{isOnline ? 'Connected' : 'Disconnected'}</Text>
                            }
                        </View>
                    }
                    <View style={{}}>
                        {isPlatformTizen ?
                            <object
                                id="av-player"
                                key={liveStream}
                                type="application/avplayer"
                                style={{
                                    position: 'absolute',
                                    top: '0px',
                                    left: '0px',
                                    width: '1920px',
                                    height: '1080px',
                                    zIndex: 999
                                }}
                            />
                            :
                            <video
                                ref={playerRef}
                                width="100%"
                                height="100%"
                                src={activeVpn ? vpnliveStream : liveStream}
                                autoPlay
                                onWaiting={() => {
                                    console.log('Live video buffering');
                                    setIsLoading(true);
                                }}
                                onPlaying={() => {
                                    console.log('Live video buffering ended');
                                    setIsLoading(false);
                                }}
                            />
                        }
                        <View style={{ position: "absolute", zIndex: 1, width: Ratio(350), alignItems: "center", top: 30, right: 30, flexDirection: "row" }}>
                            {clickedNumbers.map((item: any, index: any) => (
                                <Text key={index} style={{ color: "#fff", fontWeight: "bold", fontSize: Ratio(120) }}>{item}</Text>
                            ))}
                        </View>
                    </View>
                </>
                :
                <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
                    {/* <Text style={{ color: "gray", fontSize: Ratio(50) }}>No channels available</Text> */}
                </View>
            }
        </Screen>
    );

}

const styles = StyleSheet.create({
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    },
    recyclerItem: {
        width: windowWidth / 9,
        height: windowWidth / 9,
        backgroundColor: "#222",
        alignItems: "center",
        justifyContent: 'center',
        margin: 15,
        borderRadius: 30
    },
    recyclerItemSettings: {
        width: windowWidth / 8,
        height: windowWidth / 12,
        backgroundColor: "#222",
        borderRadius: 30,
        alignItems: "center",
        marginLeft: 30,
        marginTop: 15,
        opacity: 0.6,
        justifyContent: 'center'
    },
    txtButton: {
        color: "#fff",
        fontSize: windowWidth / 90,
    },
    txtButtonSettings: {
        color: "#fff",
        fontSize: windowWidth / 90,
        fontWeight: "bold",
        marginTop: 25,
        textTransform: "uppercase"
    }
});

export default ScreenLiveTv;