import React, { useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions } from 'react-native';
import { Text, View, ScrollView, Image, ImageBackground, useTVRemoteHandler, StyleSheet, Pressable } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext } from '../../config';
import logo from "../../assets/logo.png";
import { isPlatformAndroidtv, isPlatformTizen, isPlatformWebos } from '@rnv/renative';
import axios from 'axios';
import { useQuery } from 'react-query';
import { GET_AUTH_TOKEN } from '../../storage/AuthStorage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode from 'jwt-decode';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

const generate12DigitId = (inputString: string) => {
    console.log(inputString);

    try {
        const hash = CryptoJS.SHA256(inputString).toString(CryptoJS.enc.Hex);
        const shortHash = hash.substring(0, 12).toUpperCase();

        return shortHash.substring(0, 2) + ':' +
            shortHash.substring(2, 4) + ':' +
            shortHash.substring(4, 6) + ':' +
            shortHash.substring(6, 8) + ':' +
            shortHash.substring(8, 10) + ':' +
            shortHash.substring(10, 12);
    } catch (error) {
        console.error("Error generating ID:", error);
        return "No ID found"; // Trajtimi i gabimit sipas nevojave të aplikacionit tuaj
    }

};

const ScreenCheckLicense = ({ navigation }: { navigation?: any }) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    const { t, i18n } = useTranslation();

    const [version, setVersion] = React.useState("");
    const [id, setId] = React.useState("");
    const [orbixplayId, setOrbixplayId] = React.useState("");

    // Fetcher function qr code
    const getResourceQrCode = async () => {
        try {
            const response = await axios.get('https://eu-central-1.aws.data.mongodb-api.com/app/live-lbgjx/endpoint/getqrcode', {
                headers: {
                    "Authorization": `Bearer ${await GET_AUTH_TOKEN()}`
                }
            });
            return response.data;
        } catch (error) {
            throw new Error('Failed to fetch resource QR code');
        }
    };

    // Using the hook
    const { data: getqrocode, error: errgetqrcode, isLoading: isLoadingGetQrcode, refetch } = useQuery('activate-license', getResourceQrCode);

    const [deviceid, setDeviceId] = React.useState("");

    const TizenGetMACAddress = async () => {
        try {
            // @ts-ignore
            console.log("DUID: " + webapis.productinfo.getDuid());
            setId(generate12DigitId(webapis.productinfo.getDuid()));
            setOrbixplayId(generate12DigitId(webapis.productinfo.getDuid()));
            await AsyncStorage.setItem('@device_id', generate12DigitId(webapis.productinfo.getDuid()));
            // tizen.systeminfo.getPropertyValue("ETHERNET_NETWORK", async function (ethernet) {
            //     console.log("MAC Address: " + ethernet.macAddress);
            //     setId(generate12DigitId(ethernet.macAddress));
            //     setOrbixplayId(generate12DigitId(ethernet.macAddress));
            //     await AsyncStorage.setItem('@device_id', generate12DigitId(ethernet.macAddress));
            // }, function (error: any) {
            //     console.error("Error getting MAC address: " + error.message);
            // });
        } catch (e: any) {
            console.error("Exception while getting MAC address: " + e.message);
        }
    }

    const [tvHandlerRemote, setTvHandlerRemote] = React.useState<any>();

    useTVRemoteHandler((remote: any) => {
        setTvHandlerRemote(remote);
    });

    const getLicense = async () => {

        try {

            if (id) {
                const response = await fetch('https://eu-central-1.aws.realm.mongodb.com/api/client/v2.0/app/live-lbgjx/auth/providers/custom-function/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "deviceid": id,
                        "device_mac_address": id,
                        "orbixplay_id": orbixplayId,
                        "app_name": "OrbixPLay",
                        "app_version": version,
                        "ntype": 1,
                        "hdmi": true,
                        "firmware": "10101",
                        "device_brand": "orbix",
                        "screensize": Dimensions.get("window").width + "x" + Dimensions.get("window").height,
                        "api_version": "1111",
                        "os": "Android"
                    }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const responseData = await response.json();

                const decoded: any = jwtDecode(responseData.access_token);
                console.log(responseData.access_token, "th2222222");
                console.log(decoded.user_data.license_end_date.$date.$numberLong, "---------");

                await AsyncStorage.setItem('@license_timestamp', decoded.user_data.license_end_date.$date.$numberLong);
                await AsyncStorage.setItem('@access_token', responseData.access_token);
                await AsyncStorage.setItem('@refresh_token', responseData.refresh_token);

                navigation.navigate(ROUTES.PLAYLIST, { params: 2 });
            }

        } catch (error) {
            console.error('Error during API call:', error);
        }

    };

    React.useEffect(() => {

        if (tvHandlerRemote) {
            if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "back") {
                navigation.goBack();
            }
        }

    }, [tvHandlerRemote]);

    React.useEffect(() => {
        const getDeviceId = async () => {
            const did: any = await AsyncStorage.getItem("@device_id");
            setDeviceId(did);
        };
        getDeviceId();
    }, [deviceid]);

    React.useEffect(() => {

        if (isPlatformTizen) {
            // TizenGetMACAddress();
            // @ts-ignore
            // setId(webapis.network.getMac());
            // @ts-ignore

            //console.log(webapis.network.getMac(), "_______________________________________");

            // setId("sgdeviceos");

        } else if (isPlatformWebos) {
            //@ts-ignore
            webOS.service.request('luna://com.webos.service.sm', {
                method: 'deviceid/getIDs',
                parameters: {
                    idType: ['LGUDID'],
                },
                onSuccess: async function (inResponse: any) {
                    setId(generate12DigitId(inResponse.idList[0].idValue));
                    setOrbixplayId(generate12DigitId(inResponse.idList[0].idValue));
                    await AsyncStorage.setItem('@device_id', generate12DigitId(inResponse.idList[0].idValue));
                    console.log('Result: ' + JSON.stringify(inResponse));
                    // To-Do something
                },
                onFailure: function (inError: any) {
                    console.log('Failed to get system ID information');
                    console.log('[' + inError.errorCode + ']: ' + inError.errorText);
                    // To-Do something
                    return;
                },
            });
        }

    }, [])

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#0A1C34" }]}
        >
            <View style={{ height: Dimensions.get("window").width / 20, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <View style={{ marginTop: 30 }}>
                    <Image source={logo} style={{ width: isPlatformAndroidtv ? 160 : 300, height: isPlatformAndroidtv ? 50 : 100 }} />
                </View>
            </View>
            <View>
                <View style={{ padding: 40 }}>
                    <Text style={{ color: "white", fontSize: Dimensions.get("window").width / 60, marginBottom: 0, textAlign: "center", textTransform: "uppercase", fontWeight: "bold" }}>
                        {t('check_license.you_dont_have_license')}
                    </Text>
                    <Text style={{ color: "white", fontSize: Dimensions.get("window").width / 80, marginBottom: 10, textAlign: "center" }}>
                        {t('check_license.scan_to_buy')}
                    </Text>

                    <View style={{ flexDirection: "row" }}>
                        <View
                            style={{
                                borderRadius: 20,
                                alignItems: "flex-start",
                                position: 'absolute',
                                left: Ratio(150),
                                top: Ratio(150)
                            }}
                        >
                            <Text style={{ color: "white", fontSize: Dimensions.get("window").width / 40, marginBottom: 30, textAlign: "center", textTransform: "uppercase" }}>
                                {t('check_license.how_to_activate')}
                            </Text>

                            <View style={{ marginBottom: 20, flexDirection: "row" }}>
                                <View style={{ alignItems: "flex-start" }}>
                                    <Text style={{ color: "white", fontSize: Dimensions.get("window").width / 60, textAlign: "center", marginBottom: 5 }}>
                                        {t('check_license.scan_qr_visit')}
                                    </Text>
                                    <Text style={{ color: "orange", fontSize: Dimensions.get("window").width / 60 }}>
                                        https://orbixplay.com/activate
                                    </Text>
                                </View>
                            </View>

                            <View style={{ marginBottom: 20, flexDirection: "row" }}>
                                <View style={{ alignItems: "flex-start" }}>
                                    <Text style={{ color: "white", fontSize: Dimensions.get("window").width / 60, textAlign: "center", marginBottom: 5 }}>
                                        {t('check_license.type_mac_address')}
                                    </Text>
                                    <Text style={{ fontSize: Dimensions.get("window").width / 60 }}>
                                        <Text style={{ color: "white" }}>Mac Address: </Text>
                                        <Text style={{ color: "orange" }}>{deviceid}</Text>
                                    </Text>
                                </View>
                            </View>

                            <View style={{ marginBottom: 20, flexDirection: "row" }}>
                                <View style={{ alignItems: "flex-start", justifyContent: "center" }}>
                                    <Text style={{ color: "white", fontSize: Dimensions.get("window").width / 60, textAlign: "center" }}>
                                        {t('check_license.activate')}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View
                            style={{
                                backgroundColor: "#fff",
                                width: Dimensions.get("window").width / 4.5,
                                borderRadius: 20,
                                height: Dimensions.get("window").width / 4.5,
                                alignItems: "center",
                                justifyContent: "center",
                                position: 'absolute',
                                right: Ratio(200),
                                top: Ratio(100)
                            }}
                        >
                            {getqrocode &&
                                <Image source={{ uri: getqrocode.qr_payment_url }} style={{
                                    width: Dimensions.get("window").width / 5,
                                    height: Dimensions.get("window").width / 5,
                                }} />
                            }
                        </View>
                    </View>
                </View>
            </View>

            <View style={{ alignSelf: "center", flexDirection: "row", position: "absolute", bottom: Ratio(100) }}>
                <Pressable
                    style={{
                        padding: Ratio(20),
                        backgroundColor: "#2b3e54",
                        marginTop: 20,
                        alignItems: "center",
                        borderRadius: 15,
                        justifyContent: "center",
                        marginRight: 30,
                        marginLeft: 30
                    }}
                    onPress={() => navigation.navigate(ROUTES.PLAYLIST)}
                    focusOptions={{
                        animator: {
                            type: "background",
                            focus: {
                                backgroundColor: "#128DD3"
                            }
                        }
                    }}
                >
                    <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 70 }}>
                        {t('check_license.myplaylists')}
                    </Text>
                </Pressable>
                <Pressable
                    style={{
                        padding: Ratio(20),
                        backgroundColor: "#2b3e54",
                        marginTop: 20,
                        borderRadius: 15,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    onPress={() => getLicense()}
                    focusOptions={{
                        animator: {
                            type: "background",
                            focus: {
                                backgroundColor: "#128DD3"
                            }
                        }
                    }}
                >
                    <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 70 }}>
                        {t('check_license.check')}
                    </Text>
                </Pressable>
            </View>
        </Screen>
    )
}

export default ScreenCheckLicense;