import React from 'react';
import { App, Text, Pressable, StyleSheet, FocusContext, View } from '@flexn/create';
import { Dimensions, LogBox } from 'react-native';
import Screen from '../screen';
import { Ratio, isWebBased } from '../../config';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = ({ type, title, description, cancelDialog, confirmDialog }: any) => {

    const { t, i18n } = useTranslation();

    return (
        <Screen style={styles.container} focusOptions={{ 
            screenOrder: 4
        }}>
            <View style={{ width: Dimensions.get("screen").width / 1.12, height: Ratio(400), backgroundColor: "#2b3e54", borderRadius: Ratio(50), padding: Ratio(20), borderColor: "rgb(182, 195, 210)", borderWidth: 10, justifyContent: "center" }}>
                <View style={{ marginLeft: Ratio(30) }}>
                    <Text style={{ color: "#ddd", fontSize: Ratio(50), fontWeight: "bold" }}>{title}</Text>
                    <Text style={{ color: "#ddd", fontSize: Ratio(30) }}>{description}</Text>
                </View>
                <View style={{ marginLeft: Ratio(30), flexDirection: "row", marginTop: Ratio(15) }}>
                    <Pressable
                        style={{
                            padding: !isWebBased ? 10 : 30,
                            backgroundColor: "#2b3e54",
                            marginTop: !isWebBased ? 10 : 30,
                            width: Ratio(300),
                            marginRight: 10,
                            borderRadius: 20
                        }}
                        focusOptions={{
                            forbiddenFocusDirections: ["left", "up", "down"],
                            animator: {
                                type: "background",
                                focus: {
                                    backgroundColor: "#128DD3"
                                }
                            }
                        }}
                        onMouseDown={() => {
                            cancelDialog(true);
                        }}
                        onPress={() => {
                            cancelDialog(true);
                        }}
                    >
                        <View style={{ flexDirection: "row" }}>
                            <Text style={{ color: "#ddd", fontSize: Ratio(30), textTransform: "uppercase", fontWeight: "bold" }}>
                                Cancel
                            </Text>
                        </View>
                    </Pressable>
                    <Pressable
                        style={{
                            padding: !isWebBased ? 10 : 30,
                            backgroundColor: "#2b3e54",
                            marginTop: !isWebBased ? 10 : 30,
                            width: Ratio(300),
                            marginRight: 10,
                            borderRadius: 20
                        }}
                        focusOptions={{
                            forbiddenFocusDirections: ["right", "up", "down"],
                            animator: {
                                type: "background",
                                focus: {
                                    backgroundColor: "#128DD3"
                                }
                            }
                        }}
                        onMouseDown={() => {
                            confirmDialog(true);
                        }}
                        onPress={() => {
                            confirmDialog(true);
                        }}
                    >
                        <View style={{ flexDirection: "row" }}>
                            <Text style={{ color: "#ddd", fontSize: Ratio(30), textTransform: "uppercase", fontWeight: "bold" }}>
                                Confirm
                            </Text>
                        </View>
                    </Pressable>
                </View>
            </View>
        </Screen>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1
    }
});

export default ConfirmDialog;