import React, { useContext, useRef } from 'react';
import { ScrollView as RNScrollView, useWindowDimensions, Dimensions } from 'react-native';
import { Text, View, ScrollView, Image, useTVRemoteHandler, ImageBackground, StyleSheet, Pressable, CreateListRenderItemInfo } from '@flexn/create';
import Screen from '../screen';
import { ROUTES, Ratio, ThemeContext } from '../../config';
import logo from "../../assets/logo.png";
import { useQuery } from 'react-query';
import { isFactorMobile, isPlatformMacos, isPlatformWeb, isFactorTv, isPlatformAndroidtv, isPlatformTizen, isEngineRnWeb } from '@rnv/renative';
import VODLayout from "../../assets/layers/vod_preview_layer.png";
import { PLAYLIST_STORAGE_PASSWORD, PLAYLIST_STORAGE_URL, PLAYLIST_STORAGE_USERNAME } from '../../storage/PlayListStorage';
import { useTranslation } from 'react-i18next';

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

const ScreenMovieDetails = ({ route, navigation, router }: { navigation?: any; router?: any; route?: any }) => {

    const { height } = useWindowDimensions();
    const { theme, toggle } = useContext(ThemeContext);

    const params = isPlatformWeb ? router.query : route?.params;

    const { t, i18n } = useTranslation();

    // Fetcher function for VOD categories
    const getResource = async () => {
        try {

            const playlistStorageUrl = await PLAYLIST_STORAGE_URL();
            const playlistStorageUsername = await PLAYLIST_STORAGE_USERNAME();
            const playlistStoragePassword = await PLAYLIST_STORAGE_PASSWORD();

            const response = await fetch(`${playlistStorageUrl}/player_api.php?username=${playlistStorageUsername}&password=${playlistStoragePassword}&action=get_vod_info&vod_id=${params.item.stream_id}`);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } catch (error) {
            throw new Error('Failed to fetch VOD categories');
        }
    };

    // Using the hook for VOD categories
    const { data, error, isLoading } = useQuery('vod-info', getResource, {
        refetchInterval: 6 * 60 * 60 * 1000,
        cacheTime: 6 * 60 * 60 * 1000,
    });

    const [tvHandlerRemote, setTvHandlerRemote] = React.useState<any>();

    useTVRemoteHandler((remote: any) => {
        setTvHandlerRemote(remote);
    });

    React.useEffect(() => {

        if (tvHandlerRemote) {
            if (isEngineRnWeb) {
                if (tvHandlerRemote.eventKeyAction == "down" && tvHandlerRemote.eventType == "back") {
                    navigation.goBack();
                }
            }
        }

    }, [tvHandlerRemote]);

    return (
        <Screen
            style={[theme.styles.screen, { minHeight: height, backgroundColor: "#111" }]}
            focusOptions={{ screenOrder: 4 }}
        >
            <ImageBackground source={{ uri: params.item.stream_icon == "" || params.item.stream_icon == null ? "https://assets.orbixplay.com/no_movie_image_placeholder.png" : params.item.stream_icon }} style={{ flex: 1 }} resizeMode="cover">
                <ImageBackground source={VODLayout} resizeMode="cover" style={{ flex: 1 }}>
                    <View style={{ flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                        <View style={{ padding: isPlatformAndroidtv ? 40 : 90, flexDirection: "row" }}>
                            <View style={{ marginLeft: 0, marginBottom: 20 }}>
                                <Image
                                    style={{ width: isPlatformAndroidtv ? 180 : 380, height: isPlatformAndroidtv ? 250 : 580, borderRadius: isPlatformAndroidtv ? 20 : 50 }}
                                    source={{ uri: params.item.stream_icon == "" || params.item.stream_icon == null ? "https://assets.orbixplay.com/no_movie_image_placeholder.png" : params.item.stream_icon }}
                                />
                            </View>
                            <View style={{ marginLeft: 100, marginTop: 0 }}>
                                <Text style={styles.detailsTitle}>{params.item.name ? params.item.name.length > 40 ? params.item.name.substring(0, 40) + " ..." : params.item.name : ""}</Text>
                                {data &&
                                    <>
                                        <Text style={{ width: Dimensions.get("window").width / 2, fontSize: Dimensions.get("window").width / 70, color: "#ddd", marginTop: 30 }}>{data?.info.description ? data?.info.description.length > 600 ? data?.info.description.substring(0, 600) + " ..." : data?.info.description : ""}</Text>

                                        <Text style={{ width: Dimensions.get("window").width / 2, fontSize: Dimensions.get("window").width / 80, color: "gray", marginTop: 30 }}>{t('vods.director_label')}</Text>
                                        <Text style={{ width: Dimensions.get("window").width / 2, fontSize: Dimensions.get("window").width / 80, color: "#ddd", marginTop: 10 }}>{data?.info.director ? data?.info.director : "-"}</Text>

                                        <Text style={{ width: Dimensions.get("window").width / 2, fontSize: Dimensions.get("window").width / 80, color: "gray", marginTop: 30 }}>{t('vods.actors_label')}</Text>
                                        <Text style={{ width: Dimensions.get("window").width / 2, fontSize: Dimensions.get("window").width / 80, color: "#ddd", marginTop: 10 }}>{data?.info.actors ? data?.info.actors : "-"}</Text>

                                        <Text style={{ width: Dimensions.get("window").width / 2, fontSize: Dimensions.get("window").width / 80, color: "gray", marginTop: 30 }}>{t('vods.genres_label')}</Text>
                                        <Text style={{ width: Dimensions.get("window").width / 2, fontSize: Dimensions.get("window").width / 80, color: "#ddd", marginTop: 10 }}>{data?.info.genre ? data?.info.genre : "-"}</Text>
                                    </>
                                }
                                <Pressable style={styles.button}
                                    focusOptions={{
                                        animator: {
                                            type: "background",
                                            focus: {
                                                backgroundColor: "#128DD3"
                                            }
                                        }
                                    }}
                                    onMouseDown={() => {
                                        navigation.navigate(ROUTES.MOVIE_PLAY, { item: params.item });
                                    }}
                                    onPress={() => {
                                        navigation.navigate(ROUTES.MOVIE_PLAY, { item: params.item });
                                    }}
                                >
                                    <Text style={styles.textButton}>{t('vods.play_label')}</Text>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </ImageBackground>
            </ImageBackground>
        </Screen>
    )
}

const styles = StyleSheet.create({
    background: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    detailsInfoContainer: {
        flexDirection: "row"
    },
    poster: {
        width: 200,
        height: 280,
        borderRadius: 20
    },
    relatedCarousel: {
        paddingLeft: 20,
        flex: 1
    },
    detailsTitle: {
        color: "white",
        fontSize: Dimensions.get("window").width / 40,
        fontWeight: "bold",
        bottom: 15,
    },
    genres: {
        flexDirection: "row",
        bottom: 10,
    },
    detailsMenu: {
        color: "#fff",
        fontSize: 12,
        marginRight: 4,
        backgroundColor: "#222",
        padding: 10,
        borderRadius: 10,
        top: 4
    },
    overview: {
        color: "white",
        fontSize: Dimensions.get("window").width / 70,
        width: 500,
        height: 80,
        bottom: 10
    },
    button: {
        borderRadius: 25,
        width: Dimensions.get("window").width / 7,
        height: Dimensions.get("window").width / 35,
        marginTop: 40,
        alignItems: "center",
        justifyContent: "center"
    },
    textButton: {
        fontSize: Dimensions.get("window").width / 60,
        color: "#fff",
    },
    carouselRelated: {
        marginTop: 30
    },
    cardStyle: {
        width: Dimensions.get("window").width / 3.88,
        height: Dimensions.get("window").width / 6.2,
        borderWidth: isFactorMobile ? 0 : Ratio(5),
        borderRadius: Ratio(5),
        borderColor: 'transparent',
        fontSize: isFactorMobile ? 16 : Ratio(26),
        marginHorizontal: isFactorTv ? Ratio(5) : 0,
        marginVertical: isFactorTv ? Ratio(15) : 0,
    },
    image: {
        width: Dimensions.get("window").width / 4,
        height: Dimensions.get("window").width / 6.5,
        marginLeft: 4,
        marginTop: 4,
    }
});

export default ScreenMovieDetails;