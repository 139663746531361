import React, { useEffect, FC, useContext } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import { Text, View, ScrollView, Pressable, Image, ANIMATION_TYPES } from '@flexn/create';
import Screen from '../screen';
import { ThemeContext, ROUTES, Ratio } from '../../config';
import { useQuery } from 'react-query';
import noimage from "../../assets/noimage.png";
import { useTranslation } from 'react-i18next';

// screen size
const windowWidth: any = Dimensions.get('window').width;
const windowHeight: any = Dimensions.get('window').height;

interface Props {
    channel: any
    navigation: any
    channelsMenu: any;
}

const Osd: FC<Props> = ({ channel, navigation, channelsMenu }) => {

    const { theme } = useContext(ThemeContext);

    const [date, setDate] = React.useState(new Date());
    const [time, setTime] = React.useState("");

    React.useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);
        return function cleanup() {
            clearInterval(timer)
        }
    });

    const { t, i18n } = useTranslation();

    useEffect(() => {

        const intervalId = setInterval(() => {

            const date = new Date();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const timeString = hours + ':' + (minutes < 10 ? '0' + minutes : minutes);

            setTime(timeString);

        }, 5);

        return () => clearInterval(intervalId);

    }, []);

    return (
        <Screen style={[styles.osd]} focusOptions={{ screenOrder: 2 }}>
            <View style={styles.logoSection}>
                {channel['stream_icon'] !== undefined &&
                    <Image resizeMode="contain" style={styles.channelLogo} source={channel.stream_icon == "" ? noimage : { uri: `${channel['stream_icon']}` }} />
                }
            </View>
            <View style={styles.contentSection}>
                <View style={{ marginTop: 0 }}>
                    {/* @ts-ignore */}
                    <Text style={styles.title} >{channel['num']}  {channel['name']}</Text>
                    <View style={{ marginTop: 5 }}>
                        <View>
                            {/* @ts-ignore */}
                            <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 70, marginLeft: 5 }}>Programs of {channel.name}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 25 }}>
                    <Pressable
                        style={{
                            backgroundColor: "#2b3e54",
                            width: Dimensions.get("window").width / 6.5,
                            height: Dimensions.get("window").width / 35,
                            borderRadius: 15,
                            justifyContent: "center",
                            marginTop: 5,
                            marginLeft: 0
                        }}
                        onPress={() => {
                            channelsMenu(true)
                        }}
                        onMouseDown={() => {
                            channelsMenu(true)
                        }}
                        focusOptions={{
                            animator: {
                                type: 'background',
                                focus: {
                                    backgroundColor: '#128DD3'
                                }
                            }
                        }}
                    >
                        {/* @ts-ignore */}
                        <Text style={{ color: "#fff", textAlign: "center", fontSize: Dimensions.get("window").width / 90, marginBottom: 2 }}>{t('livetv.channels_menu_label')}</Text>
                    </Pressable>
                </View>
            </View>
            <View style={{ justifyContent: "center", marginLeft: -15 }}>
                {/* @ts-ignore */}
                <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 70, marginBottom: -7 }}>{date.toDateString()}</Text>
                {/* @ts-ignore */}
                <Text style={{ color: "#ddd", fontSize: Dimensions.get("window").width / 30, fontWeight: "bold" }}>{time}</Text>
            </View>
        </Screen>
    )
}

const styles = StyleSheet.create({
    osd: {
        backgroundColor: "#0A1C34",
        width: windowWidth,
        height: windowHeight / 3,
        padding: 10,
        alignSelf: "center",
        position: 'absolute',
        zIndex: 1,
        bottom: 0,
        opacity: 0.9,
        flexDirection: "row"
    },
    title: {
        fontSize: Dimensions.get("window").width / 40,
        color: "white",
        fontWeight: "bold"
    },
    logoSection: {
        alignItems: "center",
        justifyContent: "center",
        flexBasis: "15%"
    },
    contentSection: {
        flexBasis: "70%",
        alignContent: "center",
        justifyContent: "center",
        marginLeft: 10
    },
    timeSection: {
        flexBasis: "15%",
        alignContent: "center",
        justifyContent: "center",
        marginLeft: 10
    },
    channelLogo: {
        width: windowWidth / 9,
        height: windowWidth / 9,
    },
    description: {
        color: "white",
        marginTop: 5
    }
});

export default Osd;